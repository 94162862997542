
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { usePreviewMode } from "@/contexts/PreviewContext";

/**
 * Custom hook for handling sign-out functionality
 * Includes special handling for preview environments
 */
export const useSignOut = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { isPreviewMode } = usePreviewMode();

  const signOut = async () => {
    try {
      // Clear any local storage items related to authentication
      const authKeys = Object.keys(localStorage).filter(key => 
        key.startsWith('sb-') || 
        key.includes('auth') || 
        key.includes('supabase')
      );
      
      authKeys.forEach(key => localStorage.removeItem(key));
      
      // Perform Supabase sign out
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        // Handle specific error codes 
        if (error.message.includes('session not found') && isPreviewMode) {
          // In preview mode, if session not found, we still want to redirect
          toast({
            title: "Signed out successfully",
            description: "You've been signed out of your account",
            duration: 2000,
          });
          
          navigate("/auth");
          return;
        }
        
        // For other errors, show the error message
        throw error;
      }
      
      toast({
        title: "Signed out successfully",
        description: "You've been signed out of your account",
        duration: 2000,
      });
      
      // Redirect to auth page
      navigate("/auth");
    } catch (error: any) {
      console.error("Error during sign out:", error);
      
      toast({
        title: "Error signing out",
        description: error.message || "An unexpected error occurred",
        variant: "destructive",
      });
      
      // In preview mode, force redirect to auth even on error
      if (isPreviewMode) {
        navigate("/auth");
      }
    }
  };

  return { signOut };
};
