
import { TableCell, TableRow } from "@/components/ui/table";
import { RateCell } from "../RateCell";

interface CartonCubicFootageInfoRowProps {
  cubicFootage: number;
  rate: number;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isModified: boolean;
}

export const CartonCubicFootageInfoRow = ({
  cubicFootage,
  rate,
  onRateChange,
  isCustomizing,
  isModified
}: CartonCubicFootageInfoRowProps) => {
  return (
    <TableRow className="bg-gray-50">
      <TableCell className="font-medium text-sm italic pl-6">
        Carton Cubic Footage
      </TableCell>
      <RateCell 
        fieldKey="storagePerCubicFoot" 
        rate={rate || 0}
        onChange={onRateChange}
        isCustomizing={isCustomizing}
        isModified={isModified}
        label="/cu. ft."
      />
      <TableCell>
        {cubicFootage.toFixed(2)} ft³
      </TableCell>
      <TableCell className="text-muted-foreground">
        <span className="text-xs">(Included in shelf rate)</span>
      </TableCell>
    </TableRow>
  );
};
