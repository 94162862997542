
import React from 'react';
import { TooltipContent as RadixTooltipContent } from "@/components/ui/tooltip";
import { Separator } from "@/components/ui/separator";
import { formatDate } from "@/lib/utils";
import { getRateTierColor } from "../utils/tooltipUtils";
import { ChannelMixSection } from "./ChannelMixSection";
import { StorageSection } from "./StorageSection";
import { ServicesSection } from "./ServicesSection";
import { ProductsSection } from "./ProductsSection";

interface TooltipContentProps {
  displayTier: string;
  hasCustomRates: boolean;
  recommendedTier: string | null;
  shouldShowRecommendedBadge: boolean;
  channelMix: { name: string, percentage: number }[];
  palletPositions: number;
  hasCartonStorage: boolean;
  hasBinStorage: boolean;
  valueAddedServices: string[];
  productDescription: string;
  lastModifiedDate: string | null;
}

export const TooltipContentComponent = ({
  displayTier,
  hasCustomRates,
  recommendedTier,
  shouldShowRecommendedBadge,
  channelMix,
  palletPositions,
  hasCartonStorage,
  hasBinStorage,
  valueAddedServices,
  productDescription,
  lastModifiedDate
}: TooltipContentProps) => {
  // Get the appropriate colors
  const activeTierColor = getRateTierColor(displayTier);
  const recommendedTierColor = recommendedTier ? getRateTierColor(recommendedTier) : '';

  return (
    <RadixTooltipContent
      side="right"
      className="p-0 bg-white shadow-md border border-gray-200 rounded-md max-w-md overflow-hidden"
      sideOffset={5}
      align="start"
      alignOffset={-20}
    >
      <div className="flex flex-col p-4">
        <div className="flex justify-between items-center mb-3 border-b pb-2">
          <h4 className="font-semibold text-base">Business Summary</h4>
          <div className="flex flex-col items-end">
            <div className="text-xs">
              <span className="text-gray-500">Rate Tier: </span>
              <span className={`font-semibold ${activeTierColor}`}>
                {displayTier} 
                {hasCustomRates && displayTier !== 'CUSTOM' && <span className="text-violet-700"> (Custom)</span>}
              </span>
            </div>
            
            {shouldShowRecommendedBadge && (
              <div className="text-xs mt-0.5">
                <span className="text-gray-500">Recommended: </span>
                <span className={`font-semibold ${recommendedTierColor}`}>
                  {recommendedTier}
                </span>
              </div>
            )}
          </div>
        </div>
        
        <div className="space-y-3 max-h-[400px] overflow-y-auto pr-1">
          <ChannelMixSection channelMix={channelMix} />
          
          <StorageSection 
            palletPositions={palletPositions}
            hasCartonStorage={hasCartonStorage}
            hasBinStorage={hasBinStorage}
          />
          
          <ServicesSection valueAddedServices={valueAddedServices} />
          
          <ProductsSection productDescription={productDescription} />
        </div>
        
        {lastModifiedDate && (
          <div className="flex justify-between mt-4 pt-2 border-t text-xs text-gray-500">
            <span>Last Updated:</span>
            <span>{formatDate(lastModifiedDate)}</span>
          </div>
        )}
      </div>
    </RadixTooltipContent>
  );
};
