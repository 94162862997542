
import { NavigationMenuContent, NavigationMenuLink } from "@/components/ui/navigation-menu";
import { Link } from "react-router-dom";
import { 
  Package, 
  Building, 
  Truck, 
  PackageCheck, 
  RefreshCcw, 
  Wrench,
  Box,
  ShoppingBag,
} from "lucide-react";

export const FulfillmentMenu = () => {
  return (
    <NavigationMenuContent>
      <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px]">
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/fulfillment/dtc"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <ShoppingBag className="h-4 w-4" />
              <span>DTC</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/fulfillment/b2b"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Building className="h-4 w-4" />
              <span>B2B Retail</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/fulfillment/fba"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Box className="h-4 w-4" />
              <span>Amazon FBA</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/fulfillment/fbm"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Package className="h-4 w-4" />
              <span>Amazon FBM</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/fulfillment/returns"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <RefreshCcw className="h-4 w-4" />
              <span>Returns Management</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/fulfillment/kitting"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <PackageCheck className="h-4 w-4" />
              <span>Kitting and Customization</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/fulfillment/special-projects"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Wrench className="h-4 w-4" />
              <span>Inspections, Remediation, and Special Projects</span>
            </Link>
          </NavigationMenuLink>
        </li>
      </ul>
    </NavigationMenuContent>
  );
};
