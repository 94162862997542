
import { safeNumberConversion } from '../dataConversion';

// Extract fulfillment data from quote data
export const extractFulfillmentData = (quoteData: any) => {
  // Default to empty object if not present
  const fulfillmentRequirements = quoteData.fulfillment_requirements || {};
  
  // Extract monthly order volumes by type
  const monthlyParcels = safeNumberConversion(fulfillmentRequirements.monthlyParcels, 0);
  const monthlyPallets = safeNumberConversion(fulfillmentRequirements.monthlyPallets, 0);
  const monthlyCartons = safeNumberConversion(fulfillmentRequirements.monthlyCartons, 0);
  const monthlyFbaLtlPallets = safeNumberConversion(fulfillmentRequirements.monthlyFbaLtlPallets, 0);
  const monthlyFbaParcelCartons = safeNumberConversion(fulfillmentRequirements.monthlyFbaParcelCartons, 0);
  
  // Calculate total FBA orders
  const fbaOrders = monthlyFbaLtlPallets + monthlyFbaParcelCartons;
  
  // Calculate total monthly orders
  const monthlyOrders = monthlyParcels + monthlyPallets + monthlyCartons + fbaOrders;
  
  // Extract FBA-specific values
  const fbaFnskuLabels = safeNumberConversion(fulfillmentRequirements.monthlyFnskuLabels, 0);
  const fbaCasePack = safeNumberConversion(fulfillmentRequirements.monthlyFbaCasePack, 0);
  
  // Extract units per parcel with default of 1
  const unitsPerParcel = safeNumberConversion(fulfillmentRequirements.unitsPerParcel, 1);
  
  return {
    monthlyOrders,
    parcelOrders: monthlyParcels,
    palletOrders: monthlyPallets,
    cartonOrders: monthlyCartons,
    fbaOrders,
    fbaFnskuLabels,
    fbaCasePack,
    fbaLtlPallets: monthlyFbaLtlPallets,
    fbaParcelCartons: monthlyFbaParcelCartons,
    unitsPerParcel
  };
};
