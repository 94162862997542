
import { useState } from "react";
import { toast } from "sonner";
import { UseMutationResult } from "@tanstack/react-query";
import { Json } from "@/integrations/supabase/types";
import { DisplayRateTier, RateTier } from "@/utils/rates";

interface UseCustomRatesManagementProps {
  quote: any;
  updateQuoteMutation: UseMutationResult<any, Error, any, unknown>;
  handleTierChange: (tier: DisplayRateTier) => void;
}

export const useCustomRatesManagement = ({
  quote,
  updateQuoteMutation,
  handleTierChange
}: UseCustomRatesManagementProps) => {
  const [isCustomizing, setIsCustomizing] = useState(false);

  // Component specific handlers
  const toggleCustomization = () => {
    setIsCustomizing(!isCustomizing);
  };

  const handleSaveCustomRates = () => {
    // When saving custom rates, we now immediately persist to DB
    if (!quote?.id) {
      toast.error("Cannot save - quote ID not found");
      return;
    }
    
    const customRatesKey = `quote_${quote.id}_custom_rates`;
    const storedCustomRates = localStorage.getItem(customRatesKey);
    const customRates = storedCustomRates ? JSON.parse(storedCustomRates) : null;
    
    // Only save if we have custom rates to save
    if (customRates && Object.keys(customRates).length > 0) {
      // Store the current approved tier as original_rate_tier when switching to CUSTOM
      const originalRateTier = quote.approved_rate_tier !== 'CUSTOM' ? 
                               quote.approved_rate_tier : 
                               quote.original_rate_tier || 'D2C';
                               
      updateQuoteMutation.mutate({
        prospectId: quote.id,
        data: {
          approved_rate_tier: 'CUSTOM',
          custom_rates: customRates,
          original_rate_tier: originalRateTier
        }
      }, {
        onSuccess: () => {
          toast.success("Custom rates saved to database");
          setIsCustomizing(false);
          // Clear localStorage after successful save
          localStorage.removeItem(customRatesKey);
        },
        onError: (error) => {
          console.error("Failed to save custom rates:", error);
          toast.error(`Failed to save custom rates. Please try again.`);
        }
      });
    } else {
      // No custom rates to save, just exit customization mode
      setIsCustomizing(false);
    }
  };

  const handleCustomRatesDetected = () => {
    // When custom rates are detected, update the tier to CUSTOM
    handleTierChange('CUSTOM');
  };

  const handleTierChangeFromSheet = (newTier: string) => {
    // Call the hook function to update state/DB
    handleTierChange(newTier as DisplayRateTier);
    
    // If switching to a non-CUSTOM tier, also clear custom rates from DB
    if (newTier !== 'CUSTOM' && quote?.id) {
      // Update the database to clear custom rates
      updateQuoteMutation.mutate({
        prospectId: quote.id,
        data: {
          custom_rates: null,
          approved_rate_tier: newTier as DisplayRateTier,
          original_rate_tier: null // Clear original tier when reverting to standard tier
        }
      }, {
        onSuccess: () => {
          toast.success(`Switched to ${newTier} rate tier`);
          
          // Clear localStorage custom rates
          localStorage.removeItem(`quote_${quote.id}_custom_rates`);
        },
        onError: (error) => {
          console.error("Failed to clear custom rates:", error);
          toast.error(`Failed to switch rate tier. Please try again.`);
        }
      });
    }
  };

  return {
    isCustomizing,
    toggleCustomization,
    handleSaveCustomRates,
    handleCustomRatesDetected,
    handleTierChangeFromSheet
  };
};
