import { motion } from "framer-motion";
import { CheckCircle, ArrowRight } from "lucide-react";
import { Button } from "./ui/button";
import { useNavigate } from "react-router-dom";

interface ThankYouMessageProps {
  onReset?: () => void;
}

export const ThankYouMessage = ({ onReset }: ThankYouMessageProps) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-xl p-8 shadow-lg border border-gray-100 flex flex-col items-center text-center"
    >
      <div className="bg-green-100 p-4 rounded-full mb-4">
        <CheckCircle className="w-12 h-12 text-green-600" />
      </div>
      
      <h2 className="text-2xl font-bold text-gray-900 mb-2">Thank You!</h2>
      
      <p className="text-gray-600 mb-6">
        We've received your inquiry and our team will get back to you shortly.
      </p>
      
      <div className="space-y-4 w-full">
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-left">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">What happens next?</h3>
          <ul className="space-y-2 text-gray-600">
            <li className="flex items-start">
              <ArrowRight className="h-4 w-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
              <span>A fulfillment specialist will review your inquiry</span>
            </li>
            <li className="flex items-start">
              <ArrowRight className="h-4 w-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
              <span>You'll receive a response within 1 business day</span>
            </li>
            <li className="flex items-start">
              <ArrowRight className="h-4 w-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
              <span>We'll schedule a consultation to discuss your specific needs</span>
            </li>
          </ul>
        </div>
        
        <div className="flex flex-col space-y-3">
          <Button 
            className="w-full" 
            onClick={() => navigate("/quote/contact")}
          >
            Create a Detailed Quote
          </Button>
          
          {onReset && (
            <Button 
              variant="outline" 
              className="w-full" 
              onClick={onReset}
            >
              Submit Another Inquiry
            </Button>
          )}
          
          <Button 
            variant="ghost" 
            className="w-full" 
            onClick={() => navigate("/")}
          >
            Return to Homepage
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default ThankYouMessage;
