
import { TableCell, TableRow } from "@/components/ui/table";
import { RateCell } from "../RateCell";
import { formatCurrency } from "@/lib/utils";
import { calculateFTLCost } from "./utils";

interface FTLRowProps {
  scopeData: any;
  rateCardData: Record<string, number>;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified: (key: string) => boolean;
}

export const FTLRow = ({
  scopeData,
  rateCardData,
  onRateChange,
  isCustomizing,
  isRateModified
}: FTLRowProps) => {
  const isPalletized = scopeData.loadingTypes?.ftl === "palletized" && 
                      scopeData.palletCounts?.ftl > 0;
  
  return (
    <TableRow>
      <TableCell className="font-medium">
        {isPalletized 
          ? "FTL Receiving (Palletized)" 
          : "FTL Receiving"}
      </TableCell>
      <TableCell>
        {isPalletized 
          ? (
            <div className="flex flex-col">
              <RateCell 
                fieldKey="receivingStandardPallet" 
                rate={rateCardData.receivingStandardPallet || 0}
                onChange={onRateChange}
                isCustomizing={isCustomizing}
                isModified={isRateModified("receivingStandardPallet")}
                label="per pallet"
              />
            </div>
          ) 
          : (
            <RateCell 
              fieldKey="receivingFTL" 
              rate={rateCardData.receivingFTL || 0}
              onChange={onRateChange}
              isCustomizing={isCustomizing}
              isModified={isRateModified("receivingFTL")}
            />
          )
        }
      </TableCell>
      <TableCell>
        {isPalletized 
          ? `${scopeData.ftlVolume * scopeData.palletCounts.ftl} pallets/mo` 
          : `${scopeData.ftlVolume || 0}/mo`}
      </TableCell>
      <TableCell>
        {formatCurrency(calculateFTLCost(scopeData, rateCardData))}
      </TableCell>
    </TableRow>
  );
};
