
import { supabase } from "@/integrations/supabase/client";
import { v4 as uuidv4 } from "uuid";

/**
 * Upload an image to Supabase storage
 */
export const uploadDiscoveryImage = async (
  file: File,
  quoteId: string | undefined
): Promise<{ path: string; error: Error | null }> => {
  try {
    if (!file) {
      throw new Error("No file selected");
    }

    // Create a unique filename to avoid collisions
    const fileExt = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExt}`;
    const filePath = `${quoteId || 'unassigned'}/${fileName}`;

    console.log("Uploading image to Supabase storage:", {
      bucketName: 'discovery-images',
      filePath,
      fileSize: file.size,
      fileType: file.type
    });

    // Upload file to Supabase storage
    const { error: uploadError } = await supabase.storage
      .from('discovery-images')
      .upload(filePath, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (uploadError) {
      console.error("Error uploading file to storage:", uploadError);
      throw uploadError;
    }

    // Save metadata in the discovery_images table
    const { error: dbError } = await supabase
      .from('discovery_images')
      .insert({
        quote_id: quoteId,
        filename: file.name,
        storage_path: filePath,
        content_type: file.type,
        size: file.size
      });

    if (dbError) {
      console.error("Error saving image metadata:", dbError);
      // Continue anyway, as the file is uploaded
    }

    console.log("Successfully uploaded image:", filePath);
    return { path: filePath, error: null };
  } catch (error) {
    console.error("Error uploading image:", error);
    return { path: '', error: error as Error };
  }
};

/**
 * Get a public URL for an image from Supabase storage
 */
export const getImageUrl = (path: string): string => {
  // If it's already a full URL, return it
  if (path.startsWith('http')) {
    return path;
  }
  
  // Get public URL from Supabase storage
  const { data } = supabase.storage
    .from('discovery-images')
    .getPublicUrl(path);
  
  console.log("Generated public URL for path:", {
    path,
    publicUrl: data.publicUrl
  });
  
  return data.publicUrl;
};

/**
 * Validates if a file is an acceptable image
 */
export const validateImageFile = (file: File): { valid: boolean; message?: string } => {
  // Check file type
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  if (!acceptedTypes.includes(file.type)) {
    return { 
      valid: false, 
      message: 'Only JPG, PNG, GIF and WebP images are supported' 
    };
  }

  // Check file size (5MB limit)
  const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  if (file.size > maxSize) {
    return { 
      valid: false, 
      message: 'Image must be less than 5MB' 
    };
  }

  return { valid: true };
};
