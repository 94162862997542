
import { safeNumberConversion } from '../dataConversion';
import { getNestedValue } from '../helpers/scopeHelpers';

// Extract storage-related data from the quote data
export const extractStorageData = (quoteData: any) => {
  const storageTypes = quoteData.storage_types || {};
  
  // Extract pallet, carton, and item positions from storage_types
  const palletPositions = safeNumberConversion(
    getNestedValue(storageTypes, ['pallets', 'initial'], 
    getNestedValue(storageTypes, ['pallets', 'positions'], 0))
  );
  
  const cartonPositions = safeNumberConversion(
    getNestedValue(storageTypes, ['cartons', 'initial'], 
    getNestedValue(storageTypes, ['cartons', 'positions'], 0))
  );
  
  const itemPositions = safeNumberConversion(
    getNestedValue(storageTypes, ['items', 'initial'], 
    getNestedValue(storageTypes, ['items', 'positions'], 0))
  );

  // Calculate cubic footage for cartons if dimensions are available
  let cartonCubicFootage = 0;
  const cartonDimensions = getNestedValue(storageTypes, ['cartons', 'dimensions'], null);
  
  if (cartonDimensions && cartonPositions > 0) {
    const length = safeNumberConversion(cartonDimensions.length, 0);
    const width = safeNumberConversion(cartonDimensions.width, 0);
    const height = safeNumberConversion(cartonDimensions.height, 0);
    
    if (length > 0 && width > 0 && height > 0) {
      // Convert inches to feet and calculate total cubic footage
      cartonCubicFootage = (length / 12) * (width / 12) * (height / 12) * cartonPositions;
    }
  }
  
  // Extract item storage type (bin or shelf)
  const itemStorageType = getNestedValue(storageTypes, ['items', 'storageType'], 'bin');

  return {
    palletPositions,
    cartonPositions,
    itemPositions,
    cartonCubicFootage,
    itemStorageType
  };
};
