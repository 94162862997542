
import { useEffect, useState } from "react";
import { UseBudgetaryQuoteProps } from "./types";
import { useRateCardData } from "./useRateCardData";
import { useQuoteCalculations } from "./useQuoteCalculations";
import { useSectionToggle } from "./useSectionToggle";
import { saveCustomRatesToStorage } from "./utils/rateUtils";
import { saveCustomRatesToDB } from "./utils/dbOperations";
import { getRecommendedTier } from "@/utils/rates/recommendedTierUtils";
import { RateTier } from "@/utils/rates";

export const useBudgetaryQuote = ({ 
  approvedRateTier,
  quoteData,
  isCustomizing = false,
  quoteId,
  quoteFirmStatus = 'budgetary',
  isAdminView = false,
  onMonthlyTotalChange,
  onCustomRatesDetected
}: UseBudgetaryQuoteProps) => {
  // State for recommended tier
  const [recommendedTier, setRecommendedTier] = useState<RateTier | undefined>(undefined);
  
  // Use our specialized hooks for better organization
  const rateCardState = useRateCardData({
    approvedRateTier,
    quoteData,
    quoteId,
    onCustomRatesDetected
  });
  
  const { expandedSections, toggleSection } = useSectionToggle();
  
  const calculations = useQuoteCalculations({
    quoteData,
    approvedRateTier,
    baseTier: rateCardState.baseTier,
    rateCardData: rateCardState.rateCardData,
    isCustomizing,
    onMonthlyTotalChange
  });
  
  // Initialize rates when component mounts or when approvedRateTier changes
  useEffect(() => {
    rateCardState.initializeRates();
  }, [approvedRateTier, rateCardState.initializeRates]);
  
  // When exiting customizing mode and we have custom rates, save them
  useEffect(() => {
    if (!isCustomizing && rateCardState.hasCustomRates) {
      saveCustomRates();
    }
  }, [isCustomizing, rateCardState.hasCustomRates]);

  // Calculate recommended tier if we have a valid monthly total
  useEffect(() => {
    if (calculations.monthlyTotal > 0) {
      const tier = getRecommendedTier(calculations.monthlyTotal, quoteId);
      setRecommendedTier(tier);
    }
  }, [calculations.monthlyTotal, quoteId]);
  
  // Save custom rates function - includes baseTier as the original_rate_tier
  const saveCustomRates = async (): Promise<void> => {
    // Store custom rates in localStorage temporarily
    saveCustomRatesToStorage(
      rateCardState.customRatesStorageKey,
      rateCardState.customRateCardData,
      rateCardState.hasCustomRates
    );
    
    // If we're in admin view and have a quote ID, try to save to DB directly
    if (isAdminView && quoteId && rateCardState.hasCustomRates) {
      // Pass the baseTier as the original rate tier
      const result = await saveCustomRatesToDB(
        quoteId, 
        rateCardState.customRateCardData,
        rateCardState.baseTier
      );
      
      if (result.success) {
        // Clear localStorage after successful DB save
        if (rateCardState.customRatesStorageKey) {
          localStorage.removeItem(rateCardState.customRatesStorageKey);
        }
      }
    }
  };

  // Return combined state and handlers from all hooks
  return {
    // From rateCardState
    rateCardData: rateCardState.rateCardData,
    hasCustomRates: rateCardState.hasCustomRates,
    modifiedRates: rateCardState.modifiedRates,
    baseTier: rateCardState.baseTier,
    customRateCardData: rateCardState.customRateCardData,
    isRateModified: rateCardState.isRateModified,
    handleRateChange: rateCardState.handleRateChange,
    clearCustomRates: rateCardState.clearCustomRates,
    setBaseTier: rateCardState.setBaseTier,
    
    // From section toggle
    expandedSections,
    toggleSection,
    
    // From calculations
    scopeData: calculations.scopeData,
    quoteDetails: calculations.quoteDetails,
    monthlyTotal: calculations.monthlyTotal,
    setupTotal: calculations.setupTotal,
    
    // Local function
    saveCustomRates,
    
    // Quote ID for lookups
    quoteId,
    
    // Recommended tier
    recommendedTier
  };
};
