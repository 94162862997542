
import React from "react";
import { DatabaseQuote } from "@/pages/Quote/types";
import { DiscoveryChat } from "./DiscoveryChat";
import { ImageGallery } from "./ImageGallery";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { MessageSquare, Images } from "lucide-react";

interface DiscoveryTabProps {
  quote: DatabaseQuote;
}

const DiscoveryTab: React.FC<DiscoveryTabProps> = ({ quote }) => {
  return (
    <div className="space-y-6">
      <Tabs defaultValue="chat" className="w-full">
        <TabsList className="grid w-full grid-cols-2 mb-4">
          <TabsTrigger value="chat" className="flex items-center gap-2">
            <MessageSquare className="h-4 w-4" />
            Discovery Chat
          </TabsTrigger>
          <TabsTrigger value="images" className="flex items-center gap-2">
            <Images className="h-4 w-4" />
            Uploaded Images
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="chat">
          {quote.discovery_conversation ? (
            <DiscoveryChat discoveryConversation={quote.discovery_conversation} />
          ) : (
            <Card>
              <CardHeader>
                <CardTitle className="text-lg">Discovery Chat History</CardTitle>
                <CardDescription>No discovery chat has been conducted with this prospect yet.</CardDescription>
              </CardHeader>
            </Card>
          )}
        </TabsContent>
        
        <TabsContent value="images">
          <ImageGallery quoteId={quote.id} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export { DiscoveryTab };
export default DiscoveryTab;
