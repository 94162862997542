
import React, { useState, useEffect } from "react";
import { usePreviewMode } from "@/contexts/PreviewContext";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  fallback?: React.ReactNode;
  onLoadingComplete?: () => void;
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ({ src, alt, className, fallback, onLoadingComplete, ...props }, ref) => {
    const { adjustImagePath, isPreviewMode, isIframe, origin } = usePreviewMode();
    const [error, setError] = useState<boolean>(false);
    const [adjustedSrc, setAdjustedSrc] = useState<string>(src);
    
    // Update the source when it changes
    useEffect(() => {
      try {
        // Use the context's adjustImagePath for consistent handling
        const newAdjustedSrc = adjustImagePath(src);
        setAdjustedSrc(newAdjustedSrc);
        
        // Reset error state when source changes
        setError(false);
        
        // Log detailed info for debugging
        console.log('[Image] Source processing:', {
          component: props['data-component-name'] || 'unknown',
          original: src,
          adjusted: newAdjustedSrc,
          isPreviewMode,
          isIframe,
          origin,
          location: window.location.toString()
        });
      } catch (err) {
        console.error('[Image] Error adjusting path:', err);
        setAdjustedSrc(src); // Fallback to original src
      }
    }, [src, adjustImagePath, isPreviewMode, isIframe, origin, props]);
    
    const handleError = () => {
      console.error('[Image] Failed to load:', {
        original: src,
        adjusted: adjustedSrc,
        isPreviewMode,
        isIframe,
        location: window.location.toString()
      });
      setError(true);
    };
    
    const handleLoad = () => {
      console.log('[Image] Successfully loaded:', adjustedSrc);
      if (onLoadingComplete) {
        onLoadingComplete();
      }
    };
    
    if (error && fallback) {
      return <>{fallback}</>;
    }
    
    return (
      <img
        ref={ref}
        src={adjustedSrc}
        alt={alt || ""}
        className={className}
        onError={handleError}
        onLoad={handleLoad}
        data-component-name={props['data-component-name'] || 'Image'}
        {...props}
      />
    );
  }
);

Image.displayName = "Image";
