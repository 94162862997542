
import { Button } from "@/components/ui/button";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { SortDirection, SortField } from "../types";

interface TableSortButtonProps {
  field: SortField;
  label: string;
  onClick: (field: SortField) => void;
  currentSortField: SortField;
  currentSortDirection: SortDirection;
}

export const TableSortButton = ({ 
  field, 
  label, 
  onClick,
  currentSortField,
  currentSortDirection
}: TableSortButtonProps) => {
  const isActive = currentSortField === field;
  
  return (
    <Button
      variant="ghost"
      onClick={() => onClick(field)}
      className={`flex items-center gap-1 hover:bg-transparent ${isActive ? 'text-primary' : 'text-muted-foreground'}`}
    >
      {label}
      {isActive ? (
        currentSortDirection === 'asc' ? (
          <ArrowUp className="h-4 w-4" />
        ) : (
          <ArrowDown className="h-4 w-4" />
        )
      ) : (
        <ArrowUpDown className="h-4 w-4" />
      )}
    </Button>
  );
};
