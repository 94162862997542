
import { NavigationMenuContent, NavigationMenuLink } from "@/components/ui/navigation-menu";
import { Link } from "react-router-dom";
import { 
  ClipboardCheck,
  Warehouse,
  Calendar,
  Forklift,
} from "lucide-react";

export const WarehousingMenu = () => {
  return (
    <NavigationMenuContent>
      <ul className="grid w-[400px] gap-3 p-4">
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/warehousing/storage"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Warehouse className="h-4 w-4" />
              <span>Storage Solutions</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/warehousing/inventory"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <ClipboardCheck className="h-4 w-4" />
              <span>Inventory Management</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/warehousing/dock"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Calendar className="h-4 w-4" />
              <span>Dock Scheduling</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/warehousing/freight"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Forklift className="h-4 w-4" />
              <span>Freight Booking</span>
            </Link>
          </NavigationMenuLink>
        </li>
      </ul>
    </NavigationMenuContent>
  );
};
