import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { MainNav } from "@/components/MainNav";
import { Helmet } from "react-helmet";

const NotFound = () => {
  const location = useLocation();
  const [isRedirecting, setIsRedirecting] = useState(true);
  
  useEffect(() => {
    // Give the RedirectsHandler a chance to redirect
    // If we're still on the NotFound page after a short delay, 
    // it means there was no redirect match
    const timer = setTimeout(() => {
      setIsRedirecting(false);
    }, 300);
    
    return () => clearTimeout(timer);
  }, [location.pathname]);

  if (isRedirecting) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900 mx-auto mb-4"></div>
          <p className="text-gray-600">Checking for page redirect...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Page Not Found | Thrive 3PL</title>
        <meta name="description" content="We couldn't find the page you're looking for. It might have been moved or doesn't exist." />
      </Helmet>
      
      <header className="bg-[#002408] w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center gap-4 flex-shrink-0">
            <img src="/lovable-uploads/177edcf5-8180-47b2-a62d-f1e5b983086a.png" alt="Thrive 3PL" className="h-12" />
            <span className="font-jakarta font-bold text-3xl text-white whitespace-nowrap">
              Thrive 3PL
            </span>
          </Link>
          <MainNav />
        </div>
      </header>

      <div className="flex-grow flex items-center justify-center p-4">
        <div className="max-w-md text-center">
          <h1 className="text-6xl font-bold text-gray-900 mb-6">404</h1>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Page Not Found</h2>
          <p className="text-gray-600 mb-8">
            Sorry, we couldn't find the page you're looking for. It might have been moved or doesn't exist.
          </p>
          <div className="flex flex-col space-y-4 items-center">
            <Link to="/">
              <Button className="w-full">
                <ArrowLeft className="mr-2 h-4 w-4" />
                Back to Home
              </Button>
            </Link>
            <p className="text-sm text-gray-500">
              Looking for something specific? Try navigating through our main menu or contact us for assistance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
