
import { QuoteTypeToggle } from "./QuoteTypeToggle";
import { QuoteActionButtons } from "./QuoteActionButtons";
import { LastPushedInfo } from "./LastPushedInfo";
import { DisplayRateTier } from "@/utils/rates";

interface QuoteRateTierHeaderProps {
  isFirmQuote: boolean;
  lastPushedAt: string | null;
  isCustomizing: boolean;
  handleQuoteTypeChange: (checked: boolean) => void;
  handleSaveCustomRates: () => void;
  toggleCustomization: () => void;
  handleSaveAndPushRates: () => void;
  updatePending: boolean;
}

export const QuoteRateTierHeader = ({
  isFirmQuote,
  lastPushedAt,
  isCustomizing,
  handleQuoteTypeChange,
  handleSaveCustomRates,
  toggleCustomization,
  handleSaveAndPushRates,
  updatePending
}: QuoteRateTierHeaderProps) => {
  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center space-x-4">
        <QuoteTypeToggle 
          isFirmQuote={isFirmQuote} 
          onChange={handleQuoteTypeChange} 
        />
        
        {lastPushedAt && (
          <LastPushedInfo lastPushedAt={lastPushedAt} />
        )}
      </div>
      
      <QuoteActionButtons 
        isCustomizing={isCustomizing}
        handleSaveCustomRates={handleSaveCustomRates}
        toggleCustomization={toggleCustomization}
        handleSaveAndPushRates={handleSaveAndPushRates}
        updatePending={updatePending}
      />
    </div>
  );
};
