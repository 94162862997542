
import { Card, CardContent } from "@/components/ui/card";
import { AdminControls } from "../AdminControls";
import { MonthlyCostSummary } from "../MonthlyCostSummary";
import { QuoteSections } from "../QuoteSections";
import { QuoteDisclaimer } from "../QuoteDisclaimer";
import { AdminViewProps } from "./types";
import { useState, useEffect } from "react";
import { getRecommendedTier } from "@/utils/rates/recommendedTierUtils";

export const AdminView = ({
  budgetaryQuoteData,
  selectedTier,
  recommendedTier: externalRecommendedTier,
  hasCustomRates,
  showMonthlyTotal,
  showAllRates,
  isCustomizing,
  quoteFirmStatus,
  isAdminView,
  onTierChange
}: AdminViewProps) => {
  const [internalRecommendedTier, setInternalRecommendedTier] = useState(externalRecommendedTier);
  
  // Extract all needed data from budgetaryQuoteData
  const { 
    rateCardData,
    expandedSections,
    scopeData,
    quoteDetails,
    monthlyTotal,
    setupTotal,
    isRateModified,
    handleRateChange,
    toggleSection,
    baseTier,
    setBaseTier
  } = budgetaryQuoteData;
  
  // Calculate recommended tier if needed
  useEffect(() => {
    if (isAdminView && monthlyTotal > 0 && !externalRecommendedTier) {
      const newRecommendedTier = getRecommendedTier(
        monthlyTotal, 
        budgetaryQuoteData.quoteId
      );
      setInternalRecommendedTier(newRecommendedTier);
    }
  }, [monthlyTotal, isAdminView, budgetaryQuoteData.quoteId, externalRecommendedTier]);
  
  // Use external recommendedTier if provided, otherwise use internal
  const effectiveRecommendedTier = externalRecommendedTier || internalRecommendedTier;

  return (
    <Card>
      <CardContent className="p-6">
        <AdminControls 
          selectedTier={selectedTier}
          onTierChange={onTierChange}
          hasCustomRates={hasCustomRates}
          recommendedTier={effectiveRecommendedTier}
          isAdminView={isAdminView}
          baseTier={baseTier}
          setBaseTier={setBaseTier}
        />

        <MonthlyCostSummary 
          monthlyTotal={monthlyTotal} 
          setupTotal={setupTotal}
          showMonthlyTotal={showMonthlyTotal} 
        />

        <QuoteSections
          expandedSections={expandedSections}
          rateCardData={rateCardData}
          scopeData={scopeData}
          quoteDetails={quoteDetails}
          toggleSection={toggleSection}
          isCustomizing={isCustomizing}
          onRateChange={handleRateChange}
          isRateModified={isRateModified}
          showAllRates={showAllRates}
        />

        <QuoteDisclaimer 
          isCustomizing={isCustomizing}
          isAdminView={isAdminView}
          quoteFirmStatus={quoteFirmStatus}
        />
      </CardContent>
    </Card>
  );
};
