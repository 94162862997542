
import { SubStep } from "@/types/progress";
import { getDefaultQuoteSubsteps, getDefaultDiscoverySubsteps } from "./defaultSubsteps";

export const updateQuoteSubsteps = (substeps: SubStep[], quoteStatus?: string): SubStep[] => {
  if (!quoteStatus) return substeps;
  
  const stepsToUpdate = Array.isArray(substeps) && substeps.length > 0 
    ? substeps 
    : getDefaultQuoteSubsteps();
  
  return stepsToUpdate.map(step => {
    switch (quoteStatus) {
      case 'pending_review':
        return {
          ...step,
          completed: ['quote-submitted', 'quote-review'].includes(step.id),
          active: step.id === 'quote-review'
        };
      case 'budgetary_to_client':
        return {
          ...step,
          completed: ['quote-submitted', 'quote-review', 'budgetary-quote'].includes(step.id),
          active: step.id === 'budgetary-quote'
        };
      case 'firm_to_client':
        return {
          ...step,
          completed: ['quote-submitted', 'quote-review', 'budgetary-quote', 'firm-quote'].includes(step.id),
          active: step.id === 'firm-quote'
        };
      case 'contract_signed':
      case 'signed':
        return {
          ...step,
          completed: true,
          active: false
        };
      default:
        return step;
    }
  });
};

export const updateDiscoverySubsteps = (substeps: SubStep[], discoveryConversation: any): SubStep[] => {
  const stepsToUpdate = Array.isArray(substeps) && substeps.length > 0 
    ? substeps 
    : getDefaultDiscoverySubsteps();
  
  const hasStartedConversation = Array.isArray(discoveryConversation) && discoveryConversation.length > 0;
  const hasCompletedConversation = Array.isArray(discoveryConversation) && discoveryConversation.length >= 3;
  
  return stepsToUpdate.map(step => {
    if (step.id === 'discovery-intro') {
      return {
        ...step,
        completed: hasStartedConversation,
        active: hasStartedConversation && !hasCompletedConversation
      };
    } else if (step.id === 'discovery-complete') {
      return {
        ...step,
        completed: hasCompletedConversation,
        active: hasCompletedConversation
      };
    }
    return step;
  });
};
