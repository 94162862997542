
import { Link } from "react-router-dom";

interface CTASectionProps {
  title?: string;
  message?: string;
}

export const CTASection = ({
  title = "Ready to Optimize Your Logistics?",
  message = "Our team is ready to help your business thrive with efficient, cost-effective fulfillment solutions tailored to your specific needs."
}: CTASectionProps) => {
  return (
    <section className="bg-white rounded-xl p-8 mb-12 text-center">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-3xl font-bold mb-4">{title}</h2>
        <p className="text-gray-600 mb-6">
          {message}
        </p>
        <Link 
          to="/quote"
          className="inline-block bg-primary text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-accent transition-colors duration-300"
        >
          Request a Quote
        </Link>
      </div>
    </section>
  );
};
