
import React from "react";
import { FileQuestion } from "lucide-react";

export const EmptyImagesState: React.FC = () => {
  return (
    <div className="text-center py-12 border border-dashed rounded-lg">
      <FileQuestion className="h-12 w-12 mx-auto text-gray-300 mb-3" />
      <p className="text-sm text-gray-500">No images have been uploaded</p>
    </div>
  );
};
