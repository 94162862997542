
import React from "react";
import { ChatMessage } from "./ChatMessage";

interface ChatMessageListProps {
  messages: any[];
}

export const ChatMessageList: React.FC<ChatMessageListProps> = ({ messages }) => {
  return (
    <div className="flex flex-col gap-4">
      {messages.map((message, index) => (
        <ChatMessage key={index} message={message} index={index} />
      ))}
    </div>
  );
};
