
import { useState, useEffect, useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { ProcessedQuoteRequest, processQuoteData } from "@/utils/quoteDataProcessor";
import { useToast } from "@/hooks/use-toast";
import { DisplayRateTier, RateTier } from "@/utils/rates";
import { DatabaseQuote } from "@/pages/Quote/types";

export const useQuoteData = () => {
  const { toast } = useToast();
  const [allQuotes, setAllQuotes] = useState<ProcessedQuoteRequest[]>([]);
  const [quoteDataMap, setQuoteDataMap] = useState<Map<string, ProcessedQuoteRequest>>(new Map());
  const [isLoading, setIsLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);

  const fetchLatestQuoteData = useCallback(async () => {
    try {
      setIsLoading(true);

      // Get user's session
      const { data: { session } } = await supabase.auth.getSession();
      setAuthChecked(true);

      if (!session) {
        // Try to get a quote ID from localStorage if the user isn't authenticated yet
        const savedQuoteData = localStorage.getItem('quoteFormData');
        if (savedQuoteData) {
          try {
            const parsedData = JSON.parse(savedQuoteData);
            if (parsedData.quote_id) {
              const { data: quoteData, error } = await supabase
                .from('detailed_quote_requests')
                .select('*')
                .eq('id', parsedData.quote_id)
                .order('created_at', { ascending: false });

              if (error) throw error;

              if (quoteData && quoteData.length > 0) {
                // Process the quote data, extracting the processedQuoteRequest property
                const processedQuotes = quoteData.map(quote => {
                  // Make sure to cast the quote to DatabaseQuote before calling processQuoteData
                  const typedQuote = prepareQuoteData(quote);
                  const { processedQuoteRequest } = processQuoteData(typedQuote);
                  return processedQuoteRequest;
                });
                setAllQuotes(processedQuotes);

                // Create a map for quick lookup
                const newQuoteMap = new Map<string, ProcessedQuoteRequest>();
                processedQuotes.forEach(quote => {
                  newQuoteMap.set(quote.id, quote);
                });
                setQuoteDataMap(newQuoteMap);
                return;
              }
            }
          } catch (e) {
            // Error parsing saved quote data
          }
        }

        // No saved quote and no session
        setAllQuotes([]);
        setQuoteDataMap(new Map());
        return;
      }

      // User is authenticated, fetch their quotes
      const { data: quoteData, error } = await supabase
        .from('detailed_quote_requests')
        .select('*')
        .eq('archived', false)
        .eq('email', session.user.email)
        .order('created_at', { ascending: false });

      if (error) throw error;

      if (!quoteData || quoteData.length === 0) {
        setAllQuotes([]);
        setQuoteDataMap(new Map());
        return;
      }

      // Process the quote data, extracting the processedQuoteRequest property
      const processedQuotes = quoteData.map(quote => {
        // Make sure to cast the quote to DatabaseQuote before calling processQuoteData
        const typedQuote = prepareQuoteData(quote);
        const { processedQuoteRequest } = processQuoteData(typedQuote);
        return processedQuoteRequest;
      });
      setAllQuotes(processedQuotes);

      // Create a map for quick lookup
      const newQuoteMap = new Map<string, ProcessedQuoteRequest>();
      processedQuotes.forEach(quote => {
        newQuoteMap.set(quote.id, quote);
      });
      setQuoteDataMap(newQuoteMap);

    } catch (error: any) {
      toast({
        title: "Error Loading Quotes",
        description: error.message || "Could not load your quote data",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  // Helper function to prepare quote data and cast approved_rate_tier to DisplayRateTier
  const prepareQuoteData = (quote: any): DatabaseQuote => {
    // Process the custom_rates to ensure they're properly typed
    const custom_rates = quote.custom_rates ? 
      typeof quote.custom_rates === 'string' ? 
        JSON.parse(quote.custom_rates) : 
        quote.custom_rates 
      : undefined;
    
    // Ensure original_rate_tier is a valid RateTier
    const original_rate_tier = quote.original_rate_tier as RateTier | undefined;
    
    return {
      ...quote,
      approved_rate_tier: quote.approved_rate_tier as DisplayRateTier,
      original_rate_tier,
      custom_rates
    };
  };

  useEffect(() => {
    fetchLatestQuoteData();

    // Subscribe to changes on the quote table
    const channel = supabase
      .channel('detailed_quote_requests_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'detailed_quote_requests'
        },
        () => {
          fetchLatestQuoteData();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [fetchLatestQuoteData]);

  return {
    allQuotes,
    quoteDataMap,
    isLoading,
    authChecked,
    fetchLatestQuoteData
  };
};
