
import { NavigationMenuContent, NavigationMenuLink } from "@/components/ui/navigation-menu";
import { Link } from "react-router-dom";
import { Database, Radio, Network } from "lucide-react";

export const TechnologyMenu = () => {
  return (
    <NavigationMenuContent>
      <ul className="grid w-[400px] gap-3 p-4">
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/technology/wms"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Database className="h-4 w-4" />
              <span>Warehouse Management System (WMS)</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/technology/parcel"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Radio className="h-4 w-4" />
              <span>Parcel Management</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/technology/integrations"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <Network className="h-4 w-4" />
              <span>Native Integrations</span>
            </Link>
          </NavigationMenuLink>
        </li>
      </ul>
    </NavigationMenuContent>
  );
};
