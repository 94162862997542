
import React from "react";
import { Button } from "@/components/ui/button";
import { RefreshCw, ClockIcon } from "lucide-react";
import { format } from "date-fns";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface ResearchControlsProps {
  isResearching: boolean;
  researchTimestamp: string | null;
  onRefresh: () => void;
}

export const ResearchControls: React.FC<ResearchControlsProps> = ({
  isResearching,
  researchTimestamp,
  onRefresh
}) => {
  return (
    <div className="flex items-center gap-2">
      {researchTimestamp && (
        <div className="flex items-center text-sm text-muted-foreground">
          <ClockIcon className="h-4 w-4 mr-1" />
          Last updated: {format(new Date(researchTimestamp), 'MMM d, yyyy h:mm a')}
        </div>
      )}
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button 
              variant="outline" 
              size="sm" 
              onClick={onRefresh}
              disabled={isResearching}
            >
              <RefreshCw className={`h-4 w-4 mr-2 ${isResearching ? 'animate-spin' : ''}`} />
              {isResearching ? 'Researching...' : 'Refresh Research'}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Performs real-time web search to gather company information</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
