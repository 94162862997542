
import { RateTier, DisplayRateTier } from "@/utils/rates";
import { Json } from "@/integrations/supabase/types";

// Helper to load custom rates from various sources
export const loadCustomRates = (
  quoteData: any,
  customRatesStorageKey: string | null
): Record<string, number> => {
  // First try to load from quoteData (database)
  if (quoteData?.custom_rates && typeof quoteData.custom_rates === 'object') {
    // Ensure all values are properly cast to numbers
    const processedRates: Record<string, number> = {};
    
    Object.entries(quoteData.custom_rates).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        // Ensure the value is a number
        const numValue = typeof value === 'number' ? value : parseFloat(String(value));
        if (!isNaN(numValue)) {
          processedRates[key] = numValue;
        }
      }
    });
    
    if (Object.keys(processedRates).length > 0) {
      return processedRates;
    }
  }
  
  // Then try localStorage as fallback during editing
  if (customRatesStorageKey) {
    const storedCustomRates = localStorage.getItem(customRatesStorageKey);
    if (storedCustomRates) {
      try {
        const parsedRates = JSON.parse(storedCustomRates);
        if (Object.keys(parsedRates).length > 0) {
          return parsedRates;
        }
      } catch (e) {
        console.error("Error parsing stored custom rates:", e);
      }
    }
  }
  
  return {};
};

// Check if custom rates exist
export const hasStoredCustomRates = (
  quoteData: any,
  customRatesStorageKey: string | null
): boolean => {
  // First check the database custom_rates
  if (quoteData?.custom_rates && 
      typeof quoteData.custom_rates === 'object') {
      
    const customRatesCount = Object.keys(quoteData.custom_rates).length;
    if (customRatesCount > 0) {
      return true;
    }
  }
  
  // Then check localStorage
  if (customRatesStorageKey) {
    const storedCustomRates = localStorage.getItem(customRatesStorageKey);
    if (storedCustomRates) {
      try {
        const parsedRates = JSON.parse(storedCustomRates);
        return Object.keys(parsedRates).length > 0;
      } catch (e) {
        console.error("Error parsing stored custom rates:", e);
      }
    }
  }
  
  return false;
};

// Determine which rates have been modified
export const determineModifiedRates = (
  quoteData: any,
  customRatesStorageKey: string | null
): Set<string> => {
  const customRates = loadCustomRates(quoteData, customRatesStorageKey);
  const modifiedRates = new Set<string>();
  
  // If we have custom rates, mark each key as modified
  Object.keys(customRates).forEach(key => {
    modifiedRates.add(key);
  });
  
  return modifiedRates;
};

// Get the base tier from approved rate tier or fall back to original_rate_tier
export const getBaseTier = (approvedRateTier?: DisplayRateTier, originalRateTier?: string): RateTier => {
  if (approvedRateTier && approvedRateTier !== 'CUSTOM') {
    return approvedRateTier as RateTier;
  }
  
  // If we have an original rate tier saved, use that
  if (originalRateTier) {
    return originalRateTier as RateTier;
  }
  
  // Default fallback
  return 'D2C';
};

// Save custom rates to localStorage and optionally to DB
export const saveCustomRatesToStorage = (
  customRatesStorageKey: string | null,
  customRateCardData: Record<string, number>,
  hasCustomRates: boolean
): void => {
  if (customRatesStorageKey && hasCustomRates) {
    localStorage.setItem(
      customRatesStorageKey, 
      JSON.stringify(customRateCardData)
    );
  }
};
