import { motion } from "framer-motion";
import { Check } from "lucide-react";
import { ContactForm } from "../ContactForm";
export const ValueProps = () => {
  return <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <motion.div initial={{
        opacity: 0,
        y: 20
      }} whileInView={{
        opacity: 1,
        y: 0
      }} transition={{
        duration: 0.6
      }} viewport={{
        once: true
      }} className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4">
            Highly Responsive 3PL Logistics
          </h2>
          <p className="text-xl text-gray-600">Your 3PL should be an extension of your business. We help brands Thrive by being a true partner.</p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="space-y-6">
            {[{
            title: "Fast and Accurate Fulfillment",
            description: "Deliver on your promises with our industry-leading accuracy rates and rapid order processing."
          }, {
            title: "Scale with The Right Fulfillment Partner",
            description: "Free up your time to focus on what matters most: your products, customers, and brand growth."
          }, {
            title: "Reduce Your Shipping Costs",
            description: "Get access to our high-volume shipping discounts."
          }, {
            title: "Accelerate Your e-commerce Sales",
            description: "Leverage our in-house e-commerce experts and creative services."
          }, {
            title: "Expand Effortlessly to New Sales Channels",
            description: "Natively integrate with all major e-commerce platforms and marketplaces."
          }].map((prop, index) => <motion.div key={index} initial={{
            opacity: 0,
            y: 20
          }} whileInView={{
            opacity: 1,
            y: 0
          }} transition={{
            duration: 0.5,
            delay: index * 0.1
          }} viewport={{
            once: true
          }} className="flex items-start gap-4 group">
                <div className="bg-primary p-2 rounded-full text-white mt-1 group-hover:scale-110 transition-transform">
                  <Check className="w-4 h-4" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900">{prop.title}</h3>
                  <p className="text-gray-600 mt-1">{prop.description}</p>
                </div>
              </motion.div>)}
          </div>
          <ContactForm />
        </div>
      </div>
    </section>;
};