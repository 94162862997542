
import { DatabaseQuote } from "@/pages/Quote/types";

/**
 * Processes and returns fulfillment requirements from a quote
 */
export const getFulfillmentRequirements = (quote: DatabaseQuote) => {
  console.log("Fulfillment requirements:", quote.fulfillment_requirements);
  
  const items = [];
  const fulfillmentReqs = quote.fulfillment_requirements as Record<string, any> || {};

  if (fulfillmentReqs.parcelShipments) {
    items.push({
      type: "Parcel Shipments",
      volume: fulfillmentReqs.monthlyParcels || "0",
      details: "Individual orders to end customers"
    });
  }
  if (fulfillmentReqs.palletShipments) {
    items.push({
      type: "Pallet Shipments",
      volume: fulfillmentReqs.monthlyPallets || "0",
      details: "Full pallet orders to retailers/distributors"
    });
  }
  if (fulfillmentReqs.cartonForwarding) {
    items.push({
      type: "Carton Forwarding",
      volume: fulfillmentReqs.monthlyCartons || "0",
      details: "Case-pick orders to retailers/distributors"
    });
  }
  if (fulfillmentReqs.fbaPrep) {
    // We now use a single entry for Amazon FBA Prep
    items.push({
      type: "Amazon FBA Prep",
      volume: "See details", // We'll display detailed volumes in the FBADetails section
      details: "Preparation & forwarding to FBA centers"
    });
  }
  
  console.log("Processed fulfillment requirements:", items);
  return items;
};

/**
 * Processes and returns Amazon FBA details from a quote
 */
export const getAmazonFBADetails = (quote: DatabaseQuote) => {
  console.log("Getting Amazon FBA details");
  
  const fulfillmentReqs = quote.fulfillment_requirements as Record<string, any> || {};
  
  if (!fulfillmentReqs.fbaPrep) {
    return null;
  }
  
  return {
    monthlyLtlPallets: fulfillmentReqs.monthlyFbaLtlPallets || "0",
    monthlyParcelCartons: fulfillmentReqs.monthlyFbaParcelCartons || "0",
    monthlyFnskuLabels: fulfillmentReqs.monthlyFnskuLabels || "0",
    monthlyFbaCasePack: fulfillmentReqs.monthlyFbaCasePack || "0"
  };
};

/**
 * Processes and returns value-added services from a quote
 */
export const getValueAddedServices = (quote: DatabaseQuote) => {
  console.log("Value-added services:", quote.value_added_services);
  console.log("Services details:", quote.services_details);
  
  const items = [];
  const valueAddedServices = quote.value_added_services as Record<string, any> || {};
  const servicesDetails = quote.services_details as Record<string, string> || {};
  if (valueAddedServices.kitting) {
    items.push({
      name: "Kitting/Assembly",
      details: servicesDetails.kittingDetails || ""
    });
  }
  if (valueAddedServices.customLabeling) {
    items.push({
      name: "Custom Labeling/Stickering",
      details: ""
    });
  }
  if (valueAddedServices.returnProcessing) {
    items.push({
      name: "Return Processing",
      details: servicesDetails.returnsDetails || ""
    });
  }
  if (valueAddedServices.qualityControl) {
    items.push({
      name: "Quality Control",
      details: servicesDetails.qcRequirements || ""
    });
  }
  if (valueAddedServices.ecommerceGrowth === "yes") {
    items.push({
      name: "E-Commerce Growth Services",
      details: "Interested in Amazon management and scaling"
    });
  }
  
  console.log("Processed value-added services:", items);
  return items;
};

/**
 * Processes and returns packaging materials from a quote
 */
export const getPackagingMaterials = (quote: DatabaseQuote) => {
  console.log("Packaging materials:", quote.packaging_materials);
  
  const items = [];
  const packagingMaterials = quote.packaging_materials as Record<string, any> || {};
  if (packagingMaterials.boxes) {
    items.push("Boxes (Various Sizes)");
  }
  if (packagingMaterials.polyMailers) {
    items.push("Poly Mailers & Envelopes");
  }
  if (packagingMaterials.palletSupplies) {
    items.push("Pallet Supplies (Wrap, Corner Guards)");
  }
  if (packagingMaterials.customMaterials) {
    items.push(`Custom Packaging Materials${packagingMaterials.customDetails ? ': ' + packagingMaterials.customDetails : ''}`);
  }
  
  console.log("Processed packaging materials:", items);
  return items;
};
