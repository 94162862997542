
import { useState, useEffect } from 'react';
import { FormattedProgressStep, ClientProgress, ProgressStepStatus } from "@/types/progress";
import { progressSteps } from './constants';
import { getDefaultSubsteps } from './defaultSubsteps';

export const useFormattedSteps = (
  progressData: ClientProgress[],
  quoteStatus: string | undefined,
  discoveryConversation: any
) => {
  const [formattedSteps, setFormattedSteps] = useState<FormattedProgressStep[]>([]);
  const [activeStepType, setActiveStepType] = useState<string | null>(null);
  
  useEffect(() => {
    // Format the steps based on progress data
    const formatted = progressSteps.map(stepDef => {
      const progressStep = progressData.find(p => p.step_type === stepDef.type);
      
      let actualStatus: ProgressStepStatus = progressStep?.status || 'not_started';
      
      // Override statuses based on quote status and discovery conversation
      if (stepDef.type === 'quote') {
        actualStatus = 'completed';
      }
      
      if (stepDef.type === 'discovery' && 
         (Array.isArray(discoveryConversation) && discoveryConversation.length > 0 || 
          quoteStatus === 'contract_signed')) {
        actualStatus = 'completed';
      }
      
      if (stepDef.type === 'contract' && quoteStatus === 'contract_signed') {
        actualStatus = 'completed';
      }
      
      return {
        ...stepDef,
        status: actualStatus,
        isCurrentStep: progressStep?.is_current_step || false,
        percentage: progressStep?.percentage_complete || 0,
        startedAt: progressStep?.started_at || null,
        completedAt: progressStep?.completed_at || null,
        substeps: progressStep?.substeps || getDefaultSubsteps(stepDef.type)
      };
    });
    
    setFormattedSteps(formatted);
    
    // Determine the active step
    let activeType: string | null = null;
    
    if (quoteStatus === 'contract_signed') {
      activeType = 'onboarding';
    } else if (quoteStatus === 'firm_to_client') {
      activeType = 'contract';
    } else {
      const inProgressStep = progressData.find(step => step.status === 'in_progress');
      if (inProgressStep) {
        activeType = inProgressStep.step_type;
      } else {
        const nextStep = progressData.find(step => step.status === 'not_started');
        if (nextStep) {
          activeType = nextStep.step_type;
        } else {
          activeType = 'onboarding';
        }
      }
    }
    
    setActiveStepType(activeType);
    
  }, [progressData, quoteStatus, discoveryConversation]);
  
  return { formattedSteps, activeStepType };
};
