
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { ChartChannel } from "./types";
import { CHART_COLORS } from "./constants";
import { memo } from "react";

interface ChannelDistributionChartProps {
  channels: Record<string, ChartChannel>;
}

export const ChannelDistributionChart = memo(({ channels }: ChannelDistributionChartProps) => {
  console.log("ChannelDistributionChart received channels:", channels);

  // Use the exact percentages without normalization, handling undefined values
  const chartData = Object.values(channels).map(channel => ({
    name: channel.name,
    value: channel.percentage || 0
  }));

  console.log("Chart data after mapping:", chartData);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    // Only show label if the value is at least 5
    if (value < 5) return null;
    
    return (
      <text 
        x={x} 
        y={y} 
        fill="white" 
        textAnchor="middle" 
        dominantBaseline="middle"
        style={{
          fontSize: '12px',
          fontWeight: 'bold',
          textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
        }}
      >
        {`${Math.round(value)}%`}
      </text>
    );
  };

  return (
    <div className="h-[300px] w-full">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={120}
            labelLine={false}
            label={renderCustomizedLabel}
            animationDuration={500}
            animationBegin={0}
          >
            {chartData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={CHART_COLORS[index % CHART_COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip 
            formatter={(value: any) => `${Math.round(value)}%`}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
});

ChannelDistributionChart.displayName = "ChannelDistributionChart";
