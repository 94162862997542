
import { Settings, LogOut } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useSignOut } from "@/hooks/useSignOut";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "@/components/ui/sidebar";

export const SettingsSection = () => {
  const location = useLocation();
  const { signOut } = useSignOut();

  return (
    <SidebarGroup>
      <SidebarGroupContent>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className={location.pathname === "/client-portal/settings" ? "bg-gray-100" : ""}
            >
              <Link to="/client-portal/settings" className="flex items-center gap-3">
                <Settings className="h-4 w-4" />
                <span>Settings</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SidebarMenuButton
              onClick={signOut}
              className="w-full text-red-600 hover:text-red-700 hover:bg-red-50"
            >
              <div className="flex items-center gap-3">
                <LogOut className="h-4 w-4" />
                <span>Sign Out</span>
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
};
