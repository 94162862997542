
import React from "react";
import { Button } from "@/components/ui/button";
import { Eye, ExternalLink } from "lucide-react";
import { DiscoveryImage } from "../types";

interface ImageCardProps {
  image: DiscoveryImage;
  onViewImage: (image: DiscoveryImage) => void;
  onOpenInNewTab: (url: string) => void;
}

export const ImageCard: React.FC<ImageCardProps> = ({ 
  image, 
  onViewImage, 
  onOpenInNewTab 
}) => {
  return (
    <div 
      key={image.id} 
      className="relative group border rounded-md p-2 bg-gray-50 hover:bg-gray-100 transition-colors"
    >
      <div className="relative aspect-square mb-2 overflow-hidden rounded-md bg-gray-200">
        <img 
          src={image.image_path} 
          alt={image.description || 'Uploaded image'} 
          className="object-cover w-full h-full cursor-pointer"
          onClick={() => onViewImage(image)}
        />
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all flex items-center justify-center opacity-0 group-hover:opacity-100">
          <Button 
            variant="secondary" 
            size="icon"
            onClick={() => onViewImage(image)}
            className="mr-1"
          >
            <Eye className="h-4 w-4" />
          </Button>
          <Button 
            variant="secondary" 
            size="icon"
            onClick={() => onOpenInNewTab(image.image_path)}
          >
            <ExternalLink className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <p className="text-xs text-gray-500 truncate">
        {new Date(image.created_at).toLocaleDateString()}
      </p>
    </div>
  );
};
