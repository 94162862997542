
import React from "react";
import { ChatMessageList } from "../components/ChatMessageList";

export const formatChatConversation = (discoveryConversation: string | any) => {
  console.log("Formatting discovery conversation:", discoveryConversation);
  
  // If it's a string, try to parse it as JSON first
  if (typeof discoveryConversation === 'string') {
    try {
      const parsedConversation = JSON.parse(discoveryConversation);
      
      // If it's an array of messages, format them
      if (Array.isArray(parsedConversation)) {
        return <ChatMessageList messages={parsedConversation} />;
      }
      
      // If parsing worked but it's not an array, stringify it with indentation
      return <pre className="whitespace-pre-wrap text-sm">{JSON.stringify(parsedConversation, null, 2)}</pre>;
    } catch (e) {
      // If parsing failed, display as plain text
      return <pre className="whitespace-pre-wrap text-sm">{discoveryConversation}</pre>;
    }
  }
  
  // If it's already an object or array
  if (Array.isArray(discoveryConversation)) {
    return <ChatMessageList messages={discoveryConversation} />;
  }
  
  // Default case: stringify the object
  return <pre className="whitespace-pre-wrap text-sm">{JSON.stringify(discoveryConversation, null, 2)}</pre>;
};
