
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ChevronDown, ChevronUp } from "lucide-react";
import { formatCurrency } from "@/lib/utils";
import { RateCell } from "./RateCell";

export interface SetupFeesSectionProps {
  rateCardData: Record<string, number>;
  quoteData: any;
  expanded: boolean;
  onToggle: () => void;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified?: (key: string) => boolean;
}

export const SetupFeesSection = ({
  rateCardData,
  quoteData,
  expanded,
  onToggle,
  onRateChange,
  isCustomizing,
  isRateModified = () => false
}: SetupFeesSectionProps) => {
  return (
    <div className="mb-6 border rounded-lg overflow-hidden">
      <div 
        className="flex justify-between items-center p-3 bg-gray-50 cursor-pointer"
        onClick={onToggle}
      >
        <h3 className="text-lg font-semibold">Setup Fees (One-Time)</h3>
        {expanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </div>
      
      {expanded && (
        <div className="overflow-x-auto p-3">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[50%]">Service</TableHead>
                <TableHead>Rate</TableHead>
                <TableHead>Quantity</TableHead>
                <TableHead>Extended</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium">Account Setup Fee</TableCell>
                <RateCell 
                  fieldKey="setupFee" 
                  rate={rateCardData.setupFee || 0}
                  onChange={onRateChange}
                  isCustomizing={isCustomizing}
                  isModified={isRateModified("setupFee")}
                />
                <TableCell>1</TableCell>
                <TableCell>{formatCurrency(rateCardData.setupFee || 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">SKU Setup Fee</TableCell>
                <RateCell 
                  fieldKey="skuSetupFee" 
                  rate={rateCardData.skuSetupFee || 0}
                  onChange={onRateChange}
                  isCustomizing={isCustomizing}
                  isModified={isRateModified("skuSetupFee")}
                />
                <TableCell>{quoteData?.totalSkus || 50} SKUs</TableCell>
                <TableCell>
                  {formatCurrency((rateCardData.skuSetupFee || 0) * (quoteData?.totalSkus || 50))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
