
import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { DatabaseQuote } from "@/pages/Quote/types";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { getInboundShipmentTypes } from "../utils";
import { format } from "date-fns";

interface ReceivingTabProps {
  quote: DatabaseQuote;
}

const ReceivingTab: React.FC<ReceivingTabProps> = ({ quote }) => {
  // Format expected start date if it exists
  const formatDate = (dateString?: string) => {
    if (!dateString) return 'Not specified';
    try {
      return format(new Date(dateString), 'PPP');
    } catch (e) {
      console.error("Error formatting date:", e);
      return 'Invalid date';
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Receiving Requirements</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h4 className="font-semibold mb-2">Inbound Shipment Types</h4>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[40%]">Shipment Type</TableHead>
                <TableHead className="w-[30%]">Loading Type</TableHead>
                <TableHead className="text-right w-[30%]">Monthly Volume</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getInboundShipmentTypes(quote).map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">{item.type}</TableCell>
                  <TableCell>
                    {item.loadingType === 'floor' ? 'Floor Loaded' : 
                     item.loadingType === 'palletized' ? 'Palletized' : 
                     item.loadingType === 'mixed' ? 'Mixed Loading' : 
                     item.loadingType}
                  </TableCell>
                  <TableCell className="text-right">
                    {item.volume ? (
                      <>
                        {item.type.includes("LTL") ? 
                          `${item.volume} pallets/month` : 
                          `${item.volume}/month`}
                        {item.isPalletized && item.palletCount && (
                          <span className="block text-xs text-gray-600">
                            {`${item.palletCount} pallets per ${item.type.includes("Container") ? "container" : "truckload"}`}
                          </span>
                        )}
                      </>
                    ) : (
                      '0/month'
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {getInboundShipmentTypes(quote).length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} className="text-center text-muted-foreground">
                    No inbound shipment types specified
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <h4 className="font-semibold mb-2">SKU Mix</h4>
            <p className="capitalize">{quote.sku_mix || 'Not specified'}</p>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Expected Start Date</h4>
            <p>{formatDate(quote.expected_start_date)}</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <Card className="border shadow-none">
            <CardHeader className="py-3 px-4">
              <CardTitle className="text-base font-medium">Requirements Upon Receipt</CardTitle>
            </CardHeader>
            <CardContent className="px-4 pb-4 pt-0">
              {quote.receiving_value_added_services && 
               Object.keys(quote.receiving_value_added_services).length > 0 && 
               Object.entries(typeof quote.receiving_value_added_services === 'object' && 
                            !Array.isArray(quote.receiving_value_added_services) ? 
                            quote.receiving_value_added_services : {}).some(([_, value]) => value) ? (
                <ul className="space-y-1">
                  {Object.entries(typeof quote.receiving_value_added_services === 'object' && 
                                !Array.isArray(quote.receiving_value_added_services) ? 
                                quote.receiving_value_added_services : {})
                    .filter(([_, value]) => value)
                    .map(([key]) => {
                      const label = key === 'readyToStore' ? 'Ready to Store (No Processing)' : 
                                    key === 'palletization' ? 'Palletization' : 
                                    key === 'sortAndSegregate' ? 'Sort and Segregate' : 
                                    key === 'qualityInspection' ? 'Quality Inspection' : 
                                    key === 'labeling' ? 'Labeling/Relabeling' : 
                                    key === 'specialHandling' ? 'Special Handling' : 
                                    key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
                      return (
                        <li key={key} className="flex items-center">
                          <span className="h-1.5 w-1.5 rounded-full bg-primary mr-2"></span>
                          <span>{label}</span>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <p className="text-muted-foreground text-sm">No value added services requested</p>
              )}
            </CardContent>
          </Card>

          <Card className="border shadow-none">
            <CardHeader className="py-3 px-4">
              <CardTitle className="text-base font-medium">Transportation Services Requested</CardTitle>
            </CardHeader>
            <CardContent className="px-4 pb-4 pt-0">
              {quote.transportation_needs && 
               Object.keys(quote.transportation_needs).length > 0 && 
               Object.entries(typeof quote.transportation_needs === 'object' && 
                            !Array.isArray(quote.transportation_needs) ? 
                            quote.transportation_needs : {}).some(([_, value]) => value) ? (
                <ul className="space-y-1">
                  {Object.entries(typeof quote.transportation_needs === 'object' && 
                                !Array.isArray(quote.transportation_needs) ? 
                                quote.transportation_needs : {})
                    .filter(([_, value]) => value)
                    .map(([key]) => {
                      const label = key === 'drayage' ? 'Drayage from Port of Houston' : 
                                    key === 'domesticFreight' ? 'Domestic Freight' : 
                                    key === 'internationalFreight' ? 'International Freight Forwarding' : 
                                    key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
                      return (
                        <li key={key} className="flex items-center">
                          <span className="h-1.5 w-1.5 rounded-full bg-primary mr-2"></span>
                          <span>{label}</span>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <p className="text-muted-foreground text-sm">No transportation services requested</p>
              )}
            </CardContent>
          </Card>
        </div>

        {quote.receiving_special_instructions && (
          <div>
            <h4 className="font-semibold mb-2">Special Instructions for Receiving</h4>
            <div className="border rounded-md p-3 text-sm whitespace-pre-wrap">
              {quote.receiving_special_instructions}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export { ReceivingTab };
export default ReceivingTab;
