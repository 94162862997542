
// Define the ScopeData interface used for quote calculations
export interface ScopeData {
  palletPositions: number;
  monthlyOrders: number;
  parcelOrders: number;
  palletOrders: number;
  cartonOrders: number;
  fbaOrders: number;
  cartonPositions: number;
  itemPositions: number;
  totalSkus: number;
  container20Volume: number;
  container40Volume: number;
  ftlVolume: number;
  ltlVolume: number;
  parcelVolume: number;
  loadingTypes: {
    container20?: string;
    container40?: string;
    ftl?: string;
  };
  palletCounts: {
    container20?: number;
    container40?: number;
    ftl?: number;
  };
  // Storage calculation fields
  cartonCubicFootage: number;
  itemStorageType: 'bin' | 'shelf' | string;
  // FBA-related properties
  fbaFnskuLabels: number;
  fbaCasePack: number;
  fbaLtlPallets: number;
  fbaParcelCartons: number;
  // Value-added service flags
  hasKitting: boolean;
  hasReturns: boolean;
  hasLabeling: boolean;
  // Units per parcel field
  unitsPerParcel: number;
}

// Default scope data if missing
export const getDefaultScopeData = (): ScopeData => ({
  palletPositions: 0,
  monthlyOrders: 0,
  parcelOrders: 0,
  palletOrders: 0,
  cartonOrders: 0,
  fbaOrders: 0,
  cartonPositions: 0,
  itemPositions: 0,
  totalSkus: 50,
  container20Volume: 0,
  container40Volume: 0,
  ftlVolume: 0,
  ltlVolume: 0,
  parcelVolume: 0,
  loadingTypes: {},
  palletCounts: {},
  cartonCubicFootage: 0,
  itemStorageType: 'bin',
  fbaFnskuLabels: 0,
  fbaCasePack: 0,
  fbaLtlPallets: 0,
  fbaParcelCartons: 0,
  hasKitting: false,
  hasReturns: false,
  hasLabeling: false,
  unitsPerParcel: 1
});
