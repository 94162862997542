
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { detectPreviewEnvironment } from '../utils/preview/detectPreviewMode';

interface PreviewContextType {
  isPreviewMode: boolean;
  protocol: string;
  isHttps: boolean;
  isIframe: boolean;
  isEditing: boolean;
  origin: string;
  adjustImagePath: (path: string) => string;
}

const defaultContext: PreviewContextType = {
  isPreviewMode: false,
  protocol: '',
  isHttps: false,
  isIframe: false,
  isEditing: false,
  origin: '',
  adjustImagePath: (path) => path
};

const PreviewContext = createContext<PreviewContextType>(defaultContext);

export const PreviewProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [protocol, setProtocol] = useState<string>('');
  const [isHttps, setIsHttps] = useState<boolean>(false);
  const [isIframe, setIsIframe] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [origin, setOrigin] = useState<string>('');

  useEffect(() => {
    try {
      // Detect preview mode once on mount
      const isPreview = detectPreviewEnvironment();
      setIsPreviewMode(isPreview);
      
      const currentProtocol = window.location.protocol;
      const currentOrigin = window.location.origin;
      
      setProtocol(currentProtocol);
      setIsHttps(currentProtocol === 'https:');
      setOrigin(currentOrigin);
      
      // Check if running in an iframe
      const inIframe = window !== window.parent;
      setIsIframe(inIframe);
      
      // Check if in editing mode (look for URL params)
      const urlParams = new URLSearchParams(window.location.search);
      const editing = urlParams.has('editing') || urlParams.has('edit');
      setIsEditing(editing);
      
      console.log('[PreviewProvider] Preview mode detected:', isPreview);
      console.log('[PreviewProvider] Is in iframe:', inIframe);
      console.log('[PreviewProvider] Is editing:', editing);
      console.log('[PreviewProvider] Origin:', currentOrigin);
      console.log('[PreviewProvider] Protocol:', currentProtocol);
      
      // Log some useful debugging info for Supabase auth in preview mode
      if (isPreview) {
        console.info('[PreviewProvider] Running in preview mode. For Supabase auth to work:');
        console.info(`[PreviewProvider] 1. Site URL should be set to: ${currentOrigin}`);
        console.info(`[PreviewProvider] 2. Redirect URL should include: ${currentOrigin}`);
      }
    } catch (error) {
      console.error('[PreviewProvider] Initialization error:', error);
    }
  }, []);

  // Define the adjustImagePath function
  const adjustImagePath = (path: string): string => {
    if (!path) return path;
    
    try {
      // Skip adjustment for already absolute URLs
      if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
      }
      
      // For paths in the /lovable-uploads/ directory, ensure they start with a slash
      if (path.includes('lovable-uploads') && !path.startsWith('/')) {
        path = `/${path}`;
      }
      
      // For relative paths, ensure they start with a slash
      if (!path.startsWith('/')) {
        path = `/${path}`;
      }
      
      // For paths in preview mode, use the current origin if they're not already absolute
      if (isPreviewMode) {
        const fullUrl = `${origin}${path}`;
        return fullUrl;
      }
      
      return path;
    } catch (error) {
      console.error('[adjustImagePath] Error:', error);
      // Return original on error
      return path;
    }
  };

  return (
    <PreviewContext.Provider value={{ 
      isPreviewMode, 
      protocol,
      isHttps,
      isIframe,
      isEditing,
      origin,
      adjustImagePath
    }}>
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreviewMode = () => useContext(PreviewContext);
