
import { useCallback } from "react";
import { saveCustomRatesToStorage } from "./utils/rateUtils";

interface UseRateManagementProps {
  customRatesStorageKey: string | null;
  customRateCardData: Record<string, number>;
  setCustomRateCardData: (data: Record<string, number>) => void;
  setModifiedRates: (callback: (prev: Set<string>) => Set<string>) => void;
  setHasCustomRates: (value: boolean) => void;
  onCustomRatesDetected?: () => void;
}

/**
 * Hook to manage rate changes and custom rate operations
 */
export const useRateManagement = ({
  customRatesStorageKey,
  customRateCardData,
  setCustomRateCardData,
  setModifiedRates,
  setHasCustomRates,
  onCustomRatesDetected
}: UseRateManagementProps) => {
  // Handle rate changes - this is used in admin mode to customize rates
  const handleRateChange = useCallback((key: string, value: string): void => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return;
    
    // Mark rate as modified - fixing the callback pattern
    setModifiedRates((prev) => {
      const newSet = new Set(prev);
      newSet.add(key);
      return newSet;
    });
    
    // Update custom rate card data
    setCustomRateCardData({
      ...customRateCardData,
      [key]: numValue
    });
    
    // Set custom rates flag
    setHasCustomRates(true);
    
    // When in customizing mode, also store to localStorage temporarily
    if (customRatesStorageKey) {
      const updatedCustomRates = {
        ...customRateCardData,
        [key]: numValue
      };
      
      localStorage.setItem(
        customRatesStorageKey,
        JSON.stringify(updatedCustomRates)
      );
    }
    
    // Notify parent about custom rates
    if (onCustomRatesDetected) {
      onCustomRatesDetected();
    }
  }, [customRatesStorageKey, customRateCardData, setCustomRateCardData, setModifiedRates, setHasCustomRates, onCustomRatesDetected]);
  
  // Clear all custom rates and reset to standard tier rates
  const clearCustomRates = useCallback(() => {
    // Reset the modified rates tracking set - ensuring we use the callback pattern correctly
    setModifiedRates(() => new Set());
    
    // Clear custom rate card data
    setCustomRateCardData({});
    
    // Reset has custom rates flag
    setHasCustomRates(false);
    
    // Clear localStorage if we have a storage key
    if (customRatesStorageKey) {
      localStorage.removeItem(customRatesStorageKey);
    }
  }, [customRatesStorageKey, setCustomRateCardData, setModifiedRates, setHasCustomRates]);
  
  return {
    handleRateChange,
    clearCustomRates
  };
};
