
import { useState, useEffect, useCallback } from 'react';
import { supabase } from "@/integrations/supabase/client";
import { ClientProgress, ClientProgressFromDB, ProgressStepStatus } from "@/types/progress";
import { useToast } from "@/hooks/use-toast";
import { convertProgressData, createDefaultProgressData } from './utils';
import { updateQuoteSubsteps, updateDiscoverySubsteps } from './substepUpdaters';

export const useProgressData = () => {
  const { toast } = useToast();
  const [progressData, setProgressData] = useState<ClientProgress[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  
  const fetchProgressData = useCallback(async () => {
    try {
      setIsLoading(true);
      
      const { data: { session } } = await supabase.auth.getSession();
      setAuthChecked(true);
      
      let quoteId: string | null = null;
      
      const urlPath = window.location.pathname;
      if (urlPath.includes('/quote/') || urlPath.includes('/client-portal/')) {
        const savedQuoteData = localStorage.getItem('quoteFormData');
        if (savedQuoteData) {
          try {
            const parsedData = JSON.parse(savedQuoteData);
            if (parsedData.quote_id) {
              quoteId = parsedData.quote_id;
            }
          } catch (e) {
            console.error("Error parsing saved quote data:", e);
          }
        }
      }
      
      if (!session && !quoteId) {
        // No authenticated user and no quote ID found
        setProgressData([]);
        setIsLoading(false);
        return;
      }
      
      if (session && !quoteId) {
        const { data: quoteData, error: quoteError } = await supabase
          .from('detailed_quote_requests')
          .select('id, quote_status, discovery_conversation')
          .eq('archived', false)
          .eq('email', session.user.email)
          .order('created_at', { ascending: false })
          .limit(1);
          
        if (quoteError) {
          console.error("Error fetching quote ID:", quoteError);
          throw quoteError;
        }
        
        if (quoteData && quoteData.length > 0) {
          quoteId = quoteData[0].id;
        }
      }
      
      if (!quoteId) {
        // No quote found
        setProgressData([]);
        setIsLoading(false);
        return;
      }
      
      const { data: quoteDetails, error: detailsError } = await supabase
        .from('detailed_quote_requests')
        .select('id, quote_status, discovery_conversation')
        .eq('id', quoteId)
        .single();
        
      if (detailsError) {
        console.error("Error fetching quote details:", detailsError);
        throw detailsError;
      }
      
      const quoteStatus = quoteDetails?.quote_status;
      const discoveryConversation = quoteDetails?.discovery_conversation;
      
      const { data: progressResults, error: progressError } = await supabase
        .from('client_progress')
        .select('*')
        .eq('quote_id', quoteId)
        .order('created_at', { ascending: true });
        
      if (progressError) {
        console.error("Error fetching progress data:", progressError);
        throw progressError;
      }
      
      if (!progressResults || progressResults.length === 0) {
        // No progress data found, create default progress data
        const defaultProgressData = createDefaultProgressData(
          quoteId, 
          session?.user?.id || null, 
          quoteStatus, 
          discoveryConversation
        );
        
        setProgressData(defaultProgressData);
        setIsLoading(false);
        return;
      }
      
      // Process existing progress data
      const typedProgressData = convertProgressData(progressResults as ClientProgressFromDB[]);
      
      const updatedProgressData = typedProgressData.map(item => {
        if (item.step_type === 'quote') {
          return {
            ...item,
            status: 'completed' as ProgressStepStatus,
            percentage_complete: 100,
            completed_at: item.completed_at || new Date().toISOString(),
            substeps: updateQuoteSubsteps(item.substeps, quoteStatus)
          };
        } else if (item.step_type === 'discovery') {
          const hasConversation = Array.isArray(discoveryConversation) && discoveryConversation.length > 0;
          const updatedStatus = hasConversation || quoteStatus === 'contract_signed' 
            ? 'completed' as ProgressStepStatus
            : item.status;
          
          return {
            ...item,
            status: updatedStatus,
            substeps: updateDiscoverySubsteps(item.substeps, discoveryConversation),
            percentage_complete: updatedStatus === 'completed' ? 100 : item.percentage_complete,
            completed_at: updatedStatus === 'completed' && !item.completed_at ? new Date().toISOString() : item.completed_at,
            is_current_step: updatedStatus !== 'completed' && quoteStatus !== 'firm_to_client' && quoteStatus !== 'contract_signed'
          };
        } else if (item.step_type === 'contract') {
          if (quoteStatus === 'contract_signed') {
            return {
              ...item,
              status: 'completed' as ProgressStepStatus,
              percentage_complete: 100,
              completed_at: item.completed_at || new Date().toISOString(),
              is_current_step: false
            };
          } 
          else if (quoteStatus === 'firm_to_client') {
            return {
              ...item,
              status: 'in_progress' as ProgressStepStatus,
              started_at: item.started_at || new Date().toISOString(),
              percentage_complete: Math.max(item.percentage_complete, 10),
              is_current_step: true
            };
          }
        } else if (item.step_type === 'onboarding' && quoteStatus === 'contract_signed') {
          return {
            ...item,
            status: 'in_progress' as ProgressStepStatus,
            started_at: item.started_at || new Date().toISOString(),
            percentage_complete: Math.max(item.percentage_complete, 10),
            is_current_step: true
          };
        }
        return item;
      });
      
      setProgressData(updatedProgressData as ClientProgress[]);
      
    } catch (error: any) {
      toast({
        title: "Error Fetching Progress",
        description: error.message || "Failed to load progress data",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);
  
  useEffect(() => {
    fetchProgressData();
    
    const channel = supabase
      .channel('client-progress-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'client_progress'
        },
        () => {
          fetchProgressData();
        }
      )
      .subscribe();
      
    return () => {
      supabase.removeChannel(channel);
    };
  }, [fetchProgressData]);
  
  return { progressData, isLoading, authChecked, fetchProgressData };
};
