
import { useState } from "react";
import { DetailedQuoteView } from "../../components/DetailedQuoteView";
import { Prospect } from "../../types";
import { DatabaseQuote } from "@/pages/Quote/types";
import { calculateQuoteDetails, extractScopeData } from "../../components/QuoteCalculator/utils";
import { getRecommendedTier } from "@/utils/rates/recommendedTierUtils";
import { isObject } from "@/lib/utils";
import { useEffect } from "react";
import { RateTier } from "@/utils/rates";

interface DetailedViewTriggerProps {
  quote: Prospect | DatabaseQuote;
  children?: React.ReactNode;
  className?: string;
}

export const DetailedViewTrigger = ({ quote, children, className }: DetailedViewTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [recommendedTier, setRecommendedTier] = useState<RateTier | null>(null);

  // Calculate the recommended tier when the component mounts
  useEffect(() => {
    if (quote) {
      try {
        // Extract scope data for calculations
        const scopeData = extractScopeData(quote);
        
        // Calculate quote details using the default rate tier or approved rate tier
        const rateTier = quote.approved_rate_tier || 'D2C';
        
        // Properly type custom rates
        const customRates = quote.custom_rates && isObject(quote.custom_rates) 
          ? quote.custom_rates as Record<string, number>
          : undefined;
          
        const quoteDetails = calculateQuoteDetails(
          rateTier === 'CUSTOM' ? 'D2C' : rateTier,
          scopeData,
          customRates
        );
        
        // Calculate recommended tier based on monthly revenue
        if (quoteDetails && quoteDetails.monthly && quoteDetails.monthly.total) {
          const monthlyRevenue = quoteDetails.monthly.total;
          const tier = getRecommendedTier(monthlyRevenue, quote.id);
          setRecommendedTier(tier);
        }
      } catch (err) {
        console.error(`Error calculating recommended tier for quote ${quote.id}:`, err);
      }
    }
  }, [quote]);

  return (
    <>
      {children ? (
        <div 
          className={className}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          {children}
        </div>
      ) : null}
      
      <DetailedQuoteView 
        quote={quote}
        companyName={quote.company}
        open={isOpen}
        onOpenChange={setIsOpen}
      />
    </>
  );
};
