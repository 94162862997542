
import { ChevronDown, ChevronUp, Package } from "lucide-react";
import { ReceivingTable } from "./ReceivingTable";

export interface ReceivingSectionProps {
  rateCardData: Record<string, number>;
  scopeData: any;
  expanded: boolean;
  quoteData?: any;
  onToggle: () => void;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified?: (key: string) => boolean;
  showAllRates?: boolean;
}

export const ReceivingSection = ({
  rateCardData,
  scopeData,
  expanded,
  onToggle,
  onRateChange,
  isCustomizing,
  isRateModified = () => false,
  showAllRates = false
}: ReceivingSectionProps) => {
  // Only render section if there are receiving volume items or showAllRates is true
  const hasReceivingVolume = 
    scopeData.container20Volume > 0 || 
    scopeData.container40Volume > 0 || 
    scopeData.ftlVolume > 0 || 
    scopeData.ltlVolume > 0 || 
    scopeData.parcelVolume > 0;

  if (!hasReceivingVolume && !showAllRates) {
    return null;
  }

  return (
    <div className="mb-6 border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200">
      <div 
        className="flex justify-between items-center p-3 bg-gray-50 cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={onToggle}
      >
        <h3 className="text-lg font-semibold flex items-center">
          <Package className="h-5 w-5 mr-2 text-blue-600" />
          Receiving
        </h3>
        {expanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </div>
      
      {expanded && (
        <div className="overflow-x-auto p-3 animate-fade-in">
          <ReceivingTable 
            scopeData={scopeData}
            rateCardData={rateCardData}
            onRateChange={onRateChange}
            isCustomizing={isCustomizing}
            isRateModified={isRateModified}
            showAllRates={showAllRates}
          />
        </div>
      )}
    </div>
  );
};
