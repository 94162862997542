
import React from "react";
import { DatabaseQuote } from "@/pages/Quote/types";
import { ResearchContent } from "./ResearchContent";

interface ResearchTabProps {
  quote: DatabaseQuote;
}

const ResearchTab: React.FC<ResearchTabProps> = ({ quote }) => {
  return (
    <div className="space-y-6">
      {/* Company Research Section */}
      <ResearchContent quote={quote} />
    </div>
  );
};

export { ResearchTab };
export default ResearchTab;
