
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

interface QuoteTypeToggleProps {
  isFirmQuote: boolean;
  onChange: (checked: boolean) => void;
}

export const QuoteTypeToggle = ({ isFirmQuote, onChange }: QuoteTypeToggleProps) => {
  return (
    <div className="flex items-center space-x-2">
      <Switch 
        id="quote-type-toggle"
        checked={isFirmQuote}
        onCheckedChange={onChange}
      />
      <Label htmlFor="quote-type-toggle">
        {isFirmQuote ? 'Firm Quote' : 'Budgetary Quote'}
      </Label>
    </div>
  );
};
