
// Helper function to safely access nested properties
export const getNestedValue = (obj: any, path: string[], defaultValue: any = undefined) => {
  if (!obj) return defaultValue;
  
  let current = obj;
  for (const key of path) {
    if (current === null || current === undefined || typeof current !== 'object') {
      return defaultValue;
    }
    current = current[key];
  }
  return current !== undefined ? current : defaultValue;
};
