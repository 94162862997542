
import { TableCell, TableRow } from "@/components/ui/table";
import { formatCurrency } from "@/lib/utils";
import { RateCell } from "../RateCell";

interface CartonStorageRowProps {
  showCubicFootage: boolean;
  rate: number;
  quantity: number;
  cubicFootage?: number;
  cubicFootageRate?: number;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isModified: (key: string) => boolean;
}

export const CartonStorageRow = ({
  showCubicFootage,
  rate,
  quantity,
  cubicFootage = 0,
  cubicFootageRate = 0,
  onRateChange,
  isCustomizing,
  isModified
}: CartonStorageRowProps) => {
  if (!showCubicFootage) {
    return (
      <TableRow>
        <TableCell className="font-medium">Carton Shelf Storage</TableCell>
        <RateCell 
          fieldKey="storageShelves" 
          rate={rate || 0}
          onChange={onRateChange}
          isCustomizing={isCustomizing}
          isModified={isModified("storageShelves")}
          label="/cu. ft."
        />
        <TableCell>{quantity}</TableCell>
        <TableCell>
          {formatCurrency((rate || 0) * quantity)}
        </TableCell>
      </TableRow>
    );
  }
  
  return (
    <TableRow>
      <TableCell className="font-medium">Carton Shelf Storage</TableCell>
      <RateCell 
        fieldKey="storagePerCubicFoot" 
        rate={cubicFootageRate || 0}
        onChange={onRateChange}
        isCustomizing={isCustomizing}
        isModified={isModified("storagePerCubicFoot")}
        label="/cu. ft."
      />
      <TableCell>
        {cubicFootage.toFixed(2)} ft³
      </TableCell>
      <TableCell>
        {formatCurrency(cubicFootage * cubicFootageRate)}
      </TableCell>
    </TableRow>
  );
};
