
import { Facebook, Linkedin, Twitter } from "lucide-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { usePreviewMode } from "@/contexts/PreviewContext";

export const Footer = () => {
  const { adjustImagePath, isPreviewMode } = usePreviewMode();
  const [imgError, setImgError] = useState(false);
  const logoPath = "/lovable-uploads/177edcf5-8180-47b2-a62d-f1e5b983086a.png";
  
  // Use our standardized path handling function
  const adjustedLogoPath = adjustImagePath(logoPath);

  return (
    <footer className="bg-[#F1F0FB] mt-20">
      <div className="max-w-7xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            {!imgError ? (
              <img 
                src={adjustedLogoPath} 
                alt="Thrive 3PL" 
                className="h-12"
                onError={() => {
                  console.log("Footer logo failed to load:", {
                    original: logoPath,
                    adjusted: adjustedLogoPath,
                    isPreviewMode
                  });
                  setImgError(true);
                }}
              />
            ) : (
              <div className="h-12 w-12 bg-primary/10 flex items-center justify-center rounded-md">
                <span className="font-bold text-primary text-lg">T3PL</span>
              </div>
            )}
            <p className="text-gray-600 text-sm">
              Premier third-party logistics solutions tailored for modern businesses.
            </p>
            <div className="flex space-x-4">
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primary">
                <Linkedin className="h-5 w-5" />
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primary">
                <Facebook className="h-5 w-5" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primary">
                <Twitter className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Solutions */}
          <div>
            <h3 className="font-bold text-gray-900 mb-4">Solutions</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/fulfillment/b2b" className="text-gray-600 hover:text-primary text-sm">B2B Fulfillment</Link>
              </li>
              <li>
                <Link to="/fulfillment/dtc" className="text-gray-600 hover:text-primary text-sm">DTC Fulfillment</Link>
              </li>
              <li>
                <Link to="/fulfillment/fba" className="text-gray-600 hover:text-primary text-sm">Amazon FBA</Link>
              </li>
              <li>
                <Link to="/fulfillment/fbm" className="text-gray-600 hover:text-primary text-sm">Amazon FBM</Link>
              </li>
              <li>
                <Link to="/warehousing/storage" className="text-gray-600 hover:text-primary text-sm">Warehousing</Link>
              </li>
            </ul>
          </div>

          {/* Company */}
          <div>
            <h3 className="font-bold text-gray-900 mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/customers/why-thrive" className="text-gray-600 hover:text-primary text-sm">About Us</Link>
              </li>
              <li>
                <Link to="/locations/houston" className="text-gray-600 hover:text-primary text-sm">Locations</Link>
              </li>
              <li>
                <Link to="/company/investors" className="text-gray-600 hover:text-primary text-sm">Investors</Link>
              </li>
              <li>
                <Link to="/careers" className="text-gray-600 hover:text-primary text-sm">Careers</Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-600 hover:text-primary text-sm">Blog</Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-primary text-sm">Contact</Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="font-bold text-gray-900 mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="text-gray-600 text-sm">
                Houston, TX
              </li>
              <li>
                <a href="tel:+1-832-295-9090" className="text-gray-600 hover:text-primary text-sm">
                    832-295-9090
                </a>
              </li>
              <li>
                <a href="mailto:info@thrive3pl.com" className="text-gray-600 hover:text-primary text-sm">
                  info@thrive3pl.com
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-200 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-600 text-sm">
              © {new Date().getFullYear()} Thrive 3PL. All rights reserved.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <Link to="/privacy" className="text-gray-600 hover:text-primary text-sm">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-gray-600 hover:text-primary text-sm">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
