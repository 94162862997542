
import { BudgetaryTabProps } from "../../types";
import { useProspects } from "../../../../hooks/useProspects";
import { useQuoteRateTier } from "../../hooks/useQuoteRateTier";
import { hasCustomRates } from "@/utils/rates/recommendedTierUtils";
import { QuoteRateTierHeader } from "./QuoteRateTierHeader";
import { BudgetaryQuoteContainer } from "./BudgetaryQuoteContainer";
import { useCustomRatesManagement } from "./hooks/useCustomRatesManagement";
import { useSaveAndPushQuote } from "./hooks/useSaveAndPushQuote";

export const BudgetaryTab = ({ quote, approvedRateTier }: BudgetaryTabProps) => {
  // Extract rate tier logic to custom hook
  const {
    isFirmQuote,
    quoteMonthlyRevenue,
    currentTier,
    lastPushedAt,
    handleQuoteTypeChange,
    handleTierChange,
    handleMonthlyRevenueChange
  } = useQuoteRateTier({ quote, approvedRateTier });

  const { updateQuoteMutation } = useProspects();

  // Use our custom hooks for managing different aspects of functionality
  const {
    isCustomizing,
    toggleCustomization,
    handleSaveCustomRates,
    handleCustomRatesDetected,
    handleTierChangeFromSheet
  } = useCustomRatesManagement({
    quote,
    updateQuoteMutation,
    handleTierChange
  });

  // Fixed: Pass quote.id and quote.quote_version to useSaveAndPushQuote
  const { handleSaveAndPushRates, updatePending } = useSaveAndPushQuote(
    quote?.id || "",
    quote?.quote_version || 1
  );

  // Create a wrapper function to pass the required parameters
  const handleSaveAndPush = () => {
    return handleSaveAndPushRates(currentTier, isFirmQuote);
  };

  // Determine if this quote has custom rates
  const hasCustomRatesValue = quote ? hasCustomRates(quote) : false;

  return (
    <div className="space-y-4">
      <QuoteRateTierHeader
        isFirmQuote={isFirmQuote}
        lastPushedAt={lastPushedAt}
        isCustomizing={isCustomizing}
        handleQuoteTypeChange={handleQuoteTypeChange}
        handleSaveCustomRates={handleSaveCustomRates}
        toggleCustomization={toggleCustomization}
        handleSaveAndPushRates={handleSaveAndPush}
        updatePending={updateQuoteMutation.isPending || updatePending}
      />
      
      <BudgetaryQuoteContainer
        quote={quote}
        currentTier={currentTier}
        isFirmQuote={isFirmQuote}
        isCustomizing={isCustomizing}
        hasCustomRatesValue={hasCustomRatesValue}
        handleTierChangeFromSheet={handleTierChangeFromSheet}
        handleMonthlyRevenueChange={handleMonthlyRevenueChange}
        handleCustomRatesDetected={handleCustomRatesDetected}
      />
    </div>
  );
};
