
import { safeNumberConversion } from './dataConversion';
import { ScopeData, getDefaultScopeData } from './types/scopeTypes';
import { extractStorageData } from './extractors/storageExtractor';
import { extractFulfillmentData } from './extractors/fulfillmentExtractor';
import { extractReceivingData } from './extractors/receivingExtractor';

// Re-export the ScopeData interface and getDefaultScopeData function with proper type export
export type { ScopeData };
export { getDefaultScopeData };

// Extract scope data from quoteData
export const extractScopeData = (quoteData: any): ScopeData => {
  if (!quoteData) {
    console.warn("extractScopeData: quoteData is null or undefined");
    return getDefaultScopeData();
  }

  console.log("Extracting scope data from:", quoteData);
  
  // Extract storage data
  const storage = extractStorageData(quoteData);
  
  // Extract fulfillment data
  const fulfillment = extractFulfillmentData(quoteData);
  
  // Extract receiving data
  const receiving = extractReceivingData(quoteData);

  // Get total SKUs directly or with fallback
  const totalSkus = safeNumberConversion(quoteData.total_skus, 50);

  // Extract value-added services
  const valueAddedServices = quoteData.value_added_services || {};
  const hasKitting = !!valueAddedServices.kitting;
  const hasReturns = !!valueAddedServices.returnProcessing;
  const hasLabeling = !!valueAddedServices.customLabeling;

  // Construct and return the scope data
  return {
    ...storage,
    ...fulfillment,
    ...receiving,
    totalSkus,
    hasKitting,
    hasReturns,
    hasLabeling
  };
};
