
import React from "react";
import { Prospect } from "../types";

interface CompanyCellProps {
  prospect: Prospect;
}

export const CompanyCell = ({ prospect }: CompanyCellProps) => {
  return (
    <div className="flex items-center gap-2">
      {prospect.company}
      {prospect.archived && (
        <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded">
          Archived
        </span>
      )}
      {prospect.quote_version && prospect.quote_version > 1 && (
        <span className="text-xs text-blue-500 bg-blue-50 px-2 py-1 rounded">
          v{prospect.quote_version}
        </span>
      )}
    </div>
  );
};
