
import { Prospect, SortField, SortDirection } from "../types";

/**
 * Calculate annual value from monthly cost
 * This should be used with the calculated monthly costs from the frontend
 */
export const calculateAnnualValue = (prospect: Prospect, calculatedMonthlyValue?: number): number => {
  // If a calculated monthly value is provided, use that instead
  if (calculatedMonthlyValue !== undefined) {
    return calculatedMonthlyValue * 12;
  }
  
  // Fallback to 0 if no monthly value is available
  return 0;
};

/**
 * Sort prospects based on the provided sort field and direction
 */
export const getSortedProspects = (
  prospects: Prospect[], 
  sortField: SortField, 
  sortDirection: SortDirection,
  // New parameter to accept a map of prospect IDs to calculated monthly values
  calculatedMonthlyValues?: Record<string, number>
): Prospect[] => {
  return [...prospects].sort((a, b) => {
    const direction = sortDirection === 'asc' ? 1 : -1;
    
    switch (sortField) {
      case 'company':
        return direction * a.company.localeCompare(b.company);
      case 'annual_value':
        // Use calculated monthly values if available
        const aValue = calculateAnnualValue(a, calculatedMonthlyValues?.[a.id]);
        const bValue = calculateAnnualValue(b, calculatedMonthlyValues?.[b.id]);
        return direction * (aValue - bValue);
      case 'quote_status':
        return direction * (a.quote_status || '').localeCompare(b.quote_status || '');
      case 'created_at':
        // Sort by created_at date
        const aCreatedDate = a.created_at ? new Date(a.created_at).getTime() : 0;
        const bCreatedDate = b.created_at ? new Date(b.created_at).getTime() : 0;
        return direction * (aCreatedDate - bCreatedDate);
      case 'last_modified_at':
        // Sort by last_modified_at date
        const aModifiedDate = a.last_modified_at ? new Date(a.last_modified_at).getTime() : 0;
        const bModifiedDate = b.last_modified_at ? new Date(b.last_modified_at).getTime() : 0;
        return direction * (aModifiedDate - bModifiedDate);
      default:
        return 0;
    }
  });
};
