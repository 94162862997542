
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Prospect } from "@/pages/Admin/Sales/types";

export const useSaveAndPushQuote = (quoteId: string, quoteVersion: number) => {
  const [updatePending, setUpdatePending] = useState(false);

  const saveAndPushQuoteToClient = async (rateTier: string, isFirmQuote: boolean = false) => {
    setUpdatePending(true);
    try {
      console.log(`Pushing quote ${quoteId} (version ${quoteVersion}) to client as ${isFirmQuote ? "firm" : "budgetary"} quote`);
      
      const now = new Date().toISOString();
      
      // Update the quote status based on whether it's firm or budgetary
      const quoteStatus = isFirmQuote ? 'firm_to_client' : 'budgetary_to_client';
      
      const { error } = await supabase
        .from('detailed_quote_requests')
        .update({
          quote_type: isFirmQuote ? 'firm' : 'budgetary',
          pushed_to_client_at: now,
          approved_rate_tier: rateTier,
          quote_status: quoteStatus // Add this to update the status
        })
        .eq('id', quoteId);
      
      if (error) {
        console.error("Error pushing quote to client:", error);
        throw new Error("Failed to push quote to client");
      }
      
      toast.success(`Quote has been ${isFirmQuote ? "finalized" : "pushed"} to client`);
      return true;
    } catch (error: any) {
      console.error("Error in saveAndPushQuoteToClient:", error);
      toast.error(error.message || "An error occurred while pushing the quote");
      return false;
    } finally {
      setUpdatePending(false);
    }
  };

  // Add the handleSaveAndPushRates function that's being used in BudgetaryTab.tsx
  const handleSaveAndPushRates = (currentTier: string, isFirmQuote: boolean) => {
    return saveAndPushQuoteToClient(currentTier, isFirmQuote);
  };

  return {
    saveAndPushQuoteToClient,
    handleSaveAndPushRates,
    updatePending
  };
};
