
import { TableCell } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { useState, useEffect } from "react";
import { Flag } from "lucide-react";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@/components/ui/tooltip";

interface RateCellProps {
  fieldKey: string;
  rate: number;
  onChange?: (key: string, value: string) => void;
  isCustomizing?: boolean;
  simpleView?: boolean;
  isModified?: boolean;
  showModificationIndicator?: boolean;
  label?: string;
  isEditable?: boolean;
}

export const RateCell = ({
  fieldKey,
  rate,
  onChange = () => {},
  isCustomizing = false,
  simpleView = false,
  isModified = false,
  showModificationIndicator = false,
  label,
  isEditable = true
}: RateCellProps) => {
  // Local state to track input value during editing
  const [inputValue, setInputValue] = useState(rate.toFixed(2));
  
  // Update local state when prop changes
  useEffect(() => {
    // Make sure we use the latest rate value from props
    setInputValue(rate.toFixed(2));
  }, [rate]);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };
  
  const handleBlur = () => {
    // Only update parent state when focus is lost
    onChange(fieldKey, inputValue);
  };
  
  // For simple view (client view), just render the rate simply
  if (simpleView) {
    return (
      <TableCell className="text-right font-medium">
        <span className="text-green-700">${rate.toFixed(2)}</span>
      </TableCell>
    );
  }
  
  // For non-customizing view (like admin preview)
  if (!isCustomizing) {
    return (
      <TableCell className="text-right font-medium">
        <div className="flex flex-col items-end">
          <div className="flex items-center">
            <span className="text-green-700">${rate.toFixed(2)}</span>
            {isModified && showModificationIndicator && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Flag className="h-4 w-4 ml-1 text-orange-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Modified from standard rate</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          {label && <div className="text-xs text-gray-500 mt-1">{label}</div>}
        </div>
      </TableCell>
    );
  }
  
  // This is only for admin view customization mode
  return (
    <TableCell className="text-right">
      <div className="flex items-center justify-end">
        <Input
          type="number"
          min="0"
          step="0.01"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={`w-24 h-8 text-right ${isModified && showModificationIndicator ? 'border-orange-400 ring-1 ring-orange-300' : ''}`}
          disabled={!isEditable}
        />
        {isModified && showModificationIndicator && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Flag className="h-4 w-4 ml-1 text-orange-500" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Modified from standard rate</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      {label && <div className="text-xs text-gray-500 mt-1">{label}</div>}
    </TableCell>
  );
};
