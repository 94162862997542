
import { FC } from "react";
import { StatsCards } from "./StatsCards";
import { PipelineCard } from "./PipelineCard";
import { Prospect, SortDirection, SortField } from "../types";

interface SalesPageContentProps {
  activeProspects: Prospect[];
  archivedProspects: Prospect[];
  prospects: Prospect[] | undefined;
  isLoading: boolean;
  showArchived: boolean;
  onShowArchivedChange: (value: boolean) => void;
  onToggleArchive: (prospectId: string, currentArchived: boolean) => void;
  sortField: SortField;
  sortDirection: SortDirection;
  onSort: (field: SortField) => void;
  onUpdateMonthlyValue?: (prospectId: string, monthlyValue: number) => void;
  calculatedMonthlyValues?: Record<string, number>;
}

export const SalesPageContent: FC<SalesPageContentProps> = ({
  activeProspects,
  archivedProspects,
  prospects,
  isLoading,
  showArchived,
  onShowArchivedChange,
  onToggleArchive,
  sortField,
  sortDirection,
  onSort,
  onUpdateMonthlyValue,
  calculatedMonthlyValues
}) => {
  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Sales Pipeline</h1>

      <StatsCards 
        activeProspects={activeProspects}
        archivedProspects={archivedProspects}
      />

      <PipelineCard
        prospects={prospects || []}
        isLoading={isLoading}
        showArchived={showArchived}
        onShowArchivedChange={onShowArchivedChange}
        onToggleArchive={onToggleArchive}
        sortField={sortField}
        sortDirection={sortDirection}
        onSort={onSort}
        onUpdateMonthlyValue={onUpdateMonthlyValue}
        calculatedMonthlyValues={calculatedMonthlyValues}
      />
    </div>
  );
};
