
import { motion } from "framer-motion";
import { Check } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Card, CardContent } from "@/components/ui/card";

export const TestimonialCard = () => {
  return (
    <motion.div 
      initial={{
        opacity: 0,
        y: 20
      }} 
      animate={{
        opacity: 1,
        y: 0
      }} 
      transition={{
        duration: 0.5,
        delay: 0.3
      }} 
      className="absolute bottom-24 right-12 md:right-16 lg:right-24 max-w-sm z-20"
    >
      <Card className="bg-gradient-to-br from-white to-[#F2FCE2] shadow-xl border-l-4 border-l-primary overflow-hidden rounded-2xl p-1">
        <CardContent className="p-4">
          <h2 className="font-bold text-[#001801] mb-3 text-lg">Turtlebox partnered with Thrive to:</h2>
          
          <div className="mb-4 space-y-2">
            <div className="flex items-start">
              <Check className="h-4 w-4 mr-2 text-emerald-600 mt-1 flex-shrink-0" />
              <p className="text-gray-700 text-sm">Fulfill across 7 sales channels</p>
            </div>
            <div className="flex items-start">
              <Check className="h-4 w-4 mr-2 text-emerald-600 mt-1 flex-shrink-0" />
              <p className="text-gray-700 text-sm">Manage returns and warranty claims</p>
            </div>
            <div className="flex items-start">
              <Check className="h-4 w-4 mr-2 text-emerald-600 mt-1 flex-shrink-0" />
              <p className="text-gray-700 text-sm">Launch new products and <span className="italic font-medium text-emerald-700">triple sales</span> in under a year</p>
            </div>
          </div>
          
          <div className="mt-4 bg-white/80 p-3 rounded-lg border-l-2 border-l-emerald-600">
            <blockquote className="text-gray-700 text-sm mb-2 italic">
              "The Thrive 3PL team treats our products like their own. Their care and dedication have been absolutely invaluable."
            </blockquote>
            
            <div className="flex items-center mt-2">
              <div>
                <p className="text-[#001801] font-bold text-sm">Will Bradley</p>
                <p className="text-gray-600 text-xs">Co-Founder, Turtlebox Audio</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};
