
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { toast } from '@/hooks/use-toast';
import { Prospect, SortDirection, SortField } from '../types';
import { getRecommendedTier } from '@/utils/rates/recommendedTierUtils';

export const useProspects = (
  includeArchived: boolean = false,
  sortField: SortField = 'last_modified_at',
  sortDirection: SortDirection = 'desc'
) => {
  const queryClient = useQueryClient();

  const { data: prospects, isLoading, isError, error } = useQuery({
    queryKey: ['prospects', includeArchived, sortField, sortDirection],
    queryFn: async () => {
      console.log(`Fetching prospects (includeArchived: ${includeArchived}, sortField: ${sortField}, sortDirection: ${sortDirection})`);
      
      let query = supabase
        .from('detailed_quote_requests')
        .select('*');

      if (!includeArchived) {
        query = query.eq('archived', false);
      }
      
      // Set dynamic sort order based on parameters
      query = query.order(sortField, { ascending: sortDirection === 'asc' });

      const { data, error } = await query;
      
      if (error) {
        console.error('Error loading prospects:', error);
        throw error;
      }
      
      console.log(`Fetched ${data?.length || 0} prospects`);
      // Map detailed_quote_requests to Prospect type
      return data as Prospect[];
    }
  });

  // Toggle archive mutation
  const toggleArchiveMutation = useMutation({
    mutationFn: async ({ prospectId, archived }: { prospectId: string, archived: boolean }) => {
      console.log(`Calling supabase to update prospect ${prospectId} archived status to ${archived}`);
      
      const { data, error } = await supabase
        .from('detailed_quote_requests')
        .update({ archived })
        .eq('id', prospectId)
        .select();
        
      if (error) {
        console.error('Supabase error in toggleArchiveMutation:', error);
        throw error;
      }
      
      console.log('Toggle archive success, response:', data);
      return { prospectId, archived };
    },
    onSuccess: (data) => {
      console.log('Toggle archive mutation successful, invalidating queries');
      queryClient.invalidateQueries({ queryKey: ['prospects'] });
    },
    onError: (error) => {
      console.error('Error toggling archive status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update prospect status. Please try again.',
        variant: 'destructive'
      });
    }
  });

  // Update quote mutation for all prospect data updates including rate tier changes
  const updateQuoteMutation = useMutation({
    mutationFn: async ({ prospectId, data }: { prospectId: string, data: Partial<Prospect> }) => {
      console.log('Updating quote with data:', data);
      
      // Removed .single() since it was causing issues
      const { data: updatedQuote, error } = await supabase
        .from('detailed_quote_requests')
        .update(data)
        .eq('id', prospectId)
        .select();
        
      if (error) {
        console.error('Error in supabase update:', error);
        throw error;
      }
      
      return { prospectId, updatedQuote };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prospects'] });
      toast({
        title: 'Quote Updated',
        description: 'Quote details have been successfully updated.',
        variant: 'default'
      });
    },
    onError: (error: any) => {
      console.error('Error updating quote:', error);
      toast({
        title: 'Error',
        description: `Failed to update quote: ${error.message || 'Please try again.'}`,
        variant: 'destructive'
      });
    }
  });

  return {
    prospects,
    isLoading,
    isError,
    error,
    toggleArchiveMutation,
    updateQuoteMutation
  };
};
