
interface LastPushedInfoProps {
  lastPushedAt: string;
}

export const LastPushedInfo = ({ lastPushedAt }: LastPushedInfoProps) => {
  return (
    <span className="text-xs text-gray-500">
      Last pushed: {lastPushedAt}
    </span>
  );
};
