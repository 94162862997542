
import { useEffect } from "react";
import {
  Sidebar,
  SidebarContent,
  SidebarSeparator,
} from "@/components/ui/sidebar";
import { AchievementMenuItems } from "./sidebar/AchievementMenuItems";
import { SidebarSection } from "./sidebar/SidebarSection";
import { SettingsSection } from "./sidebar/SettingsSection";
import { menuSections } from "./sidebar/MenuSections";
import { useQuoteData } from "@/hooks/useQuoteData";
import { useLocation } from "react-router-dom";
import { useClientProgress } from "@/hooks/useClientProgress";

export function ClientSidebar() {
  const { allQuotes, fetchLatestQuoteData } = useQuoteData();
  const { formattedSteps, fetchProgressData } = useClientProgress();
  const location = useLocation();
  
  // Refresh quote data when navigating between routes
  useEffect(() => {
    fetchLatestQuoteData();
    fetchProgressData();
  }, [location.pathname, fetchLatestQuoteData, fetchProgressData]);
  
  // Get the first section for Dashboard
  const dashboardSection = menuSections[0];
  
  // Get latest quote and its status to determine access to certain features
  const latestQuote = allQuotes.length > 0 ? allQuotes[0] : null;
  const latestQuoteStatus = latestQuote?.quote_status;
  
  // Find the discovery step to check completion status
  const discoveryStep = formattedSteps.find(step => step.type === 'discovery');
  const quoteStep = formattedSteps.find(step => step.type === 'quote');
  const contractStep = formattedSteps.find(step => step.type === 'contract');
  const onboardingStep = formattedSteps.find(step => step.type === 'onboarding');
  
  // Check discovery completion status directly
  const isDiscoveryComplete = discoveryStep?.status === 'completed';
  // Check if we have a discovery conversation
  const hasDiscoveryConversation = latestQuote?.discovery_conversation && 
                                 Array.isArray(latestQuote.discovery_conversation) && 
                                 latestQuote.discovery_conversation.length > 0;
  
  // Check contract status
  const isContractComplete = contractStep?.status === 'completed';
  const isContractInProgress = contractStep?.status === 'in_progress';
  const isContractSigned = latestQuoteStatus === 'contract_signed';
  
  console.log("Sidebar Navigation Status:", {
    quoteStatus: latestQuoteStatus,
    discoveryStatus: discoveryStep?.status,
    discoveryComplete: isDiscoveryComplete,
    hasDiscoveryConversation,
    contractStatus: contractStep?.status,
    contractComplete: isContractComplete,
    contractSigned: isContractSigned,
    onboardingStatus: onboardingStep?.status
  });
  
  // Get the Getting Started section and add achievement badges and access status
  const gettingStartedSection = {
    ...menuSections[1],
    items: menuSections[1].items.map(item => {
      // Define completion status and access status based on the progress steps
      let completionStatus: "incomplete" | "in-progress" | "complete" | undefined = "incomplete";
      let nextStep = false;
      let isGreyedOut = false;
      
      // QUOTES SECTION
      if (item.title === "Quotes") {
        // Quote is always accessible and stays "in-progress" until we have a firm quote
        completionStatus = (latestQuoteStatus === "firm_to_client" || latestQuoteStatus === "contract_signed") 
          ? "complete" 
          : "in-progress";
        nextStep = false;
      }
      
      // DISCOVERY SECTION
      else if (item.title === "Discovery") {
        // Discovery shows accurate completion state
        if (isDiscoveryComplete || hasDiscoveryConversation) {
          completionStatus = "complete";
          nextStep = false;
        } else if (discoveryStep?.status === 'in_progress' || 
                  (discoveryStep?.substeps && discoveryStep.substeps.some(s => s.active || s.completed))) {
          completionStatus = "in-progress";
          nextStep = true;
        } else {
          // If quotes completed but discovery not started
          completionStatus = "incomplete";
          nextStep = quoteStep?.status === 'completed';
        }
      }
      
      // CONTRACT SECTION
      else if (item.title === "Contract") {
        if (isContractComplete || isContractSigned) {
          completionStatus = "complete";
          nextStep = false;
        } else if (isContractInProgress || latestQuoteStatus === "firm_to_client") {
          completionStatus = "in-progress";
          nextStep = true;
        } else {
          completionStatus = "incomplete";
          // Grey out contract unless we have a firm quote
          isGreyedOut = latestQuoteStatus !== "firm_to_client" && latestQuoteStatus !== "contract_signed";
        }
      }
      
      // ONBOARDING SECTION
      else if (item.title === "Onboard") {
        if (onboardingStep?.status === 'completed') {
          completionStatus = "complete";
          nextStep = false;
        } else if (onboardingStep?.status === 'in_progress' || isContractSigned) {
          completionStatus = "in-progress";
          nextStep = true;
        } else {
          completionStatus = "incomplete";
          // Grey out onboarding unless contract is completed or in signed status
          isGreyedOut = !isContractComplete && !isContractSigned;
        }
      }
      
      return {
        ...item,
        completionStatus,
        nextStep,
        isGreyedOut
      };
    })
  };
  
  // Get the Support & Resources section - always accessible
  const supportSection = menuSections[2];
  
  return (
    <Sidebar>
      <SidebarContent>
        <SidebarSection 
          items={dashboardSection.items}
        />
        
        <AchievementMenuItems 
          label={gettingStartedSection.label}
          items={gettingStartedSection.items}
        />
        
        <SidebarSection 
          label={supportSection.label}
          items={supportSection.items}
        />

        <SidebarSeparator />
        
        <SettingsSection />
      </SidebarContent>
    </Sidebar>
  );
}
