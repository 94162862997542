
import { TableHead, TableHeader, TableRow } from "@/components/ui/table";

export const StorageTableHeader = () => {
  return (
    <TableHeader>
      <TableRow>
        <TableHead className="w-[50%]">Service</TableHead>
        <TableHead>Rate</TableHead>
        <TableHead>Quantity</TableHead>
        <TableHead>Extended</TableHead>
      </TableRow>
    </TableHeader>
  );
};
