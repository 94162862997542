
import { useState, useEffect } from "react";

export interface ResearchSections {
  all: string;
}

/**
 * Hook to handle sectioning of research data for display
 */
export const useResearchSections = (research: string | null) => {
  const [sections, setSections] = useState<ResearchSections>({
    all: research || ''
  });
  const [hasLimitedInfo, setHasLimitedInfo] = useState(false);

  // Update sections when research changes
  useEffect(() => {
    if (research) {
      setSections({ all: research });
      setHasLimitedInfo(checkForLimitedInfo(research));
    }
  }, [research]);

  /**
   * Checks if the research contains patterns indicating limited information
   */
  const checkForLimitedInfo = (research: string) => {
    if (!research) return false;
    
    const noInfoPatterns = [
      /I couldn['']t find (specific|detailed|any) information/i,
      /no (specific|detailed|reliable) information (is available|available|found|exists)/i,
      /no information (is available|available|found|exists)/i,
      /I (don['']t|do not|cannot) have (specific|detailed|any) information/i,
      /there (is|seems to be) (little|limited|no) information/i,
      /Research Error/i,
      /without web search capabilities/i
    ];
    
    return noInfoPatterns.some(pattern => pattern.test(research));
  };

  return {
    sections,
    hasLimitedInfo
  };
};
