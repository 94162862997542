
import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { TableCell, TableRow } from "@/components/ui/table";
import { Archive, Eye, MessageSquare } from "lucide-react";
import { Prospect } from "../types";
import { CompanyCell } from "./CompanyCell";
import { StatusBadge } from "./StatusBadge";
import { ScopeTooltip } from "./ScopeTooltip";
import { DetailedViewTrigger } from "./ScopeTooltip/DetailedViewTrigger";
import { formatCurrency, formatDate, formatTimeElapsed } from "@/lib/utils";
import { calculateQuoteDetails, extractScopeData } from "../components/QuoteCalculator/utils";
import { isObject } from "@/lib/utils";
import { RateTier } from "@/utils/rates/types";
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

interface ProspectTableRowProps {
  prospect: Prospect;
  onToggleArchive: (id: string, archived: boolean) => void;
  onUpdateMonthlyValue?: (prospectId: string, monthlyValue: number) => void;
  monthlyValue?: number;
}

export const ProspectTableRow = ({
  prospect,
  onToggleArchive,
  onUpdateMonthlyValue,
  monthlyValue
}: ProspectTableRowProps) => {
  const { toast } = useToast();
  const [annualValue, setAnnualValue] = useState(0);
  const [isConverting, setIsConverting] = useState(false);

  useEffect(() => {
    if (prospect) {
      try {
        const scopeData = extractScopeData(prospect);
        const rateTier = prospect.approved_rate_tier || 'D2C';
        const calculationTier = rateTier === 'CUSTOM' 
          ? (prospect.original_rate_tier as RateTier || 'D2C' as RateTier) 
          : rateTier as RateTier;
          
        const customRates = prospect.custom_rates && isObject(prospect.custom_rates) 
          ? prospect.custom_rates as Record<string, number>
          : undefined;
          
        const quoteDetails = calculateQuoteDetails(
          calculationTier,
          scopeData,
          customRates
        );
        
        if (quoteDetails && quoteDetails.monthly && quoteDetails.monthly.total) {
          const monthlyTotal = quoteDetails.monthly.total;
          setAnnualValue(monthlyTotal * 12);
          
          // Report the monthly value to the parent for sorting
          if (onUpdateMonthlyValue) {
            onUpdateMonthlyValue(prospect.id, monthlyTotal);
          }
        } else {
          setAnnualValue(0);
          if (onUpdateMonthlyValue) {
            onUpdateMonthlyValue(prospect.id, 0);
          }
        }
      } catch (err) {
        console.error(`Error calculating annual value for prospect ${prospect.id}:`, err);
        setAnnualValue(0);
        if (onUpdateMonthlyValue) {
          onUpdateMonthlyValue(prospect.id, 0);
        }
      }
    }
  }, [prospect, onUpdateMonthlyValue]);

  // Use the pre-calculated value if available
  const displayAnnualValue = monthlyValue !== undefined 
    ? monthlyValue * 12 
    : annualValue;

  const handleConvertToQuote = async () => {
    if (!prospect || !prospect.id) return;
    
    setIsConverting(true);
    try {
      const { error } = await supabase
        .from('detailed_quote_requests')
        .update({ 
          quote_status: 'pending_review',
          company: prospect.company === 'TBD' ? `${prospect.first_name}'s Company` : prospect.company
        })
        .eq('id', prospect.id);

      if (error) throw error;
      
      toast({
        title: "Inquiry Converted",
        description: "The contact inquiry has been converted to a quote request.",
        variant: "default"
      });
    } catch (error) {
      console.error('Error converting inquiry to quote:', error);
      toast({
        title: "Conversion Failed",
        description: "There was an error converting the inquiry to a quote.",
        variant: "destructive"
      });
    } finally {
      setIsConverting(false);
    }
  };

  const isContactInquiry = prospect.quote_status === 'contact_inquiry';
  
  // Determine if the lead is stale (more than 3 days since last update)
  const lastModifiedDate = prospect.last_modified_at || prospect.created_at;
  const daysSinceUpdate = lastModifiedDate ? Math.floor((new Date().getTime() - new Date(lastModifiedDate).getTime()) / (1000 * 60 * 60 * 24)) : 0;
  const isStale = daysSinceUpdate > 3;

  return (
    <TableRow>
      <TableCell className="font-medium">
        <CompanyCell prospect={prospect} />
      </TableCell>
      <TableCell>
        {prospect.created_at ? formatDate(prospect.created_at) : 'N/A'}
      </TableCell>
      <TableCell>
        <span className={isStale ? "text-amber-600 font-medium" : ""}>
          {prospect.last_modified_at ? formatTimeElapsed(prospect.last_modified_at) : 'N/A'}
        </span>
      </TableCell>
      <TableCell className="text-right font-mono">
        {formatCurrency(displayAnnualValue)}
      </TableCell>
      <TableCell>
        {isContactInquiry ? 
          <div className="text-sm text-gray-600 italic">
            Initial Inquiry
          </div> :
          <ScopeTooltip prospect={prospect} />
        }
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-end gap-2">
          <DetailedViewTrigger quote={prospect}>
            <Button
              variant="outline"
              size="sm"
              className="text-xs"
            >
              <Eye className="h-3.5 w-3.5 mr-1" />
              <span>View {isContactInquiry ? 'Inquiry' : 'Quote'}</span>
            </Button>
          </DetailedViewTrigger>
          
          {isContactInquiry ? (
            <Button
              variant="outline"
              size="sm"
              onClick={handleConvertToQuote}
              disabled={isConverting}
              className="text-xs"
            >
              <MessageSquare className="h-3.5 w-3.5 mr-1" />
              <span>{isConverting ? 'Converting...' : 'Convert to Quote'}</span>
            </Button>
          ) : null}
          
          <Button
            variant={prospect.archived ? "default" : "outline"}
            size="sm"
            onClick={() => onToggleArchive(prospect.id, prospect.archived || false)}
            className="text-xs"
          >
            <Archive className="h-3.5 w-3.5 mr-1" />
            <span>{prospect.archived ? "Unarchive" : "Archive"}</span>
          </Button>
        </div>
      </TableCell>
      <TableCell className="text-center">
        <StatusBadge status={prospect.quote_status || 'pending_review'} />
      </TableCell>
    </TableRow>
  );
};
