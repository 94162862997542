import AppRoutes from "./routes";
import { Toaster } from 'sonner';
import { ErrorBoundary } from "./components/ui/error-boundary";
import { RedirectsHandler } from "./components/RedirectsHandler";
import * as Sentry from "@sentry/react";

function AppComponent() {
  return (
    <ErrorBoundary fallback={<div className="p-4">Something went wrong. Please try again later.</div>}>
      <div className="app-container">
        <RedirectsHandler />
        <AppRoutes />
        <Toaster position="top-right" />
      </div>
    </ErrorBoundary>
  );
}

// Wrap the component with Sentry's performance monitoring
const App = Sentry.withProfiler(AppComponent);

export default App;
