
/**
 * Utility to detect if the application is running in preview mode
 * Uses a combination of environment variables and iframe detection
 */
export const detectPreviewEnvironment = (): boolean => {
  try {
    // Primary detection through environment variable
    const isPreviewEnv = import.meta.env.LOVABLE_PREVIEW === 'true';
    
    // Detect iframe embedding
    const isInIframe = window !== window.parent;
    
    // Check URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const hasPreviewParam = urlParams.has('preview') || urlParams.has('lovable-preview');
    
    // Secondary detection through hostname patterns
    const hostname = window.location.hostname;
    const hostnameIsPreview = 
      hostname.includes('lovable.dev') || 
      hostname.includes('lovable.ai') || 
      hostname.includes('localhost') || 
      hostname === '127.0.0.1' || 
      hostname.includes('lovableproject.com') ||
      hostname.includes('lovable.app');
    
    // Combine detection methods
    const result = isPreviewEnv || isInIframe || hasPreviewParam || hostnameIsPreview;
    
    console.log('[Preview Detection] Result:', { 
      isPreview: result,
      envValue: import.meta.env.LOVABLE_PREVIEW,
      hostname: window.location.hostname,
      isInIframe: isInIframe,
      hasPreviewParam: hasPreviewParam
    });
    
    return result;
  } catch (error) {
    console.error('[Preview Detection] Error:', error);
    // Default to false on error
    return false;
  }
};
