
import { useState, useCallback } from "react";
import { useQuoteData } from "@/hooks/useQuoteData";
import { useProgressData } from "./useProgressData";
import { useFormattedSteps } from "./useFormattedSteps";
import { useProgressUpdater } from "./useProgressUpdater";
import { getStepIcon } from "./constants";

export const useClientProgress = () => {
  const { allQuotes } = useQuoteData();
  
  // Get the latest quote status and discovery conversation
  const latestQuote = allQuotes.length > 0 ? allQuotes[0] : null;
  const quoteStatus = latestQuote?.quote_status;
  const discoveryConversation = latestQuote?.discovery_conversation;
  
  // Get the progress data
  const { progressData, isLoading, authChecked, fetchProgressData } = useProgressData();
  
  // Format steps based on progress data
  const { formattedSteps, activeStepType } = useFormattedSteps(
    progressData, 
    quoteStatus, 
    discoveryConversation
  );
  
  // Get progress update functions
  const { updateProgressStep, updateSubstep } = useProgressUpdater(
    progressData, 
    fetchProgressData
  );
  
  return {
    progressData,
    formattedSteps,
    activeStepType,
    isLoading,
    authChecked,
    fetchProgressData,
    updateProgressStep,
    updateSubstep,
    getStepIcon
  };
};
