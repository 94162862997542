
import { ScopeData } from '../types/scopeTypes';

/**
 * Calculates receiving costs based on scope data and rate data
 */
export const calculateReceivingCosts = (scopeData: ScopeData, rateData: Record<string, number>): number => {
  // Calculate costs based on loading type and pallet counts
  let receiving20ContainerCost = 0;
  let receiving40ContainerCost = 0;
  let receivingFTLCost = 0;

  // 20ft Container - Use pallet rate if palletized
  if (scopeData.loadingTypes.container20 === 'palletized' && scopeData.palletCounts.container20 && scopeData.palletCounts.container20 > 0) {
    receiving20ContainerCost = (rateData.receivingStandardPallet || 0) * 
                            (scopeData.palletCounts.container20 * scopeData.container20Volume);
  } else {
    receiving20ContainerCost = (rateData.receiving20Container || 0) * scopeData.container20Volume;
  }

  // 40ft Container - Use pallet rate if palletized
  if (scopeData.loadingTypes.container40 === 'palletized' && scopeData.palletCounts.container40 && scopeData.palletCounts.container40 > 0) {
    receiving40ContainerCost = (rateData.receivingStandardPallet || 0) * 
                            (scopeData.palletCounts.container40 * scopeData.container40Volume);
  } else {
    receiving40ContainerCost = (rateData.receiving40Container || 0) * scopeData.container40Volume;
  }

  // FTL - Use pallet rate if palletized
  if (scopeData.loadingTypes.ftl === 'palletized' && scopeData.palletCounts.ftl && scopeData.palletCounts.ftl > 0) {
    receivingFTLCost = (rateData.receivingStandardPallet || 0) * 
                      (scopeData.palletCounts.ftl * scopeData.ftlVolume);
  } else {
    receivingFTLCost = (rateData.receivingFTL || 0) * scopeData.ftlVolume;
  }

  const receivingLTLCost = (rateData.receivingLTL || 0) * scopeData.ltlVolume;
  const receivingParcelCost = (rateData.receivingParcel || 0) * scopeData.parcelVolume;

  return receiving20ContainerCost + receiving40ContainerCost + receivingFTLCost + receivingLTLCost + receivingParcelCost;
};
