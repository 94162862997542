
// Calculate cost for 20ft container
export const calculate20ftContainerCost = (
  scopeData: any, 
  rateCardData: Record<string, number>
): number => {
  if (
    scopeData.loadingTypes?.container20 === "palletized" && 
    scopeData.palletCounts?.container20 > 0
  ) {
    // Palletized calculation
    const totalPallets = scopeData.container20Volume * scopeData.palletCounts.container20;
    return (rateCardData.receivingStandardPallet || 0) * totalPallets;
  } else {
    // Standard container calculation
    return (rateCardData.receiving20Container || 0) * (scopeData.container20Volume || 0);
  }
};

// Calculate cost for 40ft container
export const calculate40ftContainerCost = (
  scopeData: any, 
  rateCardData: Record<string, number>
): number => {
  if (
    scopeData.loadingTypes?.container40 === "palletized" && 
    scopeData.palletCounts?.container40 > 0
  ) {
    // Palletized calculation
    const totalPallets = scopeData.container40Volume * scopeData.palletCounts.container40;
    return (rateCardData.receivingStandardPallet || 0) * totalPallets;
  } else {
    // Standard container calculation
    return (rateCardData.receiving40Container || 0) * (scopeData.container40Volume || 0);
  }
};

// Calculate cost for FTL
export const calculateFTLCost = (
  scopeData: any, 
  rateCardData: Record<string, number>
): number => {
  if (
    scopeData.loadingTypes?.ftl === "palletized" && 
    scopeData.palletCounts?.ftl > 0
  ) {
    // Palletized calculation
    const totalPallets = scopeData.ftlVolume * scopeData.palletCounts.ftl;
    return (rateCardData.receivingStandardPallet || 0) * totalPallets;
  } else {
    // Standard FTL calculation
    return (rateCardData.receivingFTL || 0) * (scopeData.ftlVolume || 0);
  }
};

// Calculate LTL cost - LTL is measured in pallets, so we use the pallet rate
export const calculateLTLCost = (
  scopeData: any, 
  rateCardData: Record<string, number>
): number => {
  // Use receivingStandardPallet rate since LTL volume represents number of pallets
  return (rateCardData.receivingStandardPallet || 0) * (scopeData.ltlVolume || 0);
};

// Calculate small parcel cost
export const calculateParcelCost = (
  scopeData: any, 
  rateCardData: Record<string, number>
): number => {
  return (rateCardData.receivingParcel || rateCardData.receivingStandardCarton || 0) * (scopeData.parcelVolume || 0);
};
