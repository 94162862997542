
import { ScopeData } from '../types/scopeTypes';

/**
 * Calculates storage costs based on scope data and rate data
 */
export const calculateStorageCosts = (scopeData: ScopeData, rateData: Record<string, number>): number => {
  const first100Pallets = Math.min(100, scopeData.palletPositions);
  const additionalPallets = Math.max(0, scopeData.palletPositions - 100);

  // Calculate pallet storage costs with tiered pricing
  const storageFirst100PalletsCost = (rateData.storageFirst100Pallets || 0) * first100Pallets;
  const storageAdditionalPalletsCost = (rateData.storageAdditionalPallets || 0) * additionalPallets;
  
  // Calculate bin storage costs based on positions
  const storageBinCost = (rateData.storageBin || 0) * scopeData.itemPositions;
  
  // Calculate carton storage based on cubic footage if available, otherwise use shelf positions
  let storageShelvesCost = 0;
  
  // If we have cubic footage data and a cubic footage rate, use that for the calculation
  if (scopeData.cartonCubicFootage > 0 && rateData.storagePerCubicFoot) {
    // Calculate storage based directly on the cubic footage
    storageShelvesCost = scopeData.cartonCubicFootage * rateData.storagePerCubicFoot;
  } else {
    // Fall back to position-based calculation if no cubic footage data
    storageShelvesCost = (rateData.storageShelves || 0) * scopeData.cartonPositions;
  }

  return storageFirst100PalletsCost + storageAdditionalPalletsCost + storageBinCost + storageShelvesCost;
};
