
import { BudgetaryQuoteState } from "./types";
import { RateTier, rateTable } from "@/utils/rates";

// Function to initialize rate card data based on tier
export const initializeRateCardData = (
  baseTier: RateTier,
  hasCustomRates: boolean,
  customRates: Record<string, number>
): Record<string, number> => {
  // Start with an empty rates object
  let rates: Record<string, number> = {};
  
  // Get the base tier rates for reference
  const baseTierRates = { ...rateTable[baseTier] };
  
  // Log initialization parameters
  console.log(`rateUtils: Initializing rates with base tier ${baseTier}`, {
    baseRateCount: Object.keys(baseTierRates).length,
    hasCustomRates,
    customRateCount: Object.keys(customRates).length,
    customRateSample: Object.entries(customRates).slice(0, 3)
  });
  
  // Check if we have any valid custom rates directly
  const customRateKeys = Object.keys(customRates);
  const hasValidCustomRates = customRateKeys.length > 0;

  // Apply base rates first to all fields
  Object.entries(baseTierRates).forEach(([key, value]) => {
    // Check if this specific field has a custom rate directly
    const hasCustomRateForField = customRates[key] !== undefined && 
      customRates[key] !== null;
    
    if (!hasCustomRateForField) {
      // Use base rate when no custom rate exists for this field
      rates[key] = value;
    } else {
      // Field has a custom rate - it will be applied in the next step
      console.log(`Field ${key} has custom rate: ${customRates[key]}, skipping base rate: ${value}`);
    }
  });
  
  // Apply custom rates directly - this step will override any base rates when custom rates exist
  if (hasValidCustomRates) {
    console.log("Applying custom rates directly:");
    
    Object.entries(customRates).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        // Ensure the value is a number
        const numValue = typeof value === 'number' ? value : parseFloat(String(value));
        if (!isNaN(numValue)) {
          const oldValue = rates[key];
          rates[key] = numValue;
          console.log(`  - Applied custom rate for ${key}: ${oldValue} → ${numValue}`);
        } else {
          console.log(`  - Skipped invalid custom rate for ${key}: ${value}`);
        }
      }
    });
    
    console.log("Final rates after custom rates applied:", 
      Object.fromEntries(Object.entries(rates).slice(0, 5)));
  } else {
    console.log("No valid custom rates to apply, using standard tier rates");
  }
  
  return rates;
};

// Default scope data with empty loadingTypes and palletCounts
export const getDefaultScopeData = () => ({
  palletPositions: 0,
  monthlyOrders: 0,
  parcelOrders: 0,
  palletOrders: 0,
  cartonOrders: 0,
  fbaOrders: 0,
  cartonPositions: 0,
  itemPositions: 0,
  container20Volume: 0,
  container40Volume: 0,
  ftlVolume: 0,
  ltlVolume: 0,
  parcelVolume: 0,
  totalSkus: 50,
  loadingTypes: {},
  palletCounts: {},
  hasKitting: false,
  hasReturns: false,
  hasLabeling: false
});

// Default expanded sections
export const getDefaultExpandedSections = () => ({
  'Receiving': true,
  'Storage': true,
  'Fulfillment': true,
  'Value-Added Services': true,
  'Monthly Services': true,
  'Setup Fees': true
});

export const getInitialBudgetaryQuoteState = (baseTier: RateTier): BudgetaryQuoteState => {
  return {
    rateCardData: {},
    expandedSections: {
      setup: true,
      receiving: true,
      storage: true,
      fulfillment: true,
      services: true
    },
    scopeData: getDefaultScopeData(),
    quoteDetails: null,
    monthlyTotal: 0,
    setupTotal: 0,
    hasCustomRates: false,
    modifiedRates: new Set<string>(),
    baseTier,
    customRateCardData: {}
  };
};

export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat('en-US').format(num);
};
