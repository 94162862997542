
import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { SidebarProvider } from "@/components/ui/sidebar";
import { ClientSidebar } from "@/components/client/ClientSidebar";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { User } from "lucide-react";
import { useSignOut } from "@/hooks/useSignOut";
import { usePreviewMode } from "@/contexts/PreviewContext";
import { toast } from "sonner";

const ClientPortal = () => {
  const navigate = useNavigate();
  const { signOut } = useSignOut();
  const { isPreviewMode } = usePreviewMode();

  useEffect(() => {
    // In preview mode, bypass authentication
    if (isPreviewMode) {
      console.log("[ClientPortal] Preview mode detected, bypassing authentication");
      toast.info("Preview mode: Authentication bypassed", {
        description: "Authentication is bypassed in preview mode for development purposes."
      });
      return; // Skip the authentication check
    }

    const checkAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          navigate("/auth");
          return;
        }
      } catch (error) {
        console.error("Error checking session:", error);
        navigate("/auth");
      }
    };

    checkAuth();

    // Set up auth state listener (only if not in preview mode)
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT' || !session) {
        navigate("/auth");
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate, isPreviewMode]);

  return (
    <SidebarProvider>
      <div className="min-h-screen flex">
        <ClientSidebar />
        <main className="flex-1 flex flex-col">
          <div className="h-16 border-b flex items-center justify-end px-6">
            <Button
              variant="ghost"
              size="sm"
              onClick={signOut}
              className="text-gray-600 hover:text-gray-900"
            >
              <User className="h-4 w-4 mr-2" />
              <span>Sign Out</span>
            </Button>
          </div>
          <div className="flex-1 max-w-[1400px] mx-auto w-full">
            <Outlet />
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default ClientPortal;
