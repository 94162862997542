
import { TableRow, TableHead } from "@/components/ui/table";
import { TableSortButton } from "./TableSortButton";
import { SortDirection, SortField } from "../types";

interface ProspectTableHeaderProps {
  sortField: SortField;
  sortDirection: SortDirection;
  onSort: (field: SortField) => void;
}

export const ProspectTableHeader = ({ 
  sortField, 
  sortDirection, 
  onSort 
}: ProspectTableHeaderProps) => {
  return (
    <TableRow>
      <TableHead className="w-[16%]">
        <TableSortButton 
          field="company" 
          label="Company"
          onClick={onSort}
          currentSortField={sortField}
          currentSortDirection={sortDirection}
        />
      </TableHead>
      <TableHead className="w-[12%]">
        <TableSortButton 
          field="created_at" 
          label="Date Submitted"
          onClick={onSort}
          currentSortField={sortField}
          currentSortDirection={sortDirection}
        />
      </TableHead>
      <TableHead className="w-[12%]">
        <TableSortButton 
          field="last_modified_at" 
          label="Last Updated"
          onClick={onSort}
          currentSortField={sortField}
          currentSortDirection={sortDirection}
        />
      </TableHead>
      <TableHead className="w-[12%] text-right">
        <TableSortButton 
          field="annual_value" 
          label="Annual Value"
          onClick={onSort}
          currentSortField={sortField}
          currentSortDirection={sortDirection}
        />
      </TableHead>
      <TableHead className="w-[13%]">Scope</TableHead>
      <TableHead className="w-[25%] text-right">Actions</TableHead>
      <TableHead className="w-[10%] text-center">
        <TableSortButton 
          field="quote_status" 
          label="Quote Status"
          onClick={onSort}
          currentSortField={sortField}
          currentSortDirection={sortDirection}
        />
      </TableHead>
    </TableRow>
  );
};
