
import { RateTierSelector } from "./RateTierSelector";
import { DisplayRateTier, RateTier } from "@/utils/rates";

interface AdminControlsProps {
  selectedTier: DisplayRateTier;
  onTierChange: (tier: string) => void;
  hasCustomRates?: boolean;
  recommendedTier?: RateTier;
  isAdminView: boolean;
  baseTier?: RateTier;
  setBaseTier?: (tier: RateTier) => void;
}

export const AdminControls = ({
  selectedTier,
  onTierChange,
  hasCustomRates = false,
  recommendedTier,
  isAdminView,
  baseTier,
  setBaseTier
}: AdminControlsProps) => {
  // Only show admin controls for admin users or if showExtendedPricing is true
  if (!isAdminView) return null;

  return (
    <div className="mb-6">
      <RateTierSelector
        selectedTier={selectedTier}
        onTierChange={onTierChange}
        hasCustomRates={hasCustomRates}
        recommendedTier={recommendedTier}
        baseTier={baseTier}
        setBaseTier={setBaseTier}
      />
    </div>
  );
};
