
import { Tooltip, TooltipProvider } from "@/components/ui/tooltip";
import { Prospect } from "../../types";
import { getValueAddedServices, getFulfillmentMix, getStorageNeeds } from "./utils/tooltipUtils";
import { TooltipTriggerContent } from "./components/TooltipTrigger";
import { TooltipContentComponent } from "./components/TooltipContent";
import { useScopeData } from "./hooks/useScopeData";

interface ScopeTooltipProps {
  prospect: Prospect;
}

export const ScopeTooltip = ({ prospect }: ScopeTooltipProps) => {
  // Use our custom hook to get the scope data
  const {
    recommendedTier,
    displayTier,
    hasCustomRates,
    shouldShowRecommendedBadge,
    showCustomLabel
  } = useScopeData(prospect);
  
  // Get fulfillment mix, storage needs, and value-added services
  const { channelMix } = getFulfillmentMix(prospect);
  const { palletPositions, hasCartonStorage, hasBinStorage } = getStorageNeeds(prospect);
  const valueAddedServices = getValueAddedServices(prospect);
  
  // Get the last modified date
  const lastModifiedDate = prospect.pushed_to_client_at || prospect.admin_reviewed_at || prospect.created_at;
  
  // Get product description
  const productDescription = prospect.product_description || "";
  
  return (
    <div className="flex items-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTriggerContent 
            displayTier={displayTier}
            showCustomLabel={showCustomLabel}
            shouldShowRecommendedBadge={shouldShowRecommendedBadge}
            recommendedTier={recommendedTier}
          />
          
          <TooltipContentComponent 
            displayTier={displayTier}
            hasCustomRates={hasCustomRates}
            recommendedTier={recommendedTier}
            shouldShowRecommendedBadge={shouldShowRecommendedBadge}
            channelMix={channelMix}
            palletPositions={palletPositions}
            hasCartonStorage={hasCartonStorage}
            hasBinStorage={hasBinStorage}
            valueAddedServices={valueAddedServices}
            productDescription={productDescription}
            lastModifiedDate={lastModifiedDate}
          />
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
