import { MainNav } from "@/components/MainNav";
import { motion } from "framer-motion";
import { Hero } from "@/components/sections/Hero";
import { ValueProps } from "@/components/sections/ValueProps";
import { CTASection } from "@/components/sections/CTASection";
import { PlatformIntegration } from "@/components/PlatformIntegration";
import { Testimonials } from "@/components/sections/Testimonials";
import { HoustonHub } from "@/components/sections/HoustonHub";
import { Link } from "react-router-dom";
import { memo, useState, useEffect } from "react";
import { ErrorBoundary } from "@/components/ui/error-boundary";
import { usePreviewMode } from "@/contexts/PreviewContext";
import { Footer } from "@/components/Footer";
import { Image } from "@/components/ui/image";
import { Helmet } from "react-helmet";

// Memoize the logo section with error handling to prevent unnecessary re-renders
const Logo = memo(() => {
  const [imgError, setImgError] = useState(false);
  const logoPath = "/lovable-uploads/177edcf5-8180-47b2-a62d-f1e5b983086a.png";

  return (
    <Link to="/" className="flex items-center gap-4">
      {!imgError ? (
        <Image 
          src={logoPath}
          alt="Thrive 3PL" 
          className="h-12"
          loading="eager"
          width="48"
          height="48"
          onError={() => setImgError(true)}
        />
      ) : (
        <div className="h-12 w-12 bg-primary/10 flex items-center justify-center rounded-md">
          <span className="font-bold text-primary text-lg">T3PL</span>
        </div>
      )}
      <span className="font-jakarta font-bold text-3xl text-white">
        Thrive 3PL
      </span>
    </Link>
  );
});

const Index = () => {
  useEffect(() => {
    // Log on component mount to help diagnose issues
    console.log("Index component mounted");
    
    // Check if we can access the window and document
    if (typeof window !== 'undefined') {
      console.log("Window object is available");
    }
    
    return () => {
      console.log("Index component unmounted");
    };
  }, []);

  // JSON-LD structured data for organization
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Thrive 3PL",
    "url": "https://thrive3pl.com",
    "logo": "https://thrive3pl.com/lovable-uploads/177edcf5-8180-47b2-a62d-f1e5b983086a.png",
    "description": "Thrive 3PL provides warehousing, order fulfillment, and logistics services in Houston, TX for omni-channel brands.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Houston",
      "addressRegion": "TX",
      "addressCountry": "US"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-832-295-9090",
      "contactType": "customer service"
    },
    "sameAs": [
      "https://www.linkedin.com/company/thrive3pl",
      "https://twitter.com/thrive3pl"
    ]
  };

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Thrive 3PL | Logistics Built to Make Your Business Thrive</title>
        <meta name="description" content="Thrive 3PL provides warehousing, order fulfillment, and logistics services in Houston, TX for omni-channel brands. Scale your business with our 3PL solutions." />
        <meta name="keywords" content="3PL logistics, third-party logistics providers, 3pl logistic companies, Logistics Company Houston, logistics Houston TX, Houston logistics, houston fulfillment center, order fulfillment services, E-Commerce Fulfillment" />
        <meta property="og:title" content="Thrive 3PL | Logistics Built to Make Your Business Thrive" />
        <meta property="og:description" content="Thrive 3PL provides warehousing, order fulfillment, and logistics services in Houston, TX for omni-channel brands. Scale your business with our 3PL solutions." />
        <meta property="og:image" content="/lovable-uploads/177edcf5-8180-47b2-a62d-f1e5b983086a.png" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://thrive3pl.com/" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      
      <div className="fixed top-0 left-0 right-0 z-50">
        <div className="h-16 bg-[#002408] relative">
          <div className="absolute top-2 left-6 z-10">
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="flex items-center gap-4 my-0 rounded-none"
            >
              <ErrorBoundary>
                <Logo />
              </ErrorBoundary>
            </motion.div>
          </div>

          <div className="absolute top-2 right-4 z-10">
            <MainNav />
          </div>
        </div>
      </div>

      <ErrorBoundary>
        <div className="pt-16">
          <Hero />
          <ValueProps />
          <PlatformIntegration />
          <Testimonials />
          <HoustonHub />
          <CTASection />
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
    </div>
  );
};

export default Index;
