
import { DatabaseQuote } from "@/pages/Quote/types";

/**
 * Processes and returns storage types from a quote
 */
export const getStorageTypes = (quote: DatabaseQuote) => {
  console.log("Storage types:", quote.storage_types);
  
  const storageTypes = quote.storage_types as Record<string, any> || {};
  
  const storageTypeOrder = ['pallets', 'cartons', 'items', 'bulk'];
  
  const orderedItems = [];
  
  for (const key of storageTypeOrder) {
    const value = storageTypes[key];
    if (value && typeof value === 'object' && value.enabled) {
      let details = '';

      // Add specific details based on storage type
      if (key === 'pallets' && value.isStackable) {
        details = 'Stackable pallets';
      }
      
      // Add carton dimensions if available
      if (key === 'cartons' && value.dimensions) {
        const { length, width, height } = value.dimensions;
        if (length && width && height) {
          details = `${length}″ × ${width}″ × ${height}″`;
        }
      }
      
      // For items, move storage type to details column
      if (key === 'items' && value.storageType) {
        details = value.storageType === 'bin' ? 'Bin Storage (Smaller)' : 'Shelf Storage (Larger)';
      }
      
      // For bulk storage, indicate that details are available if present
      if (key === 'bulk' && quote.bulk_storage_details) {
        details = 'See detailed description below';
      }
      
      // Set positions only for pallets and cartons
      let positions = '';
      if (key === 'pallets' || key === 'cartons') {
        positions = value.initial || '0';
      }
      // Note: We no longer set positions for 'items' as it's now in the details column
      
      orderedItems.push({
        type: key === 'items' ? 'Individual (Pickable) Items' : key.charAt(0).toUpperCase() + key.slice(1),
        positions: positions,
        details: details
      });
    }
  }
  
  console.log("Processed storage types:", orderedItems);
  return orderedItems;
};

/**
 * Processes and returns special storage requirements from a quote
 */
export const getSpecialStorageRequirements = (quote: DatabaseQuote) => {
  console.log("Special storage requirements:", quote.special_storage_requirements);
  
  const items = [];
  const specialReqs = quote.special_storage_requirements as Record<string, boolean> || {};

  Object.entries(specialReqs).forEach(([key, value]) => {
    if (value) {
      const label = key === 'temperatureControl' ? 'Temperature Controlled Storage' : 
                key === 'rackStorage' ? 'Rack Storage' : 
                key === 'floorStorage' ? 'Floor Storage' : 
                key === 'hazmat' ? 'Hazardous Materials Storage' : 
                key === 'oversized' ? 'Oversized Item Storage' : 
                key === 'heavy' ? 'Heavy Item Storage' : 
                key === 'fragile' ? 'Fragile Item Handling' : 
                key === 'specialPackaging' ? 'Special Packaging Required' : 
                key === 'foodGrade' ? 'Food Grade Storage' : 
                key.replace(/([A-Z])/g, ' $1').trim();
      items.push(label);
    }
  });
  
  console.log("Processed special storage requirements:", items);
  return items;
};
