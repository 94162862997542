
import { DatabaseQuote } from "@/pages/Quote/types";

/**
 * Returns an ordered list of sales channels from a quote
 */
export const getOrderedSalesChannels = (quote: DatabaseQuote) => {
  if (!quote.sales_channels || typeof quote.sales_channels !== 'object') {
    return [];
  }
  
  const salesChannelsObj = quote.sales_channels as Record<string, any>;
  const orderedChannels = [];
  
  // Define the preferred display order
  const ORDERED_CHANNELS = [
    { id: 'own-ecommerce', name: 'Own E-commerce Website' },
    { id: 'amazon-fba', name: 'Amazon FBA' },
    { id: 'amazon-fbm', name: 'Amazon FBM' },
    { id: 'retail-b2b', name: 'Retail B2B' },
    { id: 'marketplaces', name: 'Other Marketplaces' },
    { id: 'retail-dropship', name: 'Retail Dropship (Non-marketplace)' },
    { id: 'retail', name: 'Retail Stores' }, // Legacy mapping
    { id: 'popup', name: 'Pop-up Shops/Events' },
    { id: 'subscription', name: 'Subscription Box' },
    { id: 'tradeshows', name: 'Trade Shows/Events' },
    { id: 'b2b', name: 'B2B Distribution' },
    { id: 'international', name: 'International Markets' }
  ];
  
  // First, add channels in the preferred order
  for (const channel of ORDERED_CHANNELS) {
    const matchingChannelEntry = Object.entries(salesChannelsObj).find(
      ([_, value]) => value.id === channel.id
    );
    
    if (matchingChannelEntry) {
      orderedChannels.push(matchingChannelEntry);
    }
  }
  
  // Then add any remaining channels that weren't in the preferred order
  Object.entries(salesChannelsObj).forEach(([key, value]) => {
    const alreadyIncluded = orderedChannels.some(([k]) => k === key);
    if (!alreadyIncluded) {
      orderedChannels.push([key, value]);
    }
  });
  
  return orderedChannels;
};
