
import React from "react";
import { Badge } from "@/components/ui/badge";
import { Clock, CheckCircle2, Send, XCircle, MessageSquare, FileCheck } from "lucide-react";

type QuoteStatus = 
  | "pending_review" 
  | "budgetary_to_client" 
  | "firm_to_client" 
  | "signed" 
  | "contract_signed" 
  | "rejected" 
  | "contact_inquiry";

interface StatusBadgeProps {
  status: QuoteStatus | string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const getStatusConfig = () => {
    switch (status) {
      case "firm_to_client":
        return {
          label: "Firm Quote Sent",
          variant: "default" as const,
          icon: <FileCheck className="h-3.5 w-3.5 mr-1" />,
          className: "bg-green-700 text-green-50" // Dark green with light text
        };
      case "budgetary_to_client":
        return {
          label: "Budgetary Quote",
          variant: "secondary" as const,
          icon: <Send className="h-3.5 w-3.5 mr-1" />,
          className: "bg-blue-100 text-blue-700" // Light blue background with dark blue text
        };
      case "pending_review":
        return {
          label: "Pending Review",
          variant: "outline" as const,
          icon: <Clock className="h-3.5 w-3.5 mr-1" />
        };
      case "signed":
      case "contract_signed": // Handle both status values
        return {
          label: "Signed",
          variant: "default" as const,
          icon: <CheckCircle2 className="h-3.5 w-3.5 mr-1" />,
          className: "bg-amber-500 text-amber-50" // Gold/amber with light text
        };
      case "rejected":
        return {
          label: "Rejected",
          variant: "destructive" as const,
          icon: <XCircle className="h-3.5 w-3.5 mr-1" />
        };
      case "contact_inquiry":
        return {
          label: "Contact Inquiry",
          variant: "secondary" as const,
          icon: <MessageSquare className="h-3.5 w-3.5 mr-1" />,
          className: "bg-blue-100 text-blue-700 border-blue-300"
        };
      default:
        return {
          label: status || "Pending Review",
          variant: "outline" as const,
          icon: <Clock className="h-3.5 w-3.5 mr-1" />
        };
    }
  };

  const { label, variant, icon, className } = getStatusConfig();

  return (
    <Badge variant={variant} className={`flex items-center justify-center mx-auto ${className || ''}`}>
      {icon}
      {label}
    </Badge>
  );
};
