
import { useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ProspectsTable } from "./ProspectsTable";
import { Prospect, SortDirection, SortField } from "../types";

interface PipelineCardProps {
  prospects: Prospect[];
  isLoading: boolean;
  showArchived: boolean;
  onShowArchivedChange: (value: boolean) => void;
  onToggleArchive: (prospectId: string, currentArchived: boolean) => void;
  sortField: SortField;
  sortDirection: SortDirection;
  onSort: (field: SortField) => void;
  onUpdateMonthlyValue?: (prospectId: string, monthlyValue: number) => void;
  calculatedMonthlyValues?: Record<string, number>;
}

export const PipelineCard = ({
  prospects,
  isLoading,
  showArchived,
  onShowArchivedChange,
  onToggleArchive,
  sortField,
  sortDirection,
  onSort,
  onUpdateMonthlyValue,
  calculatedMonthlyValues
}: PipelineCardProps) => {
  const [activeTab, setActiveTab] = useState("all");
  
  const filteredProspects = showArchived 
    ? prospects 
    : prospects.filter(prospect => !prospect.archived);
  
  // Filter prospects based on the active tab
  const displayedProspects = activeTab === "all" 
    ? filteredProspects 
    : filteredProspects.filter(prospect => {
        // Filter logic based on the active tab
        switch (activeTab) {
          case "inquiries":
            return prospect.quote_status === 'contact_inquiry';
          case "pending_review":
            return prospect.quote_status === 'pending_review';
          case "awaiting_firm_quote":
            return prospect.quote_status === 'budgetary_to_client';
          case "firm_quote_sent":
            return prospect.quote_status === 'firm_to_client';
          case "signed":
            // Check for both 'signed' and 'contract_signed' status values
            return prospect.quote_status === 'signed' || prospect.quote_status === 'contract_signed';
          default:
            return true;
        }
      });

  return (
    <Card className="mt-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Sales Pipeline</CardTitle>
        <div className="flex items-center space-x-2">
          <Switch
            id="show-archived"
            checked={showArchived}
            onCheckedChange={onShowArchivedChange}
          />
          <Label htmlFor="show-archived">Show Archived</Label>
        </div>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="all" value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="mb-4">
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="inquiries">Inquiries</TabsTrigger>
            <TabsTrigger value="pending_review">Pending Review</TabsTrigger>
            <TabsTrigger value="awaiting_firm_quote">Budgetary Quote</TabsTrigger>
            <TabsTrigger value="firm_quote_sent">Firm Quote Sent</TabsTrigger>
            <TabsTrigger value="signed">Signed</TabsTrigger>
          </TabsList>
          <TabsContent value={activeTab}>
            {isLoading ? (
              <div className="flex justify-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            ) : (
              <ProspectsTable
                prospects={displayedProspects}
                onToggleArchive={onToggleArchive}
                sortField={sortField}
                sortDirection={sortDirection}
                onSort={onSort}
                onUpdateMonthlyValue={onUpdateMonthlyValue}
                calculatedMonthlyValues={calculatedMonthlyValues}
              />
            )}
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};
