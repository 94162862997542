
import { TableCell, TableRow } from "@/components/ui/table";
import { RateCell } from "../RateCell";
import { formatCurrency } from "@/lib/utils";
import { calculateParcelCost } from "./utils";

interface ParcelRowProps {
  scopeData: any;
  rateCardData: Record<string, number>;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified: (key: string) => boolean;
}

export const ParcelRow = ({
  scopeData,
  rateCardData,
  onRateChange,
  isCustomizing,
  isRateModified
}: ParcelRowProps) => {
  return (
    <TableRow>
      <TableCell className="font-medium">Small Parcel Receiving</TableCell>
      <TableCell>
        <RateCell 
          fieldKey="receivingParcel" 
          rate={rateCardData.receivingParcel || rateCardData.receivingStandardCarton || 0}
          onChange={onRateChange}
          isCustomizing={isCustomizing}
          isModified={isRateModified("receivingParcel")}
        />
      </TableCell>
      <TableCell>{scopeData.parcelVolume || 0}/mo</TableCell>
      <TableCell>
        {formatCurrency(calculateParcelCost(scopeData, rateCardData))}
      </TableCell>
    </TableRow>
  );
};
