
import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { DatabaseQuote } from "@/pages/Quote/types";
import { ChannelDistributionChart } from "@/components/quote/business/ChannelDistributionChart";
import { getOrderedSalesChannels } from "../utils";

interface BusinessTabProps {
  quote: DatabaseQuote;
}

const BusinessTab: React.FC<BusinessTabProps> = ({ quote }) => {
  // Map business identity values to their display labels
  const getBusinessIdentityLabel = (identity: string | undefined) => {
    switch(identity) {
      case 'dtc': return 'Direct-to-Consumer Brand';
      case 'marketplace': return 'Marketplace Seller';
      case 'omni-retail': return 'Omni-Channel Retail Brand';
      case 'wholesaler-manufacturer': return 'Wholesaler or Manufacturer';
      case 'retail-store': return 'Retail Store';
      case 'logistics': return 'Logistics Manager';
      // Handle legacy values
      case 'wholesale': return 'Wholesaler or Manufacturer';
      case 'retail': return 'Retail Store';
      default: return identity || 'Not specified';
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Business Profile</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-semibold">Business Identity</h4>
              <p>{getBusinessIdentityLabel(quote.business_identity)}</p>
            </div>
            <div>
              <h4 className="font-semibold">Company Size</h4>
              <p>{quote.company_size || 'Not specified'}</p>
            </div>
            <div>
              <h4 className="font-semibold">Annual Revenue</h4>
              <p>{quote.annual_revenue || 'Not specified'}</p>
            </div>
            <div>
              <h4 className="font-semibold">Total SKUs</h4>
              <p>{quote.total_skus || 'Not specified'}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            {quote.sales_channels && Object.keys(quote.sales_channels).length > 0 && (
              <div>
                <h4 className="font-semibold mb-2">Sales Channels</h4>
                <div className="border rounded-md overflow-hidden">
                  {getOrderedSalesChannels(quote).map(([key, value], index) => (
                    <div key={key} className={`flex justify-between items-center p-2 ${index !== 0 ? 'border-t' : ''}`}>
                      <span>{value.name}</span>
                      <span className="text-sm font-medium">{value.percentage}%</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {quote.sales_channels && Object.keys(quote.sales_channels).length > 0 && (
              <div>
                <h4 className="font-semibold mb-2">Channel Distribution</h4>
                <ChannelDistributionChart channels={quote.sales_channels as Record<string, any>} />
              </div>
            )}

            {Array.isArray(quote.fulfillment_priorities) && quote.fulfillment_priorities.length > 0 && (
              <div className="col-span-2">
                <h4 className="font-semibold mb-2">Fulfillment Priorities</h4>
                <div className="border rounded-md overflow-hidden">
                  {quote.fulfillment_priorities.map((priority: string, index: number) => (
                    <div key={index} className={`flex items-center p-2 ${index !== 0 ? 'border-t' : ''}`}>
                      <span>{priority}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {quote.product_description && (
            <div>
              <h4 className="font-semibold mb-2">Product Catalog Description</h4>
              <p className="text-sm whitespace-pre-wrap border rounded-md p-3">{quote.product_description}</p>
            </div>
          )}
          
          {quote.business_challenges && (
            <div>
              <h4 className="font-semibold mb-2">Business Challenges</h4>
              <p className="text-sm whitespace-pre-wrap border rounded-md p-3">{quote.business_challenges}</p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export { BusinessTab };
export default BusinessTab;
