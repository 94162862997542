
import { Button } from "@/components/ui/button";
import { Save, Edit, SendHorizontal } from "lucide-react";

interface QuoteActionButtonsProps {
  isCustomizing: boolean;
  handleSaveCustomRates: () => void;
  toggleCustomization: () => void;
  handleSaveAndPushRates: () => void;
  updatePending: boolean;
}

export const QuoteActionButtons = ({
  isCustomizing,
  handleSaveCustomRates,
  toggleCustomization,
  handleSaveAndPushRates,
  updatePending
}: QuoteActionButtonsProps) => {
  return (
    <div className="flex items-center space-x-2">
      {isCustomizing ? (
        <Button 
          onClick={handleSaveCustomRates}
          className="bg-green-600 hover:bg-green-700"
          disabled={updatePending}
        >
          <Save className="w-4 h-4 mr-2" />
          Save Custom Rates
        </Button>
      ) : (
        <>
          <Button 
            onClick={toggleCustomization}
            variant="outline"
            className="border-green-400 text-green-600 hover:bg-green-50"
          >
            <Edit className="w-4 h-4 mr-2" />
            Customize Rates
          </Button>
          
          <Button 
            onClick={handleSaveAndPushRates}
            className="bg-blue-600 hover:bg-blue-700"
            disabled={updatePending}
          >
            <SendHorizontal className="w-4 h-4 mr-2" />
            Push to Client
          </Button>
        </>
      )}
    </div>
  );
};
