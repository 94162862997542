
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { getImageUrl } from "@/utils/imageUtils";
import { DiscoveryImage, DiscoveryImageData } from "../types";

export const useImageGallery = (quoteId: string) => {
  const [images, setImages] = useState<DiscoveryImage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<DiscoveryImage | null>(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const { data, error } = await supabase
          .from('discovery_images')
          .select('*')
          .eq('quote_id', quoteId)
          .order('created_at', { ascending: false });
        
        if (error) {
          throw error;
        }
        
        // Transform the data to match our DiscoveryImage type
        const transformedData = (data || []).map((item: DiscoveryImageData) => ({
          id: item.id,
          quote_id: item.quote_id,
          image_path: getImageUrl(item.storage_path),
          created_at: item.created_at,
          description: item.description
        }));
        
        setImages(transformedData);
      } catch (err) {
        console.error('Error fetching discovery images:', err);
        setError('Failed to load images. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    
    if (quoteId) {
      fetchImages();
    }
  }, [quoteId]);

  const handleImageClick = (image: DiscoveryImage) => {
    setSelectedImage(image);
    setImageDialogOpen(true);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return {
    images,
    isLoading,
    error,
    selectedImage,
    imageDialogOpen,
    setImageDialogOpen,
    handleImageClick,
    openInNewTab
  };
};
