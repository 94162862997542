
import { NavigationMenuContent, NavigationMenuLink } from "@/components/ui/navigation-menu";
import { Link } from "react-router-dom";
import { CreditCard } from "lucide-react";

export const PricingMenu = () => {
  return (
    <NavigationMenuContent>
      <ul className="grid w-[400px] gap-3 p-4">
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/pricing/philosophy"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <CreditCard className="h-4 w-4" />
              <span>Our Pricing Approach</span>
            </Link>
          </NavigationMenuLink>
        </li>
      </ul>
    </NavigationMenuContent>
  );
};
