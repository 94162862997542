import { useState, useEffect } from "react";
import { DisplayRateTier, RateTier } from "@/utils/rates";
import { DatabaseQuote } from "@/pages/Quote/types";
import { toast } from "sonner";
import { useProspects } from "../../../hooks/useProspects";
import { getRecommendedTier } from "@/utils/rates/recommendedTierUtils";
import { hasCustomRates } from "@/utils/rates/recommendedTierUtils";
import { calculateQuoteDetails, extractScopeData } from "../../../components/QuoteCalculator/utils";
import { isObject } from "@/lib/utils";

interface UseQuoteRateTierProps {
  quote?: DatabaseQuote;
  approvedRateTier?: DisplayRateTier;
}

interface UseQuoteRateTierReturn {
  isFirmQuote: boolean;
  quoteMonthlyRevenue: number;
  recommendedTier: RateTier;
  currentTier: DisplayRateTier;
  lastPushedAt: string | null;
  handleQuoteTypeChange: (checked: boolean) => void;
  handleTierChange: (newTier: string) => void;
  handleMonthlyRevenueChange: (newMonthlyTotal: number) => void;
}

export const useQuoteRateTier = ({ 
  quote, 
  approvedRateTier 
}: UseQuoteRateTierProps): UseQuoteRateTierReturn => {
  const { updateQuoteMutation } = useProspects();

  // Log the initial approved rate tier from the quote
  useEffect(() => {
    console.log("useQuoteRateTier: Initial quote.approved_rate_tier =", quote?.approved_rate_tier);
  }, [quote]);

  // States simplified to reflect DB values directly
  const [isFirmQuote, setIsFirmQuote] = useState(quote?.quote_type === 'firm');
  const [lastPushedAt, setLastPushedAt] = useState<string | null>(
    quote?.pushed_to_client_at ? new Date(quote.pushed_to_client_at).toLocaleString() : null
  );
  
  // Calculate monthly revenue using the single source of truth: calculateQuoteDetails
  const [quoteMonthlyRevenue, setQuoteMonthlyRevenue] = useState<number>(0);
  
  // Calculate recommended tier based on quoteMonthlyRevenue
  const [recommendedTier, setRecommendedTier] = useState<RateTier>('D2C');
  
  // For currentTier, we always use what's in the database (approved_rate_tier)
  // If it doesn't exist, we default to recommended tier
  const [currentTier, setCurrentTier] = useState<DisplayRateTier>(
    quote?.approved_rate_tier || 'D2C' as DisplayRateTier
  );

  // Update UI when quote data changes
  useEffect(() => {
    if (quote) {
      setIsFirmQuote(quote.quote_type === 'firm');
      
      if (quote.pushed_to_client_at) {
        setLastPushedAt(new Date(quote.pushed_to_client_at).toLocaleString());
      }
      
      // Extract scope data for calculations
      const scopeData = extractScopeData(quote);
      
      // Properly type custom rates
      const customRates = quote.custom_rates && isObject(quote.custom_rates) 
        ? quote.custom_rates as Record<string, number>
        : undefined;
        
      // Use the approved tier if available, otherwise use D2C as fallback
      const tierToUse = quote.approved_rate_tier && quote.approved_rate_tier !== 'CUSTOM' 
        ? quote.approved_rate_tier as RateTier 
        : 'D2C';
        
      // Calculate quote details directly
      const quoteDetails = calculateQuoteDetails(
        tierToUse,
        scopeData,
        customRates
      );
      
      if (quoteDetails?.monthly?.total) {
        // Set the monthly revenue from calculations
        const newMonthlyRevenue = quoteDetails.monthly.total;
        setQuoteMonthlyRevenue(newMonthlyRevenue);
        
        // Calculate recommended tier based on the monthly revenue
        const newRecommendedTier = getRecommendedTier(newMonthlyRevenue, quote.id);
        setRecommendedTier(newRecommendedTier);
        console.log("useQuoteRateTier: Recommended tier =", newRecommendedTier, "for revenue =", newMonthlyRevenue);
      }
      
      // Always respect the database value for tier if it exists
      if (quote.approved_rate_tier) {
        console.log("useQuoteRateTier: Using approved_rate_tier from DB:", quote.approved_rate_tier);
        setCurrentTier(quote.approved_rate_tier);
      } else if (hasCustomRates(quote)) {
        console.log("useQuoteRateTier: Quote has custom rates, setting tier to CUSTOM");
        setCurrentTier('CUSTOM');
      } else {
        // Set to recommended tier if no approved tier exists
        console.log("useQuoteRateTier: No approved tier, using recommended tier:", recommendedTier);
        setCurrentTier(recommendedTier as DisplayRateTier);
        
        // Auto-update the database with recommended tier if no approved tier is set
        if (quote.id && !quote.approved_rate_tier) {
          console.log("useQuoteRateTier: Auto-updating database with recommended tier:", recommendedTier);
          updateQuoteMutation.mutate({
            prospectId: quote.id,
            data: { approved_rate_tier: recommendedTier as DisplayRateTier }
          }, {
            onSuccess: () => {
              console.log(`Auto-updated rate tier to recommended tier: ${recommendedTier}`);
            },
            onError: (error) => {
              console.error("Failed to auto-update rate tier:", error);
            }
          });
        }
      }
    }
  }, [quote, recommendedTier]);

  // Handle quote type changes (budgetary/firm)
  const handleQuoteTypeChange = (checked: boolean) => {
    // Update local state immediately for UI
    setIsFirmQuote(checked);
    
    // Then persist to database if we have a quote ID
    if (quote?.id) {
      updateQuoteMutation.mutate({
        prospectId: quote.id,
        data: { quote_type: checked ? 'firm' : 'budgetary' }
      }, {
        onSuccess: () => {
          toast.success(`Quote type updated to ${checked ? 'Firm' : 'Budgetary'}`);
        },
        onError: (error) => {
          toast.error(`Failed to update quote type: ${error.message}`);
          // Revert UI on error
          setIsFirmQuote(!checked);
        }
      });
    }
  };

  // Handle tier changes
  const handleTierChange = (newTier: string) => {
    // Update local state immediately for UI
    console.log("useQuoteRateTier: Changing tier to:", newTier);
    setCurrentTier(newTier as DisplayRateTier);
    
    // Then persist to database if we have a quote ID
    if (quote?.id) {
      updateQuoteMutation.mutate({
        prospectId: quote.id,
        data: { approved_rate_tier: newTier as DisplayRateTier }
      }, {
        onSuccess: () => {
          toast.success(`Rate tier updated to ${newTier}`);
        },
        onError: (error) => {
          toast.error(`Failed to update rate tier: ${error.message}`);
          // Revert to previous tier on error
          setCurrentTier(quote.approved_rate_tier || recommendedTier as DisplayRateTier);
        }
      });
    }
  };

  // Handle estimated monthly revenue changes - only track locally now
  const handleMonthlyRevenueChange = (newMonthlyTotal: number) => {
    // Update local state
    setQuoteMonthlyRevenue(newMonthlyTotal);
    
    // Calculate new recommended tier using our single source of truth function
    const newRecommendedTier = getRecommendedTier(
      newMonthlyTotal,
      quote?.id,
      currentTier !== 'CUSTOM' ? currentTier as RateTier : undefined
    );
    
    // Update recommended tier if changed
    if (newRecommendedTier !== recommendedTier) {
      console.log("useQuoteRateTier: New recommended tier =", newRecommendedTier, "for revenue =", newMonthlyTotal);
      setRecommendedTier(newRecommendedTier);
    }
    
    // If not using custom rates and no approved tier is set,
    // auto-update the tier based on new revenue
    if (quote?.id && !quote.approved_rate_tier && currentTier !== 'CUSTOM') {
      console.log("useQuoteRateTier: Auto-updating tier based on new revenue");
      handleTierChange(newRecommendedTier);
    }
  };

  return {
    isFirmQuote,
    quoteMonthlyRevenue,
    recommendedTier,
    currentTier,
    lastPushedAt,
    handleQuoteTypeChange,
    handleTierChange,
    handleMonthlyRevenueChange
  };
};
