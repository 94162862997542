
import { useState, useEffect } from "react";
import { 
  loadCustomRates, 
  hasStoredCustomRates, 
  determineModifiedRates 
} from "./utils/rateUtils";

interface UseCustomRatesProps {
  quoteData?: any;
  quoteId?: string;
  approvedRateTier: string;
  onCustomRatesDetected?: () => void;
}

/**
 * Hook to manage custom rates detection and loading
 */
export const useCustomRates = ({
  quoteData,
  quoteId,
  approvedRateTier,
  onCustomRatesDetected
}: UseCustomRatesProps) => {
  // Set up storage key for custom rates
  const customRatesStorageKey = quoteId ? `quote_${quoteId}_custom_rates` : null;
  
  // Direct check for custom rates in the database
  const hasCustomRatesInDB = quoteData?.custom_rates && 
    typeof quoteData.custom_rates === 'object' && 
    Object.keys(quoteData.custom_rates).length > 0;
  
  // Initialize state
  const [hasCustomRates, setHasCustomRates] = useState<boolean>(
    hasCustomRatesInDB || approvedRateTier === 'CUSTOM'
  );
  
  const [modifiedRates, setModifiedRates] = useState<Set<string>>(
    determineModifiedRates(quoteData, customRatesStorageKey)
  );
  
  // Load custom rates from DB or localStorage
  const [customRateCardData, setCustomRateCardData] = useState<Record<string, number>>(
    loadCustomRates(quoteData, customRatesStorageKey)
  );
  
  // Effect to update hasCustomRates and customRateCardData when quoteData changes
  useEffect(() => {
    // Check for custom rates in DB directly
    const hasRatesInDB = quoteData?.custom_rates && 
      typeof quoteData.custom_rates === 'object' && 
      Object.keys(quoteData.custom_rates).length > 0;
    
    // Also check localStorage and approved tier
    const hasStoredRates = hasStoredCustomRates(quoteData, customRatesStorageKey);
    const isCustomTier = approvedRateTier === 'CUSTOM';
    
    // Set hasCustomRates based on any of the checks
    const shouldUseCustomRates = hasRatesInDB || hasStoredRates || isCustomTier;
    
    setHasCustomRates(shouldUseCustomRates);
    
    if (shouldUseCustomRates && onCustomRatesDetected) {
      onCustomRatesDetected();
    }
    
    setCustomRateCardData(loadCustomRates(quoteData, customRatesStorageKey));
    setModifiedRates(determineModifiedRates(quoteData, customRatesStorageKey));
  }, [quoteData, customRatesStorageKey, onCustomRatesDetected, quoteId, approvedRateTier]);
  
  // Check if a specific rate has been modified
  const isRateModified = (key: string): boolean => {
    return modifiedRates.has(key);
  };
  
  return {
    hasCustomRates,
    customRateCardData,
    modifiedRates,
    customRatesStorageKey,
    setCustomRateCardData,
    setModifiedRates,
    setHasCustomRates,
    isRateModified
  };
};
