import { motion } from "framer-motion";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Button } from "./ui/button";
import { useToast } from "@/hooks/use-toast";
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import ThankYouMessage from "./ThankYouMessage";

export const ContactForm = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Store a reference to the form element
    const form = e.currentTarget;
    setIsSubmitting(true);
    
    const formData = new FormData(form);
    const data = {
      name: formData.get('name') as string,
      email: formData.get('email') as string,
      phone: formData.get('phone') as string,
      message: formData.get('message') as string
    };

    try {
      // Split the name into first and last name
      const nameParts = data.name.trim().split(' ');
      const firstName = nameParts[0] || '';
      const lastName = nameParts.slice(1).join(' ') || '';
      
      // Insert the inquiry into detailed_quote_requests
      const { data: insertedData, error } = await supabase
        .from('detailed_quote_requests')
        .insert({
          first_name: firstName,
          last_name: lastName,
          email: data.email,
          phone: data.phone,
          company: 'Contact Inquiry', // Keep company field but make it generic
          quote_status: 'contact_inquiry',
          initial_inquiry: data.message,
        })
        .select('id')
        .single();

      if (error) {
        console.error('Error submitting inquiry:', error);
        throw error;
      }

      console.log('Form submitted successfully:', insertedData);
      toast({
        title: "Thank you for your interest!",
        description: "We'll get back to you shortly."
      });
      
      // Show the thank you message
      setIsSubmitted(true);
      
      // Reset form with safety check
      if (form && typeof form.reset === 'function') {
        form.reset();
      }
    } catch (error) {
      console.error('Form submission error:', error);
      toast({
        title: "Error submitting your inquiry",
        description: "Please try again or contact us directly.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setIsSubmitted(false);
  };

  if (isSubmitted) {
    return <ThankYouMessage onReset={handleReset} />;
  }

  return (
    <motion.div 
      initial={{ opacity: 0, x: 20 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      className="bg-[#F2FCE2] rounded-xl p-8 shadow-lg"
    >
      <h3 className="text-2xl font-bold text-gray-900 mb-6">Tell Us How We Can Help</h3>
      <form onSubmit={handleSubmit} className="space-y-2">
        <div>
          <Input name="name" placeholder="Your Name" required className="bg-white" />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Input name="email" type="email" placeholder="Email Address" required className="bg-white" />
          <Input name="phone" type="tel" placeholder="Phone Number" required className="bg-white" />
        </div>
        <div>
          <Textarea 
            name="message" 
            placeholder="Tell Us About Your Warehousing and Fulfillment Needs (more information is better)" 
            required 
            className="bg-white min-h-[120px]" 
          />
        </div>
        <div className="space-y-2">
          <Button type="submit" className="w-full" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
          <p className="text-sm text-center text-gray-600">
            We Will Respond Promptly (No Spam!)
          </p>
        </div>
      </form>
    </motion.div>
  );
};
