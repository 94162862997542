
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useState } from "react";

/**
 * Hook to handle the API calls to the research function
 */
export const useResearchAPI = () => {
  const [isResearching, setIsResearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);

  /**
   * Calls the company-research function to get research data
   */
  const callResearchAPI = async (companyName: string, websiteUrl: string, quoteId: string) => {
    if (isResearching) return null;
    
    setIsResearching(true);
    setErrorMessage(null);
    const toastId = toast.loading("Analyzing company information using Perplexity web search...");

    try {
      if (!websiteUrl && !companyName) {
        toast.dismiss(toastId);
        toast.error("Company name or website URL is required for research");
        setIsResearching(false);
        return null;
      }

      console.log("Invoking company-research function with:", { 
        companyName, 
        websiteUrl, 
        quoteId
      });

      // Call the Edge Function to perform the research
      const { data, error } = await supabase.functions.invoke('company-research', {
        body: {
          companyName,
          websiteUrl,
          quoteId
        },
      });

      if (error) {
        console.error("Research function error:", error);
        toast.dismiss(toastId);
        
        const errorMsg = error.message || "Unknown error";
        let detailedMsg = "";
        
        if (errorMsg.includes("Failed to send")) {
          detailedMsg = "Failed to connect to research service. This may be due to network issues or the service might be temporarily unavailable.";
        } else if (errorMsg.includes("Missing required API configuration")) {
          detailedMsg = "Perplexity API or OpenAI API keys are not configured. Please contact the administrator.";
        } else if (errorMsg.includes("Perplexity API error")) {
          detailedMsg = "Error with Perplexity service. This may be due to quota limits or invalid configuration.";
        } else {
          detailedMsg = errorMsg;
        }
        
        toast.error(`Research failed: ${detailedMsg}`);
        setErrorMessage(`Research failed: ${detailedMsg}`);
        setIsResearching(false);
        return null;
      }

      if (!data?.research) {
        toast.dismiss(toastId);
        toast.error("No research data returned. Please try again.");
        setErrorMessage("Research failed: No data returned from the server");
        setIsResearching(false);
        return null;
      }

      toast.dismiss(toastId);
      toast.success("Company research completed successfully");
      setRetryCount(0);
      return data;
    } catch (error) {
      console.error("Error refreshing research:", error);
      toast.dismiss(toastId);
      
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      let detailedMsg = "";
      
      if (errorMessage.includes("network") || errorMessage.includes("CORS") || errorMessage.includes("Failed to fetch")) {
        detailedMsg = "Network error connecting to research service. Please check your connection and try again.";
      } else if (errorMessage.includes("Perplexity")) {
        detailedMsg = "Error with Perplexity service. This may be due to quota limits or API configuration issues.";
      } else if (errorMessage.includes("OpenAI")) {
        detailedMsg = "Error with OpenAI service. Please try again later.";
      } else {
        detailedMsg = errorMessage;
      }
      
      toast.error(`Research failed: ${detailedMsg}`);
      setErrorMessage(`Research failed: ${detailedMsg}`);
      setIsResearching(false);
      return null;
    } finally {
      setIsResearching(false);
    }
  };

  /**
   * Handles the research retry logic
   */
  const handleRetry = async (
    companyName: string, 
    websiteUrl: string, 
    quoteId: string, 
    onSuccess: (data: any) => void
  ) => {
    if (retryCount < 2) {
      toast.error(`Research attempt failed, retrying... (${retryCount + 1}/3)`);
      setRetryCount(prev => prev + 1);
      
      // Wait a moment before retrying
      setTimeout(async () => {
        const data = await callResearchAPI(companyName, websiteUrl, quoteId);
        if (data) {
          onSuccess(data);
        }
      }, 2000);
      return true;
    }
    
    // Reset retry count if we've hit the max retries
    setRetryCount(0);
    return false;
  };

  return {
    isResearching,
    errorMessage,
    retryCount,
    callResearchAPI,
    handleRetry,
    setErrorMessage
  };
};
