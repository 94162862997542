
import { formatCurrency } from "@/lib/utils";

interface MonthlyCostSummaryProps {
  monthlyTotal: number;
  setupTotal: number;
  showMonthlyTotal?: boolean;
}

export const MonthlyCostSummary = ({
  monthlyTotal,
  setupTotal,
  showMonthlyTotal = true
}: MonthlyCostSummaryProps) => {
  if (!showMonthlyTotal) {
    return null;
  }
  
  return (
    <div className="my-3 animate-fade-in">
      <div className="grid grid-cols-2 gap-3">
        <div className="bg-blue-50 p-3 rounded-md border border-blue-100 shadow-sm">
          <h3 className="font-semibold text-blue-800">Monthly Cost:</h3>
          <p className="text-lg font-bold">{formatCurrency(monthlyTotal)}</p>
        </div>
        <div className="bg-amber-50 p-3 rounded-md border border-amber-100 shadow-sm">
          <h3 className="font-semibold text-amber-800">Setup Fee:</h3>
          <p className="text-lg font-bold">{formatCurrency(setupTotal)}</p>
        </div>
      </div>
    </div>
  );
};
