
import { FileText, Book, FileText as FileContract, ClipboardList } from "lucide-react";
import { ProgressStep, SubStep } from "@/types/progress";

export const progressSteps: ProgressStep[] = [
  {
    type: 'quote',
    title: 'Get a Quote',
    description: 'Request pricing for your fulfillment needs',
    iconName: 'FileText',
    path: '/client-portal/quotes'
  },
  {
    type: 'discovery',
    title: 'Discovery',
    description: 'Share more about your business',
    iconName: 'Book',
    path: '/client-portal/discovery'
  },
  {
    type: 'contract',
    title: 'Contract',
    description: 'Review and sign your agreement',
    iconName: 'FileContract',
    path: '/client-portal/contract'
  },
  {
    type: 'onboarding',
    title: 'Onboarding',
    description: 'Get set up with our fulfillment services',
    iconName: 'ClipboardList',
    path: '/client-portal/onboarding'
  }
];

export const getStepIcon = (iconName: string) => {
  switch (iconName) {
    case 'FileText':
      return FileText;
    case 'Book':
      return Book;
    case 'FileContract':
      return FileContract;
    case 'ClipboardList':
      return ClipboardList;
    default:
      return FileText;
  }
};
