
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ChevronDown, ChevronUp, Settings } from "lucide-react";
import { formatCurrency } from "@/lib/utils";
import { RateCell } from "./RateCell";

export interface MonthlyServicesSectionProps {
  rateCardData: Record<string, number>;
  expanded: boolean;
  quoteData?: any;
  onToggle: () => void;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified?: (key: string) => boolean;
}

export const MonthlyServicesSection = ({
  rateCardData,
  quoteData,
  expanded,
  onToggle,
  onRateChange,
  isCustomizing,
  isRateModified = () => false
}: MonthlyServicesSectionProps) => {
  return (
    <div className="mb-6 border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200">
      <div 
        className="flex justify-between items-center p-3 bg-gray-50 cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={onToggle}
      >
        <h3 className="text-lg font-semibold flex items-center">
          <Settings className="h-5 w-5 mr-2 text-blue-600" />
          Monthly Services
        </h3>
        {expanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </div>
      
      {expanded && (
        <div className="overflow-x-auto p-3 animate-fade-in">
          <Table>
            <TableHeader className="bg-gray-50">
              <TableRow>
                <TableHead className="w-[50%] font-semibold">Service</TableHead>
                <TableHead className="font-semibold">Rate</TableHead>
                <TableHead className="font-semibold">Quantity</TableHead>
                <TableHead className="font-semibold">Extended</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className="hover:bg-gray-50 transition-colors">
                <TableCell className="font-medium">Standard WMS Portal</TableCell>
                <RateCell 
                  fieldKey="standardWOMSPortal" 
                  rate={rateCardData.standardWOMSPortal || 0}
                  onChange={onRateChange}
                  isCustomizing={isCustomizing}
                  isModified={isRateModified("standardWOMSPortal")}
                />
                <TableCell>1</TableCell>
                <TableCell>{formatCurrency(rateCardData.standardWOMSPortal || 0)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
