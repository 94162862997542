
import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { DatabaseQuote } from "@/pages/Quote/types";
import { formatDate } from "../utils";

interface ContactTabProps {
  quote: DatabaseQuote;
}

const ContactTab: React.FC<ContactTabProps> = ({ quote }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Contact Information</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-2 gap-4">
        <div>
          <h4 className="font-semibold">Name</h4>
          <p>{quote.first_name} {quote.last_name}</p>
        </div>
        <div>
          <h4 className="font-semibold">Company</h4>
          <p>{quote.company}</p>
        </div>
        <div>
          <h4 className="font-semibold">Email</h4>
          <p>{quote.email}</p>
        </div>
        <div>
          <h4 className="font-semibold">Phone</h4>
          <p>{quote.phone || 'Not provided'}</p>
        </div>
        {quote.website && <div>
          <h4 className="font-semibold">Website</h4>
          <p>{quote.website}</p>
        </div>}
        <div>
          <h4 className="font-semibold">Submitted</h4>
          <p>{formatDate(quote.created_at)}</p>
        </div>
      </CardContent>
    </Card>
  );
};

export { ContactTab };
export default ContactTab;
