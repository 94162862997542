
import { Prospect } from "../../../types";
import { safeJsonToRecord } from "@/utils/quoteDataProcessor";
import { RateTier } from "@/utils/rates";

/**
 * Extract value-added services from prospect data
 */
export const getValueAddedServices = (prospect: Prospect): string[] => {
  const valueAddedServices: string[] = [];
  
  try {
    const services = safeJsonToRecord(prospect.value_added_services);
    const fulfillmentReqs = safeJsonToRecord(prospect.fulfillment_requirements);
    
    // Regular value-added services
    if (services.kitting) valueAddedServices.push("Kitting");
    if (services.customLabeling) valueAddedServices.push("Labeling");
    if (services.returnProcessing) valueAddedServices.push("Returns");
    if (services.qualityControl) valueAddedServices.push("QC");
    
    // Move FBA prep to value-added services section
    if (fulfillmentReqs.fbaPrep) valueAddedServices.push("FBA Prep");
  } catch (e) {
    console.error("Error parsing value added services:", e);
  }
  
  return valueAddedServices;
};

/**
 * Extract fulfillment mix data from prospect
 */
export const getFulfillmentMix = (prospect: Prospect) => {
  const salesChannels = safeJsonToRecord(prospect.sales_channels);
  
  let channelMix: {name: string, percentage: number}[] = [];
  
  // Handle modern channel structure
  Object.entries(salesChannels).forEach(([channelId, channelData]) => {
    if (typeof channelData === 'object' && channelData !== null) {
      const channel = channelData as any;
      
      // Only include channels with a percentage
      if (channel.percentage && channel.percentage > 0) {
        // Map channel IDs to readable names
        let channelName = channel.name || '';
        
        // Use specific mappings for common channels
        if (channelId === 'amazon' || channelName.toLowerCase().includes('amazon')) {
          channelName = 'Amazon';
        } else if (channelId === 'website' || channelName.toLowerCase().includes('website')) {
          channelName = 'Website';
        } else if (channelId === 'walmart' || channelName.toLowerCase().includes('walmart')) {
          channelName = 'Walmart';
        } else if (channelId === 'ebay' || channelName.toLowerCase().includes('ebay')) {
          channelName = 'eBay';
        }
        
        channelMix.push({
          name: channelName,
          percentage: parseInt(String(channel.percentage)) || 0
        });
      }
    }
  });
  
  return { channelMix };
};

/**
 * Extract storage needs data from prospect
 */
export const getStorageNeeds = (prospect: Prospect) => {
  const storageTypes = safeJsonToRecord(prospect.storage_types);
  
  let palletPositions = 0;
  const hasCartonStorage = !!storageTypes.cartons && storageTypes.cartons.enabled;
  const hasBinStorage = !!storageTypes.items && storageTypes.items.enabled;
  
  // Get pallet positions
  if (storageTypes.pallets && storageTypes.pallets.initial) {
    palletPositions = parseInt(String(storageTypes.pallets.initial)) || 0;
  }
  
  return { palletPositions, hasCartonStorage, hasBinStorage };
};

/**
 * Check if prospect has custom rates
 */
export const hasCustomRates = (prospect: Prospect): boolean => {
  return !!(prospect.custom_rates && 
    typeof prospect.custom_rates === 'object' && 
    Object.keys(prospect.custom_rates).length > 0);
};

/**
 * Get appropriate color for rate tier
 */
export const getRateTierColor = (tier: string): string => {
  switch (tier) {
    case 'D2C':
      return 'text-blue-600';
    case 'D2C+':
      return 'text-blue-700';
    case 'SMB':
      return 'text-green-600';
    case 'SMB+':
      return 'text-green-700';
    case 'LEB':
      return 'text-amber-600';
    case 'CUSTOM':
      return 'text-purple-600';
    default:
      return 'text-gray-600';
  }
};
