
import { TableCell, TableRow } from "@/components/ui/table";
import { formatCurrency } from "@/lib/utils";
import { RateCell } from "../RateCell";

interface ItemStorageRowProps {
  itemStorageType: string;
  rate: number;
  quantity: number;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isModified: boolean;
}

export const ItemStorageRow = ({
  itemStorageType,
  rate,
  quantity,
  onRateChange,
  isCustomizing,
  isModified
}: ItemStorageRowProps) => {
  return (
    <TableRow>
      <TableCell className="font-medium">
        {itemStorageType === 'shelf' ? 'Item Shelf Storage' : 'Item Bin Storage'}
      </TableCell>
      <RateCell 
        fieldKey="storageBin" 
        rate={rate || 0}
        onChange={onRateChange}
        isCustomizing={isCustomizing}
        isModified={isModified}
        label="/cu. ft."
      />
      <TableCell>{quantity}</TableCell>
      <TableCell>
        {formatCurrency((rate || 0) * quantity)}
      </TableCell>
    </TableRow>
  );
};
