
import { BudgetaryQuoteSheet } from "@/components/quotes/BudgetarySheet";
import { hasCustomRates } from "@/utils/rates/recommendedTierUtils";
import { DisplayRateTier } from "@/utils/rates";

interface BudgetaryQuoteContainerProps {
  quote: any;
  currentTier: DisplayRateTier;
  isFirmQuote: boolean;
  isCustomizing: boolean;
  hasCustomRatesValue?: boolean;
  handleTierChangeFromSheet: (newTier: string) => void;
  handleMonthlyRevenueChange: (monthlyTotal: number) => void;
  handleCustomRatesDetected: () => void;
}

export const BudgetaryQuoteContainer = ({
  quote,
  currentTier,
  isFirmQuote,
  isCustomizing,
  hasCustomRatesValue,
  handleTierChangeFromSheet,
  handleMonthlyRevenueChange,
  handleCustomRatesDetected
}: BudgetaryQuoteContainerProps) => {
  // Determine if this quote has custom rates if not explicitly provided
  const effectiveHasCustomRates = hasCustomRatesValue !== undefined 
    ? hasCustomRatesValue 
    : quote ? hasCustomRates(quote) : false;

  return (
    <BudgetaryQuoteSheet 
      approvedRateTier={currentTier}
      quoteData={quote}
      showExtendedPricing={true}
      isCustomizing={isCustomizing}
      isAdminView={true}
      quoteId={quote?.id}
      quoteFirmStatus={isFirmQuote ? 'firm' : 'budgetary'}
      onTierChange={handleTierChangeFromSheet}
      hasCustomRates={effectiveHasCustomRates}
      onMonthlyTotalChange={handleMonthlyRevenueChange}
      onCustomRatesDetected={handleCustomRatesDetected}
    />
  );
};
