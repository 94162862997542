
import { motion } from "framer-motion";
import { HeroContent } from "./HeroContent";
import { ErrorBoundary } from "@/components/ui/error-boundary";
import { useState, useEffect } from "react";
import { Image } from "@/components/ui/image";
import { TestimonialCard } from "./TestimonialCard";

export const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const heroImages = [
    "/lovable-uploads/7dc42fdb-fb35-490e-b002-84b0339d02f3.png",
    "/lovable-uploads/d7169dd3-8a1c-4e14-9e05-a5fca0960ed2.png"
  ];

  // Set up image rotation
  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }, 15000); // 15 seconds

    return () => clearInterval(rotationInterval);
  }, []);

  return (
    <motion.section 
      className="relative min-h-[calc(100vh-4rem)] overflow-hidden bg-[#000F00] text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="relative w-full h-full min-h-[calc(100vh-4rem)]">
        {/* Hero images with crossfade transition */}
        <div className="absolute inset-0 w-full h-full">
          {heroImages.map((src, index) => (
            <motion.div 
              key={index}
              className="absolute inset-0 w-full h-full"
              initial={{ opacity: index === currentImageIndex ? 1 : 0 }}
              animate={{ opacity: index === currentImageIndex ? 1 : 0 }}
              transition={{ duration: 1.5 }}
            >
              <Image 
                src={src} 
                alt={index === 0 ? "Turtlebox founder with product" : "Thrive 3PL founder with Turtlebox product"} 
                className="w-full h-full object-cover"
              />
            </motion.div>
          ))}
        </div>
        
        {/* Testimonial card - only visible when the second image is shown */}
        {currentImageIndex === 1 && <TestimonialCard />}
        
        {/* Content overlay */}
        <div className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex flex-col justify-center h-full py-16">
            <div className="max-w-xl flex flex-col items-start justify-center min-h-[calc(80vh-4rem)]">
              <ErrorBoundary>
                <HeroContent />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};
