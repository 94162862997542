
import { Card, CardContent } from "@/components/ui/card";
import { Prospect } from "../types";
import { useEffect, useState } from "react";
import { calculateQuoteDetails, extractScopeData } from "../components/QuoteCalculator/utils";
import { isObject } from "@/lib/utils";

interface StatsCardsProps {
  activeProspects: Prospect[];
  archivedProspects: Prospect[];
}

export const StatsCards = ({ activeProspects, archivedProspects }: StatsCardsProps) => {
  // State to store calculated monthly values
  const [pipelineValue, setPipelineValue] = useState(0);

  // Calculate pipeline value based on quote calculations
  useEffect(() => {
    let totalPipelineValue = 0;
    
    activeProspects.forEach(prospect => {
      if (prospect) {
        try {
          // Extract scope data for calculations
          const scopeData = extractScopeData(prospect);
          
          // Calculate quote details using the default rate tier or approved rate tier
          const rateTier = prospect.approved_rate_tier || 'D2C';
          
          // Properly type custom rates
          const customRates = prospect.custom_rates && isObject(prospect.custom_rates) 
            ? prospect.custom_rates as Record<string, number>
            : undefined;
            
          const quoteDetails = calculateQuoteDetails(
            rateTier === 'CUSTOM' ? 'D2C' : rateTier,
            scopeData,
            customRates
          );
          
          // Add the monthly total to our pipeline value
          if (quoteDetails && quoteDetails.monthly && quoteDetails.monthly.total) {
            totalPipelineValue += quoteDetails.monthly.total;
          }
        } catch (err) {
          console.error(`Error calculating monthly value for prospect ${prospect.id}:`, err);
        }
      }
    });
    
    setPipelineValue(totalPipelineValue);
  }, [activeProspects]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <Card>
        <CardContent className="p-6">
          <div className="text-2xl font-bold">
            {activeProspects.length}
          </div>
          <p className="text-sm text-gray-600">Active Prospects</p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-6">
          <div className="text-2xl font-bold">
            ${pipelineValue.toLocaleString()}
          </div>
          <p className="text-sm text-gray-600">Pipeline Value (Monthly)</p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-6">
          <div className="text-2xl font-bold">
            {archivedProspects.length}
          </div>
          <p className="text-sm text-gray-600">Archived Prospects</p>
        </CardContent>
      </Card>
    </div>
  );
};
