
import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Sparkles, AlertTriangle, RefreshCw } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { ResearchControls } from "./ResearchControls";
import { DatabaseQuote } from "@/pages/Quote/types";
import { useResearchState } from "./hooks/useResearchState";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";

interface ResearchContentProps {
  quote: DatabaseQuote;
}

export const ResearchContent: React.FC<ResearchContentProps> = ({ quote }) => {
  const {
    isResearching,
    research,
    clientFriendlyNarrative,
    hasLimitedInfo,
    sections,
    researchTimestamp,
    errorMessage,
    handleRefreshResearch
  } = useResearchState(quote);

  // For debugging
  console.log("ResearchContent rendering:", { 
    hasResearch: !!research,
    hasClientFriendlyNarrative: !!clientFriendlyNarrative,
    narrativeLength: clientFriendlyNarrative?.length,
    hasError: !!errorMessage,
    researchTimestamp 
  });

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-lg">Company Research</CardTitle>
        <ResearchControls 
          isResearching={isResearching}
          researchTimestamp={researchTimestamp}
          onRefresh={handleRefreshResearch}
        />
      </CardHeader>

      <CardContent>
        {errorMessage && (
          <Alert variant="destructive" className="mb-4">
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription className="flex flex-col gap-2">
              <div>{errorMessage}</div>
              <Button 
                variant="outline" 
                size="sm" 
                className="self-start"
                onClick={handleRefreshResearch}
                disabled={isResearching}
              >
                <RefreshCw className={`h-4 w-4 mr-2 ${isResearching ? 'animate-spin' : ''}`} />
                Try Again
              </Button>
            </AlertDescription>
          </Alert>
        )}
        
        {research ? (
          <div className="space-y-4">
            <div className="flex items-center">
              <Sparkles className="h-4 w-4 text-green-600 mr-2" />
              <span className="text-sm font-medium">Web-Enhanced Research</span>
            </div>
            
            {hasLimitedInfo && (
              <Alert variant="warning" className="mb-4">
                <AlertTriangle className="h-4 w-4" />
                <AlertDescription>
                  Limited information was found for this company. We've provided our best analysis based on available data.
                </AlertDescription>
              </Alert>
            )}
            
            <div className="mt-4 p-4 bg-muted/20 rounded-md border border-muted">
              <div className="text-sm space-y-2">
                {sections.all.split('\n\n').map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </div>
            </div>
            
            {clientFriendlyNarrative && (
              <Accordion type="single" collapsible className="mt-2">
                <AccordionItem value="client-narrative">
                  <AccordionTrigger className="text-sm font-medium">
                    Client-Friendly Narrative
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="text-sm text-muted-foreground space-y-2 p-4 bg-muted/10 rounded-md">
                      {clientFriendlyNarrative.split('\n\n').map((paragraph, i) => (
                        <p key={i}>{paragraph}</p>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
            
            <Separator />
          </div>
        ) : (
          <div className="text-muted-foreground text-sm p-4 border border-dashed rounded-md text-center">
            {isResearching 
              ? "Analyzing company information..." 
              : "No research data available. Click 'Refresh Research' to analyze company information."}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
