
import { lazy } from "react";
import { Route } from "react-router-dom";

// Lazy load content pages - Fulfillment
const DTC = lazy(() => import("@/pages/fulfillment/DTC"));
const B2B = lazy(() => import("@/pages/fulfillment/B2B"));
const FBA = lazy(() => import("@/pages/fulfillment/FBA"));
const FBM = lazy(() => import("@/pages/fulfillment/FBM"));
const Returns = lazy(() => import("@/pages/fulfillment/Returns"));
const Kitting = lazy(() => import("@/pages/fulfillment/Kitting"));
const SpecialProjects = lazy(() => import("@/pages/fulfillment/SpecialProjects"));

// Lazy load content pages - Warehousing
const StoragePage = lazy(() => import("@/pages/warehousing/Storage"));
const WarehousingInventory = lazy(() => import("@/pages/warehousing/Inventory"));
const Freight = lazy(() => import("@/pages/warehousing/Freight"));
const Dock = lazy(() => import("@/pages/warehousing/Dock"));

// Lazy load content pages - Technology
const WMS = lazy(() => import("@/pages/technology/WMS"));
const Parcel = lazy(() => import("@/pages/technology/Parcel"));
const Integrations = lazy(() => import("@/pages/technology/Integrations"));

// Lazy load content pages - Locations
const Houston = lazy(() => import("@/pages/locations/Houston"));

// Lazy load content pages - Customers
const WhyThrive = lazy(() => import("@/pages/customers/WhyThrive"));
const Apparel = lazy(() => import("@/pages/customers/Apparel"));
const FoodBeverage = lazy(() => import("@/pages/customers/FoodBeverage"));
const OmniChannel = lazy(() => import("@/pages/customers/OmniChannel"));
const EcommerceGrowth = lazy(() => import("@/pages/customers/EcommerceGrowth"));

// Lazy load content pages - Company
const Investors = lazy(() => import("@/pages/company/Investors"));

// Lazy load content pages - Pricing
const PricingPhilosophy = lazy(() => import("@/pages/pricing/Philosophy"));

export const ContentRoutes = (
  <>
    {/* Fulfillment Routes */}
    <Route path="/fulfillment">
      <Route path="dtc" element={<DTC />} />
      <Route path="b2b" element={<B2B />} />
      <Route path="fba" element={<FBA />} />
      <Route path="fbm" element={<FBM />} />
      <Route path="returns" element={<Returns />} />
      <Route path="kitting" element={<Kitting />} />
      <Route path="special-projects" element={<SpecialProjects />} />
    </Route>

    {/* Warehousing Routes */}
    <Route path="/warehousing">
      <Route path="storage" element={<StoragePage />} />
      <Route path="inventory" element={<WarehousingInventory />} />
      <Route path="freight" element={<Freight />} />
      <Route path="dock" element={<Dock />} />
    </Route>

    {/* Technology Routes */}
    <Route path="/technology">
      <Route path="wms" element={<WMS />} />
      <Route path="parcel" element={<Parcel />} />
      <Route path="integrations" element={<Integrations />} />
    </Route>

    {/* Locations Routes */}
    <Route path="/locations">
      <Route path="houston" element={<Houston />} />
    </Route>

    {/* Customers Routes */}
    <Route path="/customers">
      <Route path="why-thrive" element={<WhyThrive />} />
      <Route path="apparel" element={<Apparel />} />
      <Route path="food-beverage" element={<FoodBeverage />} />
      <Route path="omni-channel" element={<OmniChannel />} />
      <Route path="ecommerce-growth" element={<EcommerceGrowth />} />
    </Route>

    {/* Company Routes */}
    <Route path="/company">
      <Route path="investors" element={<Investors />} />
    </Route>

    {/* Pricing Routes */}
    <Route path="/pricing">
      <Route path="philosophy" element={<PricingPhilosophy />} />
    </Route>
  </>
);
