
import { useState, useCallback } from "react";
import { getDefaultExpandedSections } from "./rateUtils";

export const useSectionToggle = () => {
  const [expandedSections, setExpandedSections] = useState(getDefaultExpandedSections());
  
  // Toggle expanded sections
  const toggleSection = useCallback((section: string): void => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  }, []);
  
  return {
    expandedSections,
    toggleSection
  };
};
