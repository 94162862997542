
import { useState, useEffect } from "react";
import { RateTier, DisplayRateTier } from "@/utils/rates";

interface UseBaseTierProps {
  approvedRateTier: DisplayRateTier;
  quoteData?: any;
}

/**
 * Hook to manage the base rate tier
 */
export const useBaseTier = ({ approvedRateTier, quoteData }: UseBaseTierProps) => {
  // Always store the original tier that will be used for non-customized rates
  // If approvedRateTier is CUSTOM, we need to determine the underlying base tier
  const [baseTier, setBaseTier] = useState<RateTier>(
    approvedRateTier !== 'CUSTOM' ? approvedRateTier as RateTier : 
    quoteData?.original_rate_tier ? quoteData.original_rate_tier as RateTier : 'D2C'
  );
  
  // Update baseTier when approvedRateTier changes (but only if not CUSTOM)
  useEffect(() => {
    console.log("useBaseTier: approvedRateTier changed to:", approvedRateTier);
    if (approvedRateTier !== 'CUSTOM') {
      console.log("useBaseTier: Setting baseTier to:", approvedRateTier);
      setBaseTier(approvedRateTier as RateTier);
    } else if (quoteData?.original_rate_tier) {
      console.log("useBaseTier: Setting baseTier to original_rate_tier:", quoteData.original_rate_tier);
      setBaseTier(quoteData.original_rate_tier as RateTier);
    }
  }, [approvedRateTier, quoteData?.original_rate_tier]);
  
  return { baseTier, setBaseTier };
};
