
import { Wrench, Package, Archive, PackageCheck, Settings } from "lucide-react";
import { RateSection } from "./types";
import { RateStructure } from "@/utils/rates";

export const rateSections: RateSection[] = [
  {
    title: "Setup & Configuration",
    icon: Wrench,
    fields: [
      { key: 'setupFee', label: 'Setup Fee' },
      { key: 'skuSetupFee', label: 'SKU Setup Fee (per SKU)' },
      { key: 'skuBulkUpload', label: 'SKU Bulk Upload' },
    ]
  },
  {
    title: "Receiving",
    icon: Package,
    fields: [
      { key: 'receivingStandardCarton', label: 'Receiving Standard Carton' },
      { key: 'receivingExtraweightCarton', label: 'Receiving Extra-weight Carton' },
      { key: 'receivingStandardPallet', label: 'Receiving Standard Pallet' },
      { key: 'receiving20Container', label: 'Receiving 20\' (Floor Loaded) Container' },
      { key: 'receiving40Container', label: 'Receiving 40\' (Floor Loaded) Container' },
      { key: 'receivingFTL', label: 'Receiving 53\' Dry Van (Floor Loaded)' },
      { key: 'receivingPalletizeOnReceipt', label: 'Palletize on Receipt' },
    ]
  },
  {
    title: "Storage",
    icon: Archive,
    fields: [
      { key: 'storageBin', label: 'Storage Bin (/cu. ft.)' },
      { key: 'storageShelves', label: 'Storage Shelves (/cu. ft.)' },
      { key: 'storageFirst100Pallets', label: 'Storage First 100 Pallets' },
      { key: 'storageAdditionalPallets', label: 'Storage Additional Pallets' },
    ]
  },
  {
    title: "Fulfillment",
    icon: PackageCheck,
    fields: [
      { key: 'pickPackD2CBase', label: 'Pick & Pack D2C Base' },
      { key: 'pickPackD2CPerUnit', label: 'Pick & Pack D2C Per Unit' },
      { key: 'pickPackB2BBase', label: 'Pick & Pack B2B Base' },
      { key: 'pickPackB2BPerUnit', label: 'Pick & Pack B2B Per Unit' },
      { key: 'outboundStandardCarton', label: 'Outbound Standard Carton' },
      { key: 'outboundExtraweightCarton', label: 'Outbound Extra-weight Carton' },
      { key: 'outboundPalletForwarding', label: 'Outbound Pallet Forwarding' },
      { key: 'outboundPalletizingCartons', label: 'Outbound Palletizing Cartons' },
      { key: 'labelingStickeringPerUnit', label: 'Labeling/Stickering Per Unit' },
      { key: 'kittingBaggingPerPiece', label: 'Kitting/Bagging Per Piece' },
    ]
  },
  {
    title: "Services",
    icon: Settings,
    fields: [
      { key: 'manualOrderEntry', label: 'Manual Order Entry (Per Upload/Order)' },
      { key: 'bolCreation', label: 'Bill of Lading Creation' },
      { key: 'bookDrayage', label: 'Book Drayage' },
      { key: 'standardWOMSPortal', label: 'Standard WOMS Portal (Per Month)' },
      { key: 'enhancedWOMSPortal', label: 'Enhanced WOMS Portal (Per Month)' },
      { key: 'warehouseLabor', label: 'Warehouse/Project Labor (Per Hour)' },
      { key: 'warehouseManagement', label: 'Warehouse/Project Management (Per Hour)' },
    ]
  },
];
