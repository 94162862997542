
import { RateTier, rateTable } from "@/utils/rates";
import { ScopeData } from './types/scopeTypes';
import { 
  calculateReceivingCosts, 
  calculateStorageCosts, 
  calculateFulfillmentCosts 
} from './calculators';

// Calculate the quote details based on scope and rates
export const calculateQuoteDetails = (
  rateTier: RateTier,
  scopeData: ScopeData,
  customRates?: Record<string, number>
) => {
  // Start with the standard rates from the rate table
  let rateData: Record<string, number> = {};
  
  // Copy all properties from rateTable[rateTier] to rateData
  for (const [key, value] of Object.entries(rateTable[rateTier])) {
    rateData[key] = value;
  }
  
  // Override with custom rates if provided
  if (customRates && Object.keys(customRates).length > 0) {
    console.log("Using custom rates for calculation:", customRates);
    rateData = { ...rateData, ...customRates };
  }

  // Calculate monthly costs
  const receivingCosts = calculateReceivingCosts(scopeData, rateData);
  const storageCosts = calculateStorageCosts(scopeData, rateData);
  const fulfillmentCosts = calculateFulfillmentCosts(scopeData, rateData);
  const monthlyServicesCost = rateData.standardWOMSPortal || 0;
  
  // Calculate value-added services costs
  let valueAddedCost = 0;
  if (scopeData.hasKitting) valueAddedCost += 100; // Simplified
  if (scopeData.hasReturns) valueAddedCost += 75;  // Simplified
  if (scopeData.hasLabeling) valueAddedCost += 50; // Simplified

  // Calculate setup costs
  const setupFee = rateData.setupFee || 0;
  const skuSetupFee = (rateData.skuSetupFee || 0) * (scopeData.totalSkus || 50);

  const monthlyTotal = receivingCosts + storageCosts + fulfillmentCosts + monthlyServicesCost + valueAddedCost;
  const setupTotal = setupFee + skuSetupFee;

  return {
    monthly: {
      receiving: receivingCosts,
      storage: storageCosts,
      fulfillment: fulfillmentCosts,
      services: monthlyServicesCost,
      valueAdded: valueAddedCost,
      total: monthlyTotal,
    },
    setup: {
      accountSetup: setupFee,
      skuSetup: skuSetupFee,
      total: setupTotal,
    },
    annualized: monthlyTotal * 12 + setupTotal,
  };
};
