
import React from "react";
import { Skeleton } from "@/components/ui/skeleton";

export const ImagesLoadingState: React.FC = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {Array.from({ length: 4 }).map((_, i) => (
        <div key={i} className="flex flex-col gap-2">
          <Skeleton className="h-36 w-full rounded-md" />
          <Skeleton className="h-4 w-3/4" />
        </div>
      ))}
    </div>
  );
};
