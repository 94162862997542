
import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { DatabaseQuote } from "@/pages/Quote/types";
import { ShoppingBag, Box, Package, Truck, Tag, List } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { getFulfillmentRequirements, getAmazonFBADetails, getValueAddedServices, getPackagingMaterials } from "../utils";

interface FulfillmentTabProps {
  quote: DatabaseQuote;
}

const FulfillmentTab: React.FC<FulfillmentTabProps> = ({ quote }) => {
  const fbaDetails = getAmazonFBADetails(quote);
  const fulfillmentRequirements = getFulfillmentRequirements(quote);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Outbound & Fulfillment</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h4 className="font-semibold mb-2">Fulfillment Types and Volumes</h4>
          <div className="space-y-4">
            {fulfillmentRequirements.map((item, index) => {
              if (item.type === "Parcel Shipments") {
                return (
                  <div key={index} className="border rounded-lg p-4 bg-purple-50">
                    <div className="flex items-center mb-3">
                      <ShoppingBag className="h-5 w-5 text-purple-600 mr-2" />
                      <h4 className="font-semibold text-purple-700">{item.type}</h4>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-3">
                      <div>
                        <p className="text-sm text-gray-500">Monthly Volume</p>
                        <p className="font-medium">{item.volume}/month</p>
                      </div>
                    </div>
                    <p className="text-sm text-gray-600">{item.details}</p>
                  </div>
                );
              } else if (item.type === "Pallet Shipments") {
                return (
                  <div key={index} className="border rounded-lg p-4 bg-amber-50">
                    <div className="flex items-center mb-3">
                      <Box className="h-5 w-5 text-amber-600 mr-2" />
                      <h4 className="font-semibold text-amber-700">{item.type}</h4>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-3">
                      <div>
                        <p className="text-sm text-gray-500">Monthly Volume</p>
                        <p className="font-medium">{item.volume}/month</p>
                      </div>
                    </div>
                    <p className="text-sm text-gray-600">{item.details}</p>
                  </div>
                );
              } else if (item.type === "Carton Forwarding") {
                return (
                  <div key={index} className="border rounded-lg p-4 bg-emerald-50">
                    <div className="flex items-center mb-3">
                      <Box className="h-5 w-5 text-emerald-600 mr-2" />
                      <h4 className="font-semibold text-emerald-700">{item.type}</h4>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-3">
                      <div>
                        <p className="text-sm text-gray-500">Monthly Volume</p>
                        <p className="font-medium">{item.volume}/month</p>
                      </div>
                    </div>
                    <p className="text-sm text-gray-600">{item.details}</p>
                  </div>
                );
              } else if (item.type === "Amazon FBA Prep") {
                // If it's Amazon FBA, we'll use a different layout with sections
                return (
                  <div key={index} className="border rounded-lg p-4 bg-blue-50">
                    <div className="flex items-center mb-3">
                      <Package className="h-5 w-5 text-blue-600 mr-2" />
                      <h4 className="font-semibold text-blue-700">Amazon FBA Prep</h4>
                    </div>
                    
                    {fbaDetails && (parseInt(fbaDetails.monthlyLtlPallets) > 0 || parseInt(fbaDetails.monthlyParcelCartons) > 0) && (
                      <div className="mb-4">
                        <h5 className="text-sm font-medium text-blue-800 mb-2 flex items-center">
                          <Truck className="h-4 w-4 mr-1" /> 
                          Amazon FBA Shipping Methods
                        </h5>
                        <div className="bg-white rounded-md p-3 grid grid-cols-2 gap-3">
                          {parseInt(fbaDetails.monthlyLtlPallets) > 0 && (
                            <div className="border-l-2 border-blue-400 pl-2">
                              <p className="text-sm text-gray-500">LTL Pallet Shipments</p>
                              <p className="font-medium">{fbaDetails.monthlyLtlPallets}/month</p>
                            </div>
                          )}
                          {parseInt(fbaDetails.monthlyParcelCartons) > 0 && (
                            <div className="border-l-2 border-blue-400 pl-2">
                              <p className="text-sm text-gray-500">Parcel Carton Shipments</p>
                              <p className="font-medium">{fbaDetails.monthlyParcelCartons}/month</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    
                    {fbaDetails && (parseInt(fbaDetails.monthlyFnskuLabels) > 0 || parseInt(fbaDetails.monthlyFbaCasePack) > 0) && (
                      <div>
                        <h5 className="text-sm font-medium text-blue-800 mb-2 flex items-center">
                          <Tag className="h-4 w-4 mr-1" /> 
                          Amazon FBA Value-Added Services
                        </h5>
                        <div className="bg-white rounded-md p-3 grid grid-cols-2 gap-3">
                          {parseInt(fbaDetails.monthlyFnskuLabels) > 0 && (
                            <div className="border-l-2 border-indigo-400 pl-2">
                              <p className="text-sm text-gray-500">FNSKU Labeling</p>
                              <p className="font-medium">{fbaDetails.monthlyFnskuLabels} units/month</p>
                            </div>
                          )}
                          {parseInt(fbaDetails.monthlyFbaCasePack) > 0 && (
                            <div className="border-l-2 border-indigo-400 pl-2">
                              <p className="text-sm text-gray-500">Case Packing</p>
                              <p className="font-medium">{fbaDetails.monthlyFbaCasePack} units/month</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    
                    <div className="mt-3 pt-3 border-t border-blue-200">
                      <p className="text-sm text-gray-600">Preparation & forwarding to FBA centers</p>
                    </div>
                  </div>
                );
              }
              return null;
            })}

            {fulfillmentRequirements.length === 0 && (
              <div className="text-center text-gray-500 py-4 border rounded-md">
                No fulfillment requirements specified
              </div>
            )}
          </div>
        </div>

        {quote.fulfillment_requirements && 
         typeof quote.fulfillment_requirements === 'object' && 
         !Array.isArray(quote.fulfillment_requirements) && 
         (quote.fulfillment_requirements as any).details && (
          <div className="mt-3">
            <h4 className="font-semibold mb-2">Additional Details</h4>
            <div className="border rounded-md p-3 text-sm whitespace-pre-wrap">
              {String((quote.fulfillment_requirements as any).details)}
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 gap-6">
          <Card className="border shadow-none">
            <CardHeader className="py-3 px-4">
              <CardTitle className="text-base font-medium">Value Added Services</CardTitle>
            </CardHeader>
            <CardContent className="px-4 pb-4 pt-0">
              {getValueAddedServices(quote).length > 0 ? (
                <ul className="space-y-3">
                  {getValueAddedServices(quote).map((service, index) => (
                    <li key={index}>
                      <div className="flex items-center">
                        <span className="h-1.5 w-1.5 rounded-full bg-primary mr-2"></span>
                        <span className="font-medium">{service.name}</span>
                      </div>
                      {service.details && <p className="text-sm text-gray-600 mt-1 ml-4">{service.details}</p>}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted-foreground text-sm">No value added services requested</p>
              )}
            </CardContent>
          </Card>

          <Card className="border shadow-none">
            <CardHeader className="py-3 px-4">
              <CardTitle className="text-base font-medium">Packaging Materials</CardTitle>
            </CardHeader>
            <CardContent className="px-4 pb-4 pt-0">
              {getPackagingMaterials(quote).length > 0 ? (
                <ul className="space-y-1">
                  {getPackagingMaterials(quote).map((material, index) => (
                    <li key={index} className="flex items-center">
                      <span className="h-1.5 w-1.5 rounded-full bg-primary mr-2"></span>
                      <span>{material}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted-foreground text-sm">No packaging materials specified</p>
              )}
            </CardContent>
          </Card>
        </div>
      </CardContent>
    </Card>
  );
};

export { FulfillmentTab };
export default FulfillmentTab;
