
import { lazy } from "react";
import { Route } from "react-router-dom";

// Lazy load quote components
const Quote = lazy(() => import("@/pages/Quote"));
const Contact = lazy(() => import("@/pages/Quote/Contact"));
const Business = lazy(() => import("@/pages/Quote/Business"));
const Storage = lazy(() => import("@/pages/Quote/Storage"));
const Receiving = lazy(() => import("@/pages/Quote/Receiving"));
const Outbound = lazy(() => import("@/pages/Quote/Outbound"));
const Acknowledgment = lazy(() => import("@/pages/Quote/Acknowledgment"));
const ThankYou = lazy(() => import("@/pages/Quote/ThankYou"));

export const QuoteRoutes = (
  <>
    <Route path="/quote" element={<Quote />} />
    <Route path="/quote/contact" element={<Contact />} />
    <Route path="/quote/business" element={<Business />} />
    <Route path="/quote/storage" element={<Storage />} />
    <Route path="/quote/receiving" element={<Receiving />} />
    <Route path="/quote/outbound" element={<Outbound />} />
    <Route path="/quote/acknowledgment" element={<Acknowledgment />} />
    <Route path="/quote/thank-you" element={<ThankYou />} />
  </>
);
