
import React from 'react';
import { ShoppingBag } from "lucide-react";

interface ChannelMixSectionProps {
  channelMix: { name: string, percentage: number }[];
}

export const ChannelMixSection = ({ channelMix }: ChannelMixSectionProps) => {
  if (channelMix.length === 0) {
    return null;
  }

  return (
    <div className="flex items-start">
      <ShoppingBag className="h-4 w-4 mr-2 text-blue-500 mt-0.5 flex-shrink-0" />
      <div className="flex-1">
        <h5 className="text-sm font-medium text-gray-700">Channel Mix</h5>
        <div className="text-sm">
          {channelMix.map((channel, index) => (
            <span key={channel.name}>
              {channel.name} {channel.percentage}%
              {index < channelMix.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
