
import { safeNumberConversion } from '../dataConversion';

// Extract receiving-related data from the quote data
export const extractReceivingData = (quoteData: any) => {
  // Extract receiving volumes data - check both volumes and receiving_volumes
  const volumes = quoteData.volumes || {};
  const receivingVolumes = quoteData.receiving_volumes || {};
  
  // Extract loading types
  const loadingTypes = quoteData.loading_types || {};
  
  // Extract pallet counts
  const palletCounts = quoteData.pallet_counts || {};

  // Extract container and shipment volumes from either volumes or receiving_volumes
  const container20Volume = safeNumberConversion(
    volumes.container20PerMonth || 
    receivingVolumes.container20PerMonth ||
    volumes.container20 || 
    receivingVolumes.container20 || 
    quoteData.container_20_volume || 
    0
  );
  
  const container40Volume = safeNumberConversion(
    volumes.container40PerMonth || 
    receivingVolumes.container40PerMonth ||
    volumes.container40 || 
    receivingVolumes.container40 || 
    quoteData.container_40_volume || 
    0
  );
  
  const ftlVolume = safeNumberConversion(
    volumes.ftlPerMonth || 
    receivingVolumes.ftlPerMonth ||
    volumes.ftl || 
    receivingVolumes.ftl || 
    quoteData.ftl_volume || 
    0
  );
  
  // For LTL, the volume represents number of pallets per month
  const ltlVolume = safeNumberConversion(
    volumes.ltlPerMonth || 
    receivingVolumes.ltlPerMonth ||
    volumes.ltl || 
    receivingVolumes.ltl || 
    quoteData.ltl_volume || 
    0
  );
  
  const parcelVolume = safeNumberConversion(
    volumes.parcelsPerMonth || 
    receivingVolumes.parcelsPerMonth ||
    volumes.parcel || 
    receivingVolumes.parcel || 
    quoteData.parcel_volume || 
    0
  );

  // Extract pallet counts
  const container20PalletCount = safeNumberConversion(palletCounts.container20, 0);
  const container40PalletCount = safeNumberConversion(palletCounts.container40, 0);
  const ftlPalletCount = safeNumberConversion(palletCounts.ftl, 0);

  return {
    container20Volume,
    container40Volume,
    ftlVolume,
    ltlVolume,  // This now represents pallets per month for LTL
    parcelVolume,
    loadingTypes: {
      container20: loadingTypes.container20,
      container40: loadingTypes.container40,
      ftl: loadingTypes.ftl,
    },
    palletCounts: {
      container20: container20PalletCount,
      container40: container40PalletCount,
      ftl: ftlPalletCount,
    }
  };
};
