
import { TableCell, TableRow } from "@/components/ui/table";
import { RateCell } from "../RateCell";
import { formatCurrency } from "@/lib/utils";
import { calculateLTLCost } from "./utils";

interface LTLRowProps {
  scopeData: any;
  rateCardData: Record<string, number>;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified: (key: string) => boolean;
}

export const LTLRow = ({
  scopeData,
  rateCardData,
  onRateChange,
  isCustomizing,
  isRateModified
}: LTLRowProps) => {
  return (
    <TableRow>
      <TableCell className="font-medium">LTL Receiving (Pallets)</TableCell>
      <TableCell>
        <RateCell 
          fieldKey="receivingStandardPallet" 
          rate={rateCardData.receivingStandardPallet || 0}
          onChange={onRateChange}
          isCustomizing={isCustomizing}
          isModified={isRateModified("receivingStandardPallet")}
        />
      </TableCell>
      <TableCell>{scopeData.ltlVolume || 0} pallets/mo</TableCell>
      <TableCell>
        {formatCurrency(calculateLTLCost(scopeData, rateCardData))}
      </TableCell>
    </TableRow>
  );
};
