
import React from "react";

export const TableEmptyState = () => {
  return (
    <div className="py-8 text-center">
      <p className="text-gray-500">No prospects found. Create a quote to get started.</p>
    </div>
  );
};
