
export const AVAILABLE_CHANNELS = [
  { id: "own-ecommerce", name: "Own E-commerce Website" },
  { id: "amazon-fba", name: "Amazon FBA" },
  { id: "amazon-fbm", name: "Amazon FBM" },
  { id: "retail-b2b", name: "Retail B2B" },
  { id: "marketplaces", name: "Other Marketplaces (Walmart, eBay, TikTok Shop, etc.)" },
  { id: "retail-dropship", name: "Retail Dropship (Non-marketplace)" },
  { id: "popup", name: "Pop-up Shops/Events" },
  { id: "subscription", name: "Subscription Box" },
  { id: "tradeshows", name: "Trade Shows/Events" },
  { id: "b2b", name: "B2B Distribution" },
  { id: "international", name: "International Markets" }
];

export const CHART_COLORS = [
  '#8B5CF6', // Vivid Purple
  '#0EA5E9', // Ocean Blue
  '#F97316', // Bright Orange
  '#2DD4BF', // Teal (replaced magenta with a green-teal color)
  '#10B981', // Emerald
  '#EC4899', // Pink
  '#F59E0B', // Amber
  '#06B6D4', // Cyan
  '#6366F1', // Indigo
  '#14B8A6', // Teal
  '#4ADE80'  // Green
];

export const BUSINESS_IDENTITIES = [
  { value: "dtc", label: "Direct-to-Consumer Brand" },
  { value: "marketplace", label: "Marketplace Seller" },
  { value: "omni-retail", label: "Omni-Channel Retail Brand" },
  { value: "wholesaler-manufacturer", label: "Wholesaler or Manufacturer" },
  { value: "retail-store", label: "Retail Store" },
  { value: "logistics", label: "Logistics Manager" },
  { value: "other", label: "Other" }
];

