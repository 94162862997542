
import React from "react";

export const EmptyChatState: React.FC = () => {
  return (
    <div className="text-center text-gray-500 py-4">
      No chat history available
    </div>
  );
};
