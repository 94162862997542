
import React from 'react';
import { Package } from "lucide-react";

interface ProductsSectionProps {
  productDescription: string;
}

export const ProductsSection = ({ productDescription }: ProductsSectionProps) => {
  if (!productDescription) {
    return null;
  }

  return (
    <div className="flex items-start">
      <Package className="h-4 w-4 mr-2 text-purple-500 mt-0.5 flex-shrink-0" />
      <div className="flex-1">
        <h5 className="text-sm font-medium text-gray-700">Products</h5>
        <p className="text-sm break-words whitespace-pre-wrap">{productDescription}</p>
      </div>
    </div>
  );
};
