
import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { DatabaseQuote } from "@/pages/Quote/types";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { getStorageTypes, getSpecialStorageRequirements } from "../utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { InfoIcon } from "lucide-react";

interface StorageTabProps {
  quote: DatabaseQuote;
}

const StorageTab: React.FC<StorageTabProps> = ({ quote }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Storage Requirements</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h4 className="font-semibold mb-2 flex items-center gap-2">
            Storage Types
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoIcon className="h-4 w-4 text-muted-foreground cursor-help" />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="max-w-xs">Positions are only shown for pallet and carton storage types.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </h4>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[30%]">Storage Type</TableHead>
                <TableHead className="text-right w-[15%]">Positions</TableHead>
                <TableHead className="w-[55%]">Details</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getStorageTypes(quote).map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">{item.type}</TableCell>
                  <TableCell className="text-right">{item.positions}</TableCell>
                  <TableCell>{item.details}</TableCell>
                </TableRow>
              ))}
              {getStorageTypes(quote).length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} className="text-center text-muted-foreground">
                    No storage types specified
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <Card className="border shadow-none">
            <CardHeader className="py-3 px-4">
              <CardTitle className="text-base font-medium">Special Storage Requirements</CardTitle>
            </CardHeader>
            <CardContent className="px-4 pb-4 pt-0">
              {getSpecialStorageRequirements(quote).length > 0 ? (
                <ul className="space-y-1">
                  {getSpecialStorageRequirements(quote).map((requirement, index) => (
                    <li key={index} className="flex items-center">
                      <span className="h-1.5 w-1.5 rounded-full bg-primary mr-2"></span>
                      <span>{requirement}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted-foreground text-sm">No special storage requirements</p>
              )}
            </CardContent>
          </Card>

          {quote.other_storage_requirement && (
            <Card className="border shadow-none">
              <CardHeader className="py-3 px-4">
                <CardTitle className="text-base font-medium">Other Requirements</CardTitle>
              </CardHeader>
              <CardContent className="px-4 pb-4 pt-0">
                <p className="text-sm">{quote.other_storage_requirement}</p>
              </CardContent>
            </Card>
          )}
        </div>

        <div className="grid grid-cols-1 gap-6">
          {/* Moved bulk storage details to the top when present */}
          {quote.bulk_storage_details && (
            <Card className="border">
              <CardHeader className="py-3 px-4">
                <CardTitle className="text-base font-medium">Bulk Storage Details</CardTitle>
              </CardHeader>
              <CardContent className="px-4 pb-4 pt-0">
                <p className="text-sm whitespace-pre-wrap">{quote.bulk_storage_details}</p>
              </CardContent>
            </Card>
          )}

          {quote.storage_additional_context && (
            <Card className="border">
              <CardHeader className="py-3 px-4">
                <CardTitle className="text-base font-medium">Additional Context</CardTitle>
              </CardHeader>
              <CardContent className="px-4 pb-4 pt-0">
                <p className="text-sm whitespace-pre-wrap">{quote.storage_additional_context}</p>
              </CardContent>
            </Card>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export { StorageTab };
export default StorageTab;
