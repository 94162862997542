
import React from 'react';
import { Info } from "lucide-react";
import { getRateTierColor } from "../utils/tooltipUtils";
import { TooltipTrigger as RadixTooltipTrigger } from "@/components/ui/tooltip";

interface TooltipTriggerProps {
  displayTier: string;
  showCustomLabel: boolean;
  shouldShowRecommendedBadge: boolean;
  recommendedTier: string | null;
}

export const TooltipTriggerContent = ({
  displayTier,
  showCustomLabel,
  shouldShowRecommendedBadge,
  recommendedTier
}: TooltipTriggerProps) => {
  const tierColor = getRateTierColor(displayTier);
  const recommendedTierColor = recommendedTier ? getRateTierColor(recommendedTier) : '';

  return (
    <RadixTooltipTrigger asChild>
      <div className="flex items-center cursor-pointer">
        <div className="flex flex-col">
          <span className={`text-xs font-bold ${tierColor}`}>
            {displayTier}
            {showCustomLabel && (
              <span className="ml-1 text-xs text-purple-500">(Custom)</span>
            )}
          </span>
          
          {shouldShowRecommendedBadge && (
            <span className="text-[10px] text-amber-600">
              Rec: {recommendedTier}
            </span>
          )}
        </div>
        <Info className="h-3.5 w-3.5 ml-1 text-gray-400" />
      </div>
    </RadixTooltipTrigger>
  );
};
