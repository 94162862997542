
import { BudgetaryQuoteSheetProps } from "./types";
import { useBudgetaryQuote } from "../hooks/useBudgetaryQuote";
import { ClientView } from "./ClientView";
import { AdminView } from "./AdminView";

export const BudgetaryQuoteSheet = ({
  approvedRateTier,
  quoteData,
  showExtendedPricing = false,
  isCustomizing = false,
  quoteId,
  quoteFirmStatus = 'budgetary',
  isAdminView = false,
  onTierChange,
  hasCustomRates: externalHasCustomRates,
  onMonthlyTotalChange,
  onCustomRatesDetected,
  showAllRates = false,
  showMonthlyTotal = true,
  originalRateTier
}: BudgetaryQuoteSheetProps) => {
  // Direct check for custom rates in the database
  const hasCustomRatesInDB = quoteData?.custom_rates && 
    typeof quoteData.custom_rates === 'object' && 
    Object.keys(quoteData.custom_rates).length > 0;
  
  // Determine if custom rates should be used, with fallback chain
  const hasCustomRates = externalHasCustomRates !== undefined 
    ? externalHasCustomRates 
    : hasCustomRatesInDB;

  const handleCustomRatesDetected = () => {
    if (onCustomRatesDetected) {
      onCustomRatesDetected();
    }
  };

  const budgetaryQuoteData = useBudgetaryQuote({
    approvedRateTier,
    quoteData,
    isCustomizing,
    quoteId,
    quoteFirmStatus,
    isAdminView,
    onMonthlyTotalChange,
    onCustomRatesDetected: handleCustomRatesDetected
  });

  // Get the effective original rate tier, with fallbacks
  const effectiveOriginalRateTier = originalRateTier || 
    quoteData?.original_rate_tier || 
    budgetaryQuoteData.baseTier || 
    "D2C";

  // For client view, render a simplified version directly using custom rates
  if (!isAdminView && !showExtendedPricing) {
    return (
      <ClientView 
        quoteData={quoteData} 
        originalRateTier={effectiveOriginalRateTier}
      />
    );
  }

  // Handler function for tier changes
  function handleTierChangeWrapper(tier: string) {
    if (onTierChange) {
      onTierChange(tier);
    }
  }

  return (
    <AdminView
      budgetaryQuoteData={budgetaryQuoteData}
      selectedTier={approvedRateTier}
      recommendedTier={budgetaryQuoteData.recommendedTier}
      hasCustomRates={hasCustomRates}
      showMonthlyTotal={showMonthlyTotal}
      showAllRates={showAllRates}
      isCustomizing={isCustomizing}
      quoteFirmStatus={quoteFirmStatus}
      isAdminView={isAdminView}
      onTierChange={handleTierChangeWrapper}
    />
  );
};
