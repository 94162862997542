
import { ReceivingSection } from "./ReceivingSection";
import { StorageSection } from "./StorageSection";
import { FulfillmentSection } from "./FulfillmentSection";
import { MonthlyServicesSection } from "./MonthlyServicesSection";
import { SetupFeesSection } from "./SetupFeesSection";
import { QuoteSectionsProps } from "./types/QuoteSectionTypes";

export const QuoteSections = ({
  expandedSections,
  rateCardData,
  scopeData,
  quoteDetails,
  toggleSection,
  isCustomizing,
  onRateChange,
  isRateModified,
  showAllRates = false
}: QuoteSectionsProps) => {
  return (
    <div className="space-y-8 mt-6 animate-fade-in">
      <ReceivingSection
        expanded={expandedSections['Receiving']}
        rateCardData={rateCardData}
        scopeData={scopeData}
        quoteData={quoteDetails?.monthly.receiving}
        onToggle={() => toggleSection('Receiving')}
        isCustomizing={isCustomizing}
        onRateChange={onRateChange}
        isRateModified={isRateModified}
        showAllRates={showAllRates}
      />

      <StorageSection
        expanded={expandedSections['Storage']}
        rateCardData={rateCardData}
        scopeData={scopeData}
        quoteData={quoteDetails?.monthly.storage}
        onToggle={() => toggleSection('Storage')}
        isCustomizing={isCustomizing}
        onRateChange={onRateChange}
        isRateModified={isRateModified}
        showAllRates={showAllRates}
      />

      <FulfillmentSection
        expanded={expandedSections['Fulfillment']}
        rateCardData={rateCardData}
        scopeData={scopeData}
        quoteData={quoteDetails?.monthly.fulfillment}
        onToggle={() => toggleSection('Fulfillment')}
        isCustomizing={isCustomizing}
        onRateChange={onRateChange}
        isRateModified={isRateModified}
        showAllRates={showAllRates}
      />

      <MonthlyServicesSection
        expanded={expandedSections['Monthly Services']}
        rateCardData={rateCardData}
        quoteData={quoteDetails?.monthly.services}
        onToggle={() => toggleSection('Monthly Services')}
        isCustomizing={isCustomizing}
        onRateChange={onRateChange}
        isRateModified={isRateModified}
      />

      <SetupFeesSection
        expanded={expandedSections['Setup Fees']}
        rateCardData={rateCardData}
        quoteData={scopeData}
        onToggle={() => toggleSection('Setup Fees')}
        isCustomizing={isCustomizing}
        onRateChange={onRateChange}
        isRateModified={isRateModified}
      />
    </div>
  );
};
