
import { SubStep } from "@/types/progress";

export const getDefaultQuoteSubsteps = (): SubStep[] => [
  {
    id: 'quote-submitted',
    title: 'Quote Request Submitted',
    completed: true,
    description: 'Your quote request has been received',
    type: 'pending_review'
  },
  {
    id: 'quote-review',
    title: 'Quote Under Review',
    completed: false,
    description: 'Our team is reviewing your request',
    type: 'pending_review'
  },
  {
    id: 'budgetary-quote',
    title: 'Budgetary Quote',
    completed: false,
    description: 'Review your initial pricing estimate',
    type: 'budgetary_quote'
  },
  {
    id: 'firm-quote',
    title: 'Firm Quote Received',
    completed: false,
    description: 'Your final quote is ready for review',
    type: 'firm_quote'
  }
];

export const getDefaultDiscoverySubsteps = (): SubStep[] => [
  {
    id: 'discovery-intro',
    title: 'Business Consultation',
    completed: false,
    description: 'Share details about your business needs'
  },
  {
    id: 'discovery-complete',
    title: 'Consultation Completed',
    completed: false,
    description: 'Your information has been submitted'
  }
];

export const getDefaultContractSubsteps = (): SubStep[] => [
  {
    id: 'contract-review',
    title: 'Review Agreement',
    completed: false,
    description: 'Review your fulfillment agreement'
  },
  {
    id: 'contract-sign',
    title: 'Sign Agreement',
    completed: false,
    description: 'Complete your electronic signature'
  }
];

export const getDefaultOnboardingSubsteps = (): SubStep[] => [
  {
    id: 'welcome',
    title: 'Welcome & Setup',
    completed: false,
    description: 'Set up your fulfillment account'
  },
  {
    id: 'products',
    title: 'Product Setup',
    completed: false,
    description: 'Upload your product information'
  },
  {
    id: 'integration',
    title: 'Integration',
    completed: false,
    description: 'Connect your sales channels'
  },
  {
    id: 'go-live',
    title: 'Go Live',
    completed: false,
    description: 'Prepare for fulfillment launch'
  }
];

export const getDefaultSubsteps = (stepType: string): SubStep[] => {
  switch (stepType) {
    case 'quote':
      return getDefaultQuoteSubsteps();
    case 'discovery':
      return getDefaultDiscoverySubsteps();
    case 'contract':
      return getDefaultContractSubsteps();
    case 'onboarding':
      return getDefaultOnboardingSubsteps();
    default:
      return [];
  }
};
