
import { useState, useCallback, useEffect } from "react";
import { RateTier } from "@/utils/rates";
import { initializeRateCardData } from "./rateUtils";

interface UseRateInitializationProps {
  baseTier: RateTier;
  hasCustomRates: boolean;
  customRateCardData: Record<string, number>;
}

/**
 * Hook to handle rate card initialization and updating
 */
export const useRateInitialization = ({
  baseTier,
  hasCustomRates,
  customRateCardData
}: UseRateInitializationProps) => {
  // Initialize state
  const [rateCardData, setRateCardData] = useState<Record<string, number>>({});
  
  // Initialize rate card data by merging base rates with custom rates
  const initializeRates = useCallback(() => {
    const customRateCount = Object.keys(customRateCardData).length;
    
    console.log("useRateInitialization: Initializing rates with:", {
      baseTier,
      hasCustomRates,
      customRatesCount: customRateCount,
      customRateSample: customRateCount > 0 ?
        Object.entries(customRateCardData).slice(0, 5) :
        'No custom rates'
    });
    
    const rateData = initializeRateCardData(
      baseTier,
      hasCustomRates,
      customRateCardData
    );
    
    console.log("useRateInitialization: Final rateCardData after initialization:", {
      count: Object.keys(rateData).length,
      sample: Object.entries(rateData).slice(0, 5)
    });
    
    setRateCardData(rateData);
  }, [baseTier, hasCustomRates, customRateCardData]);
  
  // Reinitialize rates when baseTier or customRateCardData changes
  useEffect(() => {
    console.log("useRateInitialization: baseTier or customRates changed - reinitializing rates");
    initializeRates();
  }, [baseTier, initializeRates, customRateCardData]);
  
  return {
    rateCardData,
    initializeRates
  };
};
