
import React from "react";

interface ImagesErrorStateProps {
  error: string;
}

export const ImagesErrorState: React.FC<ImagesErrorStateProps> = ({ error }) => {
  return (
    <div className="p-4 border border-red-200 rounded-md bg-red-50 text-red-800">
      {error}
    </div>
  );
};
