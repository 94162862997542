
import { lazy } from "react";
import { Route, Navigate } from "react-router-dom";

// Eager load important components
import AdminSales from "@/pages/Admin/Sales";

// Lazy load admin components
const AdminPortal = lazy(() => import("@/pages/Admin/AdminPortal"));
const AdminOperations = lazy(() => import("@/pages/Admin/Operations"));
const AdminClientSuccess = lazy(() => import("@/pages/Admin/ClientSuccess"));
const AdminFinance = lazy(() => import("@/pages/Admin/Finance"));
const AdminCommercial = lazy(() => import("@/pages/Admin/Commercial"));
const AdminBusiness = lazy(() => import("@/pages/Admin/Business"));
const AdminClients = lazy(() => import("@/pages/Admin/Clients"));
const ClientDetails = lazy(() => import("@/pages/Admin/ClientDetails"));
const AdminOnboarding = lazy(() => import("@/pages/Admin/Onboarding"));
const AdminDock = lazy(() => import("@/pages/Admin/Dock"));
const AdminSupport = lazy(() => import("@/pages/Admin/Support"));
const ChangeManagement = lazy(() => import("@/pages/Admin/ChangeManagement"));
const AdminSettings = lazy(() => import("@/pages/Admin/Settings"));
const UserManagement = lazy(() => import("@/pages/Admin/UserManagement"));

export const AdminRoutes = (
  <Route path="/admin" element={<AdminPortal />}>
    <Route index element={<Navigate to="/admin/sales" replace />} />
    <Route path="operations" element={<AdminOperations />} />
    <Route path="sales" element={<AdminSales />} />
    <Route path="client-success" element={<AdminClientSuccess />} />
    <Route path="finance" element={<AdminFinance />} />
    <Route path="commercial" element={<AdminCommercial />} />
    <Route path="business" element={<AdminBusiness />} />
    <Route path="clients" element={<AdminClients />} />
    <Route path="clients/:clientId" element={<ClientDetails />} />
    <Route path="onboarding" element={<AdminOnboarding />} />
    <Route path="dock" element={<AdminDock />} />
    <Route path="support" element={<AdminSupport />} />
    <Route path="change-management" element={<ChangeManagement />} />
    <Route path="settings" element={<AdminSettings />} />
    <Route path="users" element={<UserManagement />} />
  </Route>
);
