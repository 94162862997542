
import { ChevronDown, ChevronUp } from "lucide-react";

interface StorageSectionHeaderProps {
  expanded: boolean;
  onToggle: () => void;
}

export const StorageSectionHeader = ({ expanded, onToggle }: StorageSectionHeaderProps) => {
  return (
    <div 
      className="flex justify-between items-center p-3 bg-gray-50 cursor-pointer"
      onClick={onToggle}
    >
      <h3 className="text-lg font-semibold">Storage</h3>
      {expanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
    </div>
  );
};
