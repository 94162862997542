
import { useSalesPage } from "./hooks/useSalesPage";
import { SalesPageContent } from "./components/SalesPageContent";

const AdminSales = () => {
  const {
    showArchived,
    setShowArchived,
    prospects,
    isLoading,
    handleToggleArchive,
    activeProspects,
    archivedProspects,
    sortField,
    sortDirection,
    handleSort,
    updateCalculatedMonthlyValue,
    calculatedMonthlyValues
  } = useSalesPage();

  return (
    <SalesPageContent
      activeProspects={activeProspects}
      archivedProspects={archivedProspects}
      prospects={prospects}
      isLoading={isLoading}
      showArchived={showArchived}
      onShowArchivedChange={setShowArchived}
      onToggleArchive={handleToggleArchive}
      sortField={sortField}
      sortDirection={sortDirection}
      onSort={handleSort}
      onUpdateMonthlyValue={updateCalculatedMonthlyValue}
      calculatedMonthlyValues={calculatedMonthlyValues}
    />
  );
};

export default AdminSales;
