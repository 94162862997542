
import React from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { MessageSquare } from "lucide-react";
import { EmptyChatState } from "./components/EmptyChatState";
import { formatChatConversation } from "./utils/chatFormatters";

interface DiscoveryChatProps {
  discoveryConversation: string | any;
}

export const DiscoveryChat: React.FC<DiscoveryChatProps> = ({ discoveryConversation }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg flex items-center">
          <MessageSquare className="h-5 w-5 mr-2 text-blue-500" />
          Discovery Chat History
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="max-h-[500px] overflow-y-auto p-4 border rounded-md bg-gray-50">
          {discoveryConversation ? (
            formatChatConversation(discoveryConversation)
          ) : (
            <EmptyChatState />
          )}
        </div>
      </CardContent>
    </Card>
  );
};
