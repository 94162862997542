
import React from "react";

interface ChatMessageProps {
  message: any;
  index: number;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ message, index }) => {
  const isAssistant = message.role === 'assistant';
  const timestamp = message.timestamp ? new Date(message.timestamp).toLocaleString() : '';
  
  return (
    <div key={index} className={`flex flex-col ${isAssistant ? 'items-start' : 'items-end'}`}>
      <div className={`max-w-[80%] rounded-lg p-3 ${isAssistant ? 'bg-blue-50' : 'bg-green-50'}`}>
        <div className="text-xs text-gray-500 mb-1">
          {isAssistant ? 'Assistant' : 'User'} {timestamp && `• ${timestamp}`}
        </div>
        <div className="text-sm whitespace-pre-wrap">{message.content}</div>
        {message.imagePath && (
          <div className="mt-2">
            <img 
              src={message.imagePath} 
              alt="Uploaded" 
              className="max-w-full h-auto rounded-md border border-gray-200" 
            />
          </div>
        )}
      </div>
    </div>
  );
};
