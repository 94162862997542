
import { DirectRateView } from "../DirectRateView";
import { ClientViewProps } from "./types";

export const ClientView = ({ quoteData, originalRateTier }: ClientViewProps) => {
  return (
    <DirectRateView 
      customRates={quoteData?.custom_rates || {}} 
      originalRateTier={originalRateTier}
    />
  );
};
