
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ChevronDown, ChevronUp } from "lucide-react";
import { formatCurrency } from "@/lib/utils";
import { RateCell } from "./RateCell";

export interface FulfillmentSectionProps {
  rateCardData: Record<string, number>;
  scopeData: any;
  expanded: boolean;
  quoteData?: any;
  onToggle: () => void;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified?: (key: string) => boolean;
  showAllRates?: boolean;
}

export const FulfillmentSection = ({
  rateCardData,
  scopeData,
  quoteData,
  expanded,
  onToggle,
  onRateChange,
  isCustomizing,
  isRateModified = () => false,
  showAllRates = false
}: FulfillmentSectionProps) => {
  // Only render section if there are fulfillment orders or showAllRates is true
  const hasFulfillmentOrders = 
    scopeData.parcelOrders > 0 || 
    scopeData.palletOrders > 0 || 
    scopeData.cartonOrders > 0 ||
    scopeData.fbaOrders > 0;  // Combined FBA orders
  
  if (!hasFulfillmentOrders && !showAllRates) {
    return null;
  }
  
  // Check for FBA specific volumes
  const hasFbaLtlPallets = scopeData.fbaLtlPallets > 0;
  const hasFbaParcelCartons = scopeData.fbaParcelCartons > 0;
  const hasFbaLabels = scopeData.fbaFnskuLabels > 0;
  const hasFbaCasePack = scopeData.fbaCasePack > 0;
  const hasAnyFba = hasFbaLtlPallets || hasFbaParcelCartons || hasFbaLabels || hasFbaCasePack;
  
  return (
    <div className="mb-6 border rounded-lg overflow-hidden">
      <div 
        className="flex justify-between items-center p-3 bg-gray-50 cursor-pointer"
        onClick={onToggle}
      >
        <h3 className="text-lg font-semibold">Fulfillment</h3>
        {expanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </div>
      
      {expanded && (
        <div className="overflow-x-auto p-3">
          {/* D2C Fulfillment Table */}
          {(showAllRates || scopeData.parcelOrders > 0) && (
            <div className="mb-6">
              <h4 className="text-md font-medium mb-2 pb-1 border-b border-gray-200">D2C Fulfillment</h4>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[50%]">Service</TableHead>
                    <TableHead>Rate</TableHead>
                    <TableHead>Quantity</TableHead>
                    <TableHead>Extended</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell className="font-medium">D2C Fulfillment (Base)</TableCell>
                    <RateCell 
                      fieldKey="pickPackD2CBase" 
                      rate={rateCardData.pickPackD2CBase || 0}
                      onChange={onRateChange}
                      isCustomizing={isCustomizing}
                      isModified={isRateModified("pickPackD2CBase")}
                    />
                    <TableCell>{scopeData.parcelOrders || 0}/mo</TableCell>
                    <TableCell>
                      {formatCurrency((rateCardData.pickPackD2CBase || 0) * (scopeData.parcelOrders || 0))}
                    </TableCell>
                  </TableRow>
                  
                  <TableRow>
                    <TableCell className="font-medium">D2C Per Unit Fee</TableCell>
                    <RateCell 
                      fieldKey="pickPackD2CPerUnit" 
                      rate={rateCardData.pickPackD2CPerUnit || 0}
                      onChange={onRateChange}
                      isCustomizing={isCustomizing}
                      isModified={isRateModified("pickPackD2CPerUnit")}
                    />
                    <TableCell>{(scopeData.parcelOrders || 0) * (scopeData.unitsPerParcel || 1)} units</TableCell>
                    <TableCell>
                      {formatCurrency((rateCardData.pickPackD2CPerUnit || 0) * (scopeData.parcelOrders || 0) * (scopeData.unitsPerParcel || 1))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
          
          {/* Amazon FBA Fulfillment Table */}
          {(showAllRates || hasAnyFba) && (
            <div className="mb-6">
              <h4 className="text-md font-medium mb-2 pb-1 border-b border-gray-200">Amazon FBA Fulfillment</h4>
              
              {/* FBA Shipping Section */}
              {(showAllRates || hasFbaLtlPallets || hasFbaParcelCartons) && (
                <div className="mb-4">
                  <h5 className="text-sm font-medium my-2 text-gray-600">FBA Shipping Methods</h5>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[50%]">Service</TableHead>
                        <TableHead>Rate</TableHead>
                        <TableHead>Quantity</TableHead>
                        <TableHead>Extended</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {/* FBA LTL pallet shipments */}
                      {(showAllRates || hasFbaLtlPallets) && (
                        <TableRow>
                          <TableCell className="font-medium">FBA LTL Pallet Shipments</TableCell>
                          <RateCell 
                            fieldKey="outboundPalletForwarding" 
                            rate={rateCardData.outboundPalletForwarding || 0}
                            onChange={onRateChange}
                            isCustomizing={isCustomizing}
                            isModified={isRateModified("outboundPalletForwarding")}
                          />
                          <TableCell>{scopeData.fbaLtlPallets || 0}/mo</TableCell>
                          <TableCell>
                            {formatCurrency((rateCardData.outboundPalletForwarding || 0) * (scopeData.fbaLtlPallets || 0))}
                          </TableCell>
                        </TableRow>
                      )}
                      
                      {/* FBA parcel carton shipments */}
                      {(showAllRates || hasFbaParcelCartons) && (
                        <TableRow>
                          <TableCell className="font-medium">FBA Parcel Carton Shipments</TableCell>
                          <RateCell 
                            fieldKey="outboundStandardCarton" 
                            rate={rateCardData.outboundStandardCarton || 0}
                            onChange={onRateChange}
                            isCustomizing={isCustomizing}
                            isModified={isRateModified("outboundStandardCarton")}
                          />
                          <TableCell>{scopeData.fbaParcelCartons || 0}/mo</TableCell>
                          <TableCell>
                            {formatCurrency((rateCardData.outboundStandardCarton || 0) * (scopeData.fbaParcelCartons || 0))}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              )}
              
              {/* FBA Value-Added Services Section */}
              {(showAllRates || hasFbaLabels || hasFbaCasePack) && (
                <div>
                  <h5 className="text-sm font-medium my-2 text-gray-600">FBA Value-Added Services</h5>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[50%]">Service</TableHead>
                        <TableHead>Rate</TableHead>
                        <TableHead>Quantity</TableHead>
                        <TableHead>Extended</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {/* FNSKU Labeling */}
                      {(showAllRates || hasFbaLabels) && (
                        <TableRow>
                          <TableCell className="font-medium">FNSKU Labeling</TableCell>
                          <RateCell 
                            fieldKey="labelingStickeringPerUnit" 
                            rate={rateCardData.labelingStickeringPerUnit || 0}
                            onChange={onRateChange}
                            isCustomizing={isCustomizing}
                            isModified={isRateModified("labelingStickeringPerUnit")}
                          />
                          <TableCell>{scopeData.fbaFnskuLabels || 0} units</TableCell>
                          <TableCell>
                            {formatCurrency((rateCardData.labelingStickeringPerUnit || 0) * (scopeData.fbaFnskuLabels || 0))}
                          </TableCell>
                        </TableRow>
                      )}
                      
                      {/* Case Packing */}
                      {(showAllRates || hasFbaCasePack) && (
                        <TableRow>
                          <TableCell className="font-medium">Case Packing</TableCell>
                          <RateCell 
                            fieldKey="pickPackB2BPerUnit" 
                            rate={rateCardData.pickPackB2BPerUnit || 0}
                            onChange={onRateChange}
                            isCustomizing={isCustomizing}
                            isModified={isRateModified("pickPackB2BPerUnit")}
                          />
                          <TableCell>{scopeData.fbaCasePack || 0} units</TableCell>
                          <TableCell>
                            {formatCurrency((rateCardData.pickPackB2BPerUnit || 0) * (scopeData.fbaCasePack || 0))}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              )}
            </div>
          )}
          
          {/* B2B Fulfillment Table */}
          {(showAllRates || scopeData.palletOrders > 0 || scopeData.cartonOrders > 0) && (
            <div>
              <h4 className="text-md font-medium mb-2 pb-1 border-b border-gray-200">B2B Fulfillment</h4>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[50%]">Service</TableHead>
                    <TableHead>Rate</TableHead>
                    <TableHead>Quantity</TableHead>
                    <TableHead>Extended</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {/* B2B Pallet Forwarding */}
                  {(showAllRates || scopeData.palletOrders > 0) && (
                    <TableRow>
                      <TableCell className="font-medium">B2B Pallet Forwarding</TableCell>
                      <RateCell 
                        fieldKey="outboundPalletForwarding" 
                        rate={rateCardData.outboundPalletForwarding || 0}
                        onChange={onRateChange}
                        isCustomizing={isCustomizing}
                        isModified={isRateModified("outboundPalletForwarding")}
                      />
                      <TableCell>{scopeData.palletOrders || 0}/mo</TableCell>
                      <TableCell>
                        {formatCurrency((rateCardData.outboundPalletForwarding || 0) * (scopeData.palletOrders || 0))}
                      </TableCell>
                    </TableRow>
                  )}
                  
                  {/* B2B Carton Fulfillment */}
                  {(showAllRates || scopeData.cartonOrders > 0) && (
                    <TableRow>
                      <TableCell className="font-medium">B2B Carton Fulfillment</TableCell>
                      <RateCell 
                        fieldKey="outboundStandardCarton" 
                        rate={rateCardData.outboundStandardCarton || 0}
                        onChange={onRateChange}
                        isCustomizing={isCustomizing}
                        isModified={isRateModified("outboundStandardCarton")}
                      />
                      <TableCell>{scopeData.cartonOrders || 0}/mo</TableCell>
                      <TableCell>
                        {formatCurrency((rateCardData.outboundStandardCarton || 0) * (scopeData.cartonOrders || 0))}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
