import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Map of old paths to new paths
const redirectMap: Record<string, string> = {
  // Main pages
  '/v2': '/',
  '/about': '/company/investors',
  '/what-people-are-saying': '/customers/why-thrive',
  '/3pl-houston': '/locations/houston',
  '/services': '/',
  '/industries-we-serve': '/customers/why-thrive',

  // Services that have equivalents
  '/order-fulfillment': '/fulfillment/dtc',
  '/ecommerce-fulfillment': '/fulfillment/dtc',
  '/3pl-warehouse': '/warehousing/storage',
  '/amazon-fba-shipment-receiving': '/warehousing/dock',
  '/quality-control-and-inspection': '/warehousing/dock',
  '/inventory-management': '/warehousing/inventory',
  '/real-time-tracking': '/technology/wms',
  '/cross-docking': '/warehousing/dock',
  '/shipment-consolidation-service': '/warehousing/dock',
  '/amazon-fulfillment': '/fulfillment/fba',
  '/e-commerce-platform-integration': '/technology/integrations',
  '/shopify-fulfillment': '/technology/integrations',
  '/tiktok-shop-fulfillment': '/technology/integrations',
  '/dedicated-management': '/technology/wms',
  '/enhanced-brand-content': '/fulfillment/fba',
  '/amazon-storefront': '/fulfillment/fba',
  '/e-commerce-platforms': '/technology/integrations',
  '/multichannel-fulfillment': '/technology/integrations',
  '/multichannel-listing-inventory': '/warehousing/inventory',
  '/branding-and-packaging': '/fulfillment/kitting',
  '/custom-kitting-services': '/fulfillment/kitting',
  '/product-inserts': '/fulfillment/kitting',
  '/subscription-boxes': '/fulfillment/kitting',
  '/remediation-and-custom-projects': '/fulfillment/special-projects',
  '/liquidation-and-disposal': '/fulfillment/returns',
  '/returns-and-removals': '/fulfillment/returns',

  // Services without direct equivalents
  '/amazon-ppc-management': '/',
  '/b2b-order-fulfillment-services': '/fulfillment/b2b',
  '/brand-audit': '/',
  '/brand-video': '/',
  '/influencer-management': '/',
  '/keyword-research': '/',
  '/listing-photography': '/',
  '/amazon-storefront-build-out': '/fulfillment/fba',

  // Legal and info pages
  '/acceptable-goods-policy': '/',
  '/terms-and-conditions': '/',
  '/faq': '/',
  '/contact': '/quote',
  '/thank-you': '/quote/thank-you',
  '/sitemap': '/',
};

export const RedirectsHandler: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Remove trailing slash for consistency
    const path = location.pathname.endsWith('/') 
      ? location.pathname.slice(0, -1) 
      : location.pathname;
    
    // Check if the current path needs to be redirected
    if (redirectMap[path]) {
      console.log(`Redirecting from ${path} to ${redirectMap[path]}`);
      navigate(redirectMap[path], { replace: true });
    }
  }, [location, navigate]);

  return null; // This component doesn't render anything
}; 