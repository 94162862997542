
// Add the formatDate function if it doesn't exist
export const formatDate = (dateString: string): string => {
  if (!dateString) return 'Not specified';
  
  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
};
