import { jsPDF } from "jspdf";
import { DatabaseQuote } from "@/pages/Quote/types";
import { formatDate } from "./formatters";
import { 
  getOrderedSalesChannels, 
  getFulfillmentRequirements, 
  getAmazonFBADetails, 
  getValueAddedServices, 
  getPackagingMaterials,
  getStorageTypes,
  getSpecialStorageRequirements,
  getInboundShipmentTypes
} from "./index";
import { processQuoteData } from "@/utils/quoteDataProcessor";
import { Prospect } from "../../../types";

// Function to generate a comprehensive PDF with all tabs content
export const generateComprehensivePDF = async (quote: DatabaseQuote | Prospect, companyName: string) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 20;
  const contentWidth = pageWidth - (margin * 2);
  
  // PDF Setup
  const title = `${companyName} - Quote Report`;
  const filename = `${companyName.replace(/\s+/g, '_')}_Quote_Report.pdf`;
  let yPos = margin;
  
  // Page header with company name and quote ID
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(title, margin, yPos);
  yPos += 8;
  
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(`Quote ID: ${quote.id}`, margin, yPos);
  yPos += 5;
  
  doc.text(`Generated: ${new Date().toLocaleDateString()}`, margin, yPos);
  yPos += 5;
  
  doc.text(`Status: ${(quote.quote_status || 'pending').replace(/_/g, ' ').toUpperCase()}`, margin, yPos);
  yPos += 15;
  
  // Table of Contents
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text("Contents", margin, yPos);
  yPos += 10;
  
  const sections = [
    "Contact Information",
    "Business Profile",
    "Storage Requirements",
    "Receiving Requirements",
    "Fulfillment Requirements",
    "Research Information",
    "Budgetary Quote"
  ];
  
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  sections.forEach((section, index) => {
    doc.text(`${index + 1}. ${section}`, margin, yPos);
    yPos += 6;
  });
  
  // Add a new page after TOC
  doc.addPage();
  yPos = margin;
  
  // 1. Contact Information Section
  yPos = addSectionHeader(doc, "1. Contact Information", margin, yPos);
  
  const contactInfo = [
    [`Name: ${quote.first_name} ${quote.last_name}`, `Company: ${companyName}`],
    [`Email: ${quote.email}`, `Phone: ${quote.phone || 'Not provided'}`]
  ];
  
  if (quote.website) {
    contactInfo.push([`Website: ${quote.website}`, ""]);
  }
  
  yPos = addMultiColumnContent(doc, contactInfo, margin, yPos, contentWidth);
  yPos += 10;
  
  // Add timestamp info
  doc.setFontSize(9);
  doc.setTextColor(100, 100, 100);
  doc.text(`Submitted: ${formatDate(quote.created_at)}`, margin, yPos);
  
  // Only show last_modified_at if it exists and is different from created_at
  if ('last_modified_at' in quote && quote.last_modified_at && quote.last_modified_at !== quote.created_at) {
    yPos += 5;
    doc.text(`Last Updated: ${formatDate(quote.last_modified_at)}`, margin, yPos);
  }
  
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  yPos += 15;
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // 2. Business Profile Section
  yPos = addSectionHeader(doc, "2. Business Profile", margin, yPos);
  
  const businessIdentity = quote.business_identity === 'dtc' ? 'Direct-to-Consumer Brand' : 
                         quote.business_identity === 'retail' ? 'Retail Brand' : 
                         quote.business_identity === 'wholesale' ? 'Wholesale Business' : 
                         quote.business_identity === 'marketplace' ? 'Marketplace Seller' : 
                         quote.business_identity === 'logistics' ? 'Inventory Storage & Logistics' : 
                         quote.business_identity || 'Not specified';
  
  const businessInfo = [
    [`Business Identity: ${businessIdentity}`, `Company Size: ${quote.company_size || 'Not specified'}`],
    [`Annual Revenue: ${quote.annual_revenue || 'Not specified'}`, `Total SKUs: ${quote.total_skus || 'Not specified'}`]
  ];
  
  yPos = addMultiColumnContent(doc, businessInfo, margin, yPos, contentWidth);
  
  // Sales Channels
  if (quote.sales_channels && Object.keys(quote.sales_channels).length > 0) {
    yPos += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Sales Channels:", margin, yPos);
    yPos += 7;
    
    const orderedChannels = getOrderedSalesChannels(quote);
    for (const [key, value] of orderedChannels) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${value.name}: ${value.percentage}%`, margin + 5, yPos);
      yPos += 6;
    }
  }
  
  // Fulfillment Priorities
  if (Array.isArray(quote.fulfillment_priorities) && quote.fulfillment_priorities.length > 0) {
    yPos += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Fulfillment Priorities:", margin, yPos);
    yPos += 7;
    
    for (const priority of quote.fulfillment_priorities) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${priority}`, margin + 5, yPos);
      yPos += 6;
    }
  }
  
  // Product Description
  if (quote.product_description) {
    yPos += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Product Catalog Description:", margin, yPos);
    yPos += 7;
    
    doc.setFont("helvetica", "normal");
    const textLines = doc.splitTextToSize(quote.product_description, contentWidth - 10);
    doc.text(textLines, margin + 5, yPos);
    yPos += textLines.length * 5 + 5;
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // 3. Storage Requirements Section
  yPos = addSectionHeader(doc, "3. Storage Requirements", margin, yPos);
  
  const storageTypes = getStorageTypes(quote);
  if (storageTypes.length > 0) {
    doc.setFont("helvetica", "bold");
    doc.text("Storage Types:", margin, yPos);
    yPos += 7;
    
    for (const item of storageTypes) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${item.type}: ${item.positions} positions${item.details ? ` (${item.details})` : ''}`, margin + 5, yPos);
      yPos += 6;
    }
  }
  
  // Special Storage Requirements
  const specialRequirements = getSpecialStorageRequirements(quote);
  if (specialRequirements.length > 0) {
    yPos += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Special Storage Requirements:", margin, yPos);
    yPos += 7;
    
    for (const requirement of specialRequirements) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${requirement}`, margin + 5, yPos);
      yPos += 6;
    }
  }
  
  // Additional Storage Context
  if (quote.storage_additional_context) {
    yPos += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Additional Storage Context:", margin, yPos);
    yPos += 7;
    
    doc.setFont("helvetica", "normal");
    const textLines = doc.splitTextToSize(quote.storage_additional_context, contentWidth - 10);
    doc.text(textLines, margin + 5, yPos);
    yPos += textLines.length * 5 + 5;
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // 4. Receiving Requirements Section
  yPos = addSectionHeader(doc, "4. Receiving Requirements", margin, yPos);
  
  // SKU Mix
  doc.setFont("helvetica", "normal");
  doc.text(`SKU Mix: ${quote.sku_mix ? quote.sku_mix.charAt(0).toUpperCase() + quote.sku_mix.slice(1) : 'Not specified'}`, margin, yPos);
  yPos += 10;
  
  // Inbound Shipment Types
  const inboundTypes = getInboundShipmentTypes(quote);
  if (inboundTypes.length > 0) {
    doc.setFont("helvetica", "bold");
    doc.text("Inbound Shipment Types:", margin, yPos);
    yPos += 7;
    
    for (const item of inboundTypes) {
      const loadingType = item.loadingType === 'floor' ? 'Floor Loaded' : 
                          item.loadingType === 'palletized' ? 'Palletized' : 
                          item.loadingType === 'mixed' ? 'Mixed Loading' : 
                          item.loadingType;
      
      doc.setFont("helvetica", "normal");
      doc.text(`• ${item.type}: ${loadingType}, ${item.volume ? `${item.volume}/month` : '0/month'}`, margin + 5, yPos);
      yPos += 6;
    }
    yPos += 5;
  }
  
  // Expected Start Date
  if (quote.expected_start_date) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Expected Start Date:", margin, yPos);
    yPos += 7;
    
    doc.setFont("helvetica", "normal");
    let startDate = "Not specified";
    try {
      startDate = new Date(quote.expected_start_date).toLocaleDateString();
    } catch (e) {
      console.error("Error formatting start date:", e);
    }
    doc.text(startDate, margin + 5, yPos);
    yPos += 10;
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // 5. Fulfillment Requirements Section
  yPos = addSectionHeader(doc, "5. Fulfillment Requirements", margin, yPos);
  
  // Fulfillment Types
  const fulfillmentReqs = getFulfillmentRequirements(quote);
  if (fulfillmentReqs.length > 0) {
    for (const item of fulfillmentReqs) {
      doc.setFont("helvetica", "bold");
      doc.text(item.type, margin, yPos);
      yPos += 7;
      
      doc.setFont("helvetica", "normal");
      doc.text(`Volume: ${item.volume}/month`, margin + 5, yPos);
      yPos += 6;
      
      if (item.details) {
        const textLines = doc.splitTextToSize(item.details, contentWidth - 10);
        doc.text(textLines, margin + 5, yPos);
        yPos += textLines.length * 5;
      }
      
      yPos += 5;
    }
  }
  
  // Value Added Services
  const valueAddedServices = getValueAddedServices(quote);
  if (valueAddedServices.length > 0) {
    doc.setFont("helvetica", "bold");
    doc.text("Value Added Services:", margin, yPos);
    yPos += 7;
    
    for (const service of valueAddedServices) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${service.name}${service.details ? `: ${service.details}` : ''}`, margin + 5, yPos);
      yPos += service.details ? 10 : 6;
    }
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // 6. Research Information Section
  yPos = addSectionHeader(doc, "6. Research Information", margin, yPos);
  
  // @ts-ignore - discovery_summary may not be in the type but could be in the data
  if (quote.discovery_summary) {
    doc.setFont("helvetica", "normal");
    // @ts-ignore
    const textLines = doc.splitTextToSize(quote.discovery_summary, contentWidth - 10);
    doc.text(textLines, margin + 5, yPos);
    yPos += textLines.length * 5 + 10;
    
    // Add research timestamp if available
    if (quote.research_completed_at) {
      doc.setFontSize(8);
      doc.setTextColor(100, 100, 100);
      doc.text(`Research updated: ${formatDate(quote.research_completed_at)}`, margin + 5, yPos);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      yPos += 10;
    }
  } else {
    doc.setFont("helvetica", "italic");
    doc.text("No research data available for this quote.", margin + 5, yPos);
    yPos += 10;
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // 7. Budgetary Quote Section
  yPos = addSectionHeader(doc, "7. Budgetary Quote", margin, yPos);
  
  if (quote.approved_rate_tier) {
    doc.text(`Approved Rate Tier: ${quote.approved_rate_tier}`, margin, yPos);
    yPos += 7;
  }
  
  // Get annual value estimate if available
  if (quote.custom_rates || quote.approved_rate_tier) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Estimated Annual Value:", margin, yPos);
    yPos += 7;
    
    // Use estimated_monthly_cost instead of monthly_revenue
    let annualValue = "Contact for details";
    if (quote.estimated_monthly_cost) {
      annualValue = `$${(Number(quote.estimated_monthly_cost) * 12).toLocaleString()}`;
    }
    
    doc.setFont("helvetica", "normal");
    doc.text(annualValue, margin + 5, yPos);
    yPos += 10;
  }
  
  // Admin Notes (if any)
  if (quote.admin_notes) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Additional Notes:", margin, yPos);
    yPos += 7;
    
    doc.setFont("helvetica", "normal");
    const textLines = doc.splitTextToSize(quote.admin_notes, contentWidth);
    doc.text(textLines, margin + 5, yPos);
  }
  
  // Add Footer to all pages
  const pageCount = doc.getNumberOfPages();
  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    
    // Footer text
    doc.text(
      `${companyName} - Quote Report (Page ${i} of ${pageCount})`,
      pageWidth / 2,
      doc.internal.pageSize.getHeight() - 10,
      { align: "center" }
    );
  }
  
  // Save the PDF
  doc.save(filename);
  return filename;
};

// Helper function to add a section header
const addSectionHeader = (doc: jsPDF, title: string, marginX: number, yPos: number): number => {
  // Add some space before the section
  yPos += 10;
  
  // Draw a light gray background
  doc.setFillColor(240, 240, 240);
  doc.rect(marginX - 5, yPos - 5, doc.internal.pageSize.getWidth() - (marginX * 2) + 10, 10, 'F');
  
  // Add the section title
  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(title, marginX, yPos);
  
  // Return the new Y position
  return yPos + 10;
};

// Helper function to add multi-column content
const addMultiColumnContent = (
  doc: jsPDF, 
  content: string[][], 
  marginX: number, 
  yPos: number, 
  contentWidth: number
): number => {
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  
  let currentY = yPos;
  const columnWidth = contentWidth / 2;
  
  for (const row of content) {
    if (row.length === 2) {
      doc.text(row[0], marginX, currentY);
      doc.text(row[1], marginX + columnWidth, currentY);
    } else {
      doc.text(row[0], marginX, currentY);
    }
    currentY += 5;
  }
  
  return currentY;
};

// Create a PDF document with proper formatting
export const generateDetailedQuotePDF = async (quote: DatabaseQuote) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 20;
  const contentWidth = pageWidth - (margin * 2);
  
  // Process the data
  const { processedQuoteRequest, typedQuoteData } = processQuoteData(quote);
  
  // PDF Setup
  const title = `${quote.company} - Quote Details`;
  const filename = `${quote.company.replace(/\s+/g, '_')}_Quote_${quote.id}.pdf`;
  
  // Configure styling
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.text(title, margin, margin);
  
  // Add quote ID and date
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(`Quote ID: ${quote.id}`, margin, margin + 10);
  doc.text(`Date: ${formatDate(quote.created_at)}`, margin, margin + 15);
  doc.text(`Status: ${quote.quote_status.replace(/_/g, ' ').toUpperCase()}`, margin, margin + 20);
  
  let yPos = margin + 30;
  
  // Contact Section
  yPos = addSectionHeader(doc, "Contact Information", margin, yPos);
  
  const contactInfo = [
    [`Name: ${quote.first_name} ${quote.last_name}`, `Company: ${quote.company}`],
    [`Email: ${quote.email}`, `Phone: ${quote.phone || 'Not provided'}`]
  ];
  
  if (quote.website) {
    contactInfo.push([`Website: ${quote.website}`, ""]);
  }
  
  yPos = addMultiColumnContent(doc, contactInfo, margin, yPos, contentWidth);
  yPos += 10;
  
  // Business Section
  yPos = addSectionHeader(doc, "Business Profile", margin, yPos);
  
  const businessIdentity = quote.business_identity === 'dtc' ? 'Direct-to-Consumer Brand' : 
                         quote.business_identity === 'retail' ? 'Retail Brand' : 
                         quote.business_identity === 'wholesale' ? 'Wholesale Business' : 
                         quote.business_identity === 'marketplace' ? 'Marketplace Seller' : 
                         quote.business_identity === 'logistics' ? 'Inventory Storage & Logistics' : 
                         quote.business_identity || 'Not specified';
  
  const businessInfo = [
    [`Business Identity: ${businessIdentity}`, `Company Size: ${quote.company_size || 'Not specified'}`],
    [`Annual Revenue: ${quote.annual_revenue || 'Not specified'}`, `Total SKUs: ${quote.total_skus || 'Not specified'}`]
  ];
  
  yPos = addMultiColumnContent(doc, businessInfo, margin, yPos, contentWidth);
  
  // Sales Channels
  if (quote.sales_channels && Object.keys(quote.sales_channels).length > 0) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Sales Channels:", margin, yPos);
    yPos += 5;
    
    const orderedChannels = getOrderedSalesChannels(quote);
    for (const [key, value] of orderedChannels) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${value.name}: ${value.percentage}%`, margin + 5, yPos);
      yPos += 5;
    }
  }
  
  // Fulfillment Priorities
  if (Array.isArray(quote.fulfillment_priorities) && quote.fulfillment_priorities.length > 0) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Fulfillment Priorities:", margin, yPos);
    yPos += 5;
    
    for (const priority of quote.fulfillment_priorities) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${priority}`, margin + 5, yPos);
      yPos += 5;
    }
  }
  
  // Product Description
  if (quote.product_description) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Product Catalog Description:", margin, yPos);
    yPos += 5;
    
    doc.setFont("helvetica", "normal");
    const textLines = doc.splitTextToSize(quote.product_description, contentWidth);
    doc.text(textLines, margin + 5, yPos);
    yPos += textLines.length * 5 + 5;
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // Storage Section
  yPos = addSectionHeader(doc, "Storage Requirements", margin, yPos);
  
  const storageTypes = getStorageTypes(quote);
  if (storageTypes.length > 0) {
    doc.setFont("helvetica", "bold");
    doc.text("Storage Types:", margin, yPos);
    yPos += 5;
    
    for (const item of storageTypes) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${item.type}: ${item.positions} positions${item.details ? ` (${item.details})` : ''}`, margin + 5, yPos);
      yPos += 5;
    }
  }
  
  // Special Storage Requirements
  const specialRequirements = getSpecialStorageRequirements(quote);
  if (specialRequirements.length > 0) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Special Storage Requirements:", margin, yPos);
    yPos += 5;
    
    for (const requirement of specialRequirements) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${requirement}`, margin + 5, yPos);
      yPos += 5;
    }
  }
  
  // Additional Storage Context
  if (quote.storage_additional_context) {
    yPos += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Additional Storage Context:", margin, yPos);
    yPos += 5;
    
    doc.setFont("helvetica", "normal");
    const textLines = doc.splitTextToSize(quote.storage_additional_context, contentWidth);
    doc.text(textLines, margin + 5, yPos);
    yPos += textLines.length * 5 + 5;
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // Receiving Section
  yPos = addSectionHeader(doc, "Receiving Requirements", margin, yPos);
  
  // SKU Mix
  doc.setFont("helvetica", "normal");
  doc.text(`SKU Mix: ${quote.sku_mix ? quote.sku_mix.charAt(0).toUpperCase() + quote.sku_mix.slice(1) : 'Not specified'}`, margin, yPos);
  yPos += 10;
  
  // Inbound Shipment Types
  const inboundTypes = getInboundShipmentTypes(quote);
  if (inboundTypes.length > 0) {
    doc.setFont("helvetica", "bold");
    doc.text("Inbound Shipment Types:", margin, yPos);
    yPos += 5;
    
    for (const item of inboundTypes) {
      const loadingType = item.loadingType === 'floor' ? 'Floor Loaded' : 
                          item.loadingType === 'palletized' ? 'Palletized' : 
                          item.loadingType === 'mixed' ? 'Mixed Loading' : 
                          item.loadingType;
      
      doc.setFont("helvetica", "normal");
      doc.text(`• ${item.type}: ${loadingType}, ${item.volume ? `${item.volume}/month` : '0/month'}`, margin + 5, yPos);
      yPos += 5;
    }
    yPos += 5;
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // Fulfillment Section
  yPos = addSectionHeader(doc, "Fulfillment Requirements", margin, yPos);
  
  // Fulfillment Types
  const fulfillmentReqs = getFulfillmentRequirements(quote);
  if (fulfillmentReqs.length > 0) {
    for (const item of fulfillmentReqs) {
      doc.setFont("helvetica", "bold");
      doc.text(item.type, margin, yPos);
      yPos += 5;
      
      doc.setFont("helvetica", "normal");
      doc.text(`Volume: ${item.volume}/month`, margin + 5, yPos);
      yPos += 5;
      
      if (item.details) {
        const textLines = doc.splitTextToSize(item.details, contentWidth - 10);
        doc.text(textLines, margin + 5, yPos);
        yPos += textLines.length * 5;
      }
      
      yPos += 5;
    }
  }
  
  // Value Added Services
  const valueAddedServices = getValueAddedServices(quote);
  if (valueAddedServices.length > 0) {
    doc.setFont("helvetica", "bold");
    doc.text("Value Added Services:", margin, yPos);
    yPos += 5;
    
    for (const service of valueAddedServices) {
      doc.setFont("helvetica", "normal");
      doc.text(`• ${service.name}${service.details ? `: ${service.details}` : ''}`, margin + 5, yPos);
      yPos += service.details ? 10 : 5;
    }
  }
  
  // Check if we need a page break
  if (yPos > 250) {
    doc.addPage();
    yPos = margin;
  }
  
  // Budgetary Quote Section
  yPos = addSectionHeader(doc, "Budgetary Quote", margin, yPos);
  
  if (quote.approved_rate_tier) {
    doc.text(`Approved Rate Tier: ${quote.approved_rate_tier}`, margin, yPos);
    yPos += 5;
  }
  
  // Admin Notes (if any)
  if (quote.admin_notes) {
    yPos += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Admin Notes:", margin, yPos);
    yPos += 5;
    
    doc.setFont("helvetica", "normal");
    const textLines = doc.splitTextToSize(quote.admin_notes, contentWidth);
    doc.text(textLines, margin + 5, yPos);
  }
  
  // Save the PDF
  doc.save(filename);
  return filename;
};
