
import { supabase } from "@/integrations/supabase/client";

// Save custom rates to database
export const saveCustomRatesToDB = async (
  quoteId: string,
  customRateCardData: Record<string, number>,
  originalRateTier?: string
): Promise<{ success: boolean; error?: any }> => {
  try {
    // Prepare update data - now includes original_rate_tier when available
    const updateData: any = {
      custom_rates: customRateCardData,
      approved_rate_tier: 'CUSTOM'
    };
    
    // If an original rate tier is provided, save it to the database
    if (originalRateTier) {
      updateData.original_rate_tier = originalRateTier;
    }
    
    const { error } = await supabase
      .from('detailed_quote_requests')
      .update(updateData)
      .eq('id', quoteId);
      
    if (error) {
      return { success: false, error };
    }
    
    return { success: true };
  } catch (err) {
    return { success: false, error: err };
  }
};
