
import { useEffect } from "react";
import { DisplayRateTier } from "@/utils/rates";
import { useCustomRates } from "./useCustomRates";
import { useBaseTier } from "./useBaseTier";
import { useRateManagement } from "./useRateManagement";
import { useRateInitialization } from "./useRateInitialization";

interface UseRateCardDataProps {
  approvedRateTier: DisplayRateTier;
  quoteData?: any;
  quoteId?: string;
  onCustomRatesDetected?: () => void;
}

/**
 * Main hook for managing rate card data, combining functionality from specialized hooks
 */
export const useRateCardData = ({
  approvedRateTier,
  quoteData,
  quoteId,
  onCustomRatesDetected
}: UseRateCardDataProps) => {
  console.log("useRateCardData: Initializing with approvedRateTier =", approvedRateTier, 
    "quoteId =", quoteId);
  
  // Use our specialized hooks for better organization and separation of concerns
  const { baseTier, setBaseTier } = useBaseTier({ approvedRateTier, quoteData });
  
  const { 
    hasCustomRates, 
    customRateCardData, 
    modifiedRates, 
    customRatesStorageKey, 
    setCustomRateCardData,
    setModifiedRates,
    setHasCustomRates,
    isRateModified 
  } = useCustomRates({ quoteData, quoteId, approvedRateTier, onCustomRatesDetected });
  
  const { rateCardData, initializeRates } = useRateInitialization({
    baseTier,
    hasCustomRates,
    customRateCardData
  });
  
  const { handleRateChange, clearCustomRates } = useRateManagement({
    customRatesStorageKey,
    customRateCardData,
    setCustomRateCardData,
    setModifiedRates,
    setHasCustomRates,
    onCustomRatesDetected
  });
  
  // Debug log the initial state
  useEffect(() => {
    const customRateCount = Object.keys(customRateCardData).length;
    
    console.log("useRateCardData: Initial state:", {
      approvedRateTier,
      hasCustomRates,
      baseTier,
      customRatesCount: customRateCount,
      customRateSample: customRateCount > 0 ? 
        Object.entries(customRateCardData).slice(0, 3) : 
        'No custom rates'
    });
    
    // If we have custom rates, notify parent immediately
    if (customRateCount > 0 && onCustomRatesDetected) {
      console.log("useRateCardData: Detected custom rates on initialization, notifying parent");
      onCustomRatesDetected();
    }
  }, []);
  
  // Return the combined state and handlers from all hooks
  return {
    rateCardData,
    hasCustomRates,
    modifiedRates,
    baseTier,
    customRateCardData,
    customRatesStorageKey,
    isRateModified,
    handleRateChange,
    initializeRates,
    setBaseTier,
    clearCustomRates
  };
};
