
import { useState, useEffect } from "react";
import { calculateQuoteDetails, extractScopeData } from "@/pages/Admin/Sales/components/QuoteCalculator/utils";
import { getDefaultScopeData } from "@/pages/Admin/Sales/components/QuoteCalculator/utils/types/scopeTypes";
import { DisplayRateTier, RateTier } from "@/utils/rates";

interface UseQuoteCalculationsProps {
  quoteData?: any;
  approvedRateTier: DisplayRateTier;
  baseTier: RateTier;
  rateCardData: Record<string, number>;
  isCustomizing?: boolean;
  onMonthlyTotalChange?: (monthlyTotal: number) => void;
}

export const useQuoteCalculations = ({
  quoteData,
  approvedRateTier,
  baseTier,
  rateCardData,
  isCustomizing = false,
  onMonthlyTotalChange
}: UseQuoteCalculationsProps) => {
  // State for calculation results
  const [scopeData, setScopeData] = useState(getDefaultScopeData());
  const [quoteDetails, setQuoteDetails] = useState<any>(null);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [setupTotal, setSetupTotal] = useState(0);
  
  // Calculate quote details based on scope data and rates
  useEffect(() => {
    if (quoteData) {
      const extractedScopeData = extractScopeData(quoteData);
      
      // Determine which rates to use for calculations
      let calculationTier = approvedRateTier as RateTier;
      let useCustomRates = false;
      
      // If approved tier is CUSTOM, use the original_rate_tier (or baseTier if not available) with customRates overlay
      if (approvedRateTier === "CUSTOM") {
        calculationTier = quoteData.original_rate_tier || baseTier;
        useCustomRates = true;
      }
      
      // When customizing, always use the current rateCardData
      if (isCustomizing) {
        useCustomRates = true;
      }
      
      // For custom calculations, use the rateCardData which includes our modifications
      // For non-custom calculations, use the standard tier rates
      const details = calculateQuoteDetails(
        calculationTier, 
        {
          ...extractedScopeData,
          totalSkus: quoteData.total_skus || 50
        },
        useCustomRates ? (isCustomizing ? rateCardData : quoteData.custom_rates) : null
      );
      
      if (details) {
        // Update state with the calculated details
        setQuoteDetails(details);
        setMonthlyTotal(details.monthly.total);
        setSetupTotal(details.setup.total);
        setScopeData(extractedScopeData);
        
        // Emit monthly total change if callback is provided
        if (onMonthlyTotalChange && details.monthly.total) {
          onMonthlyTotalChange(details.monthly.total);
        }
      }
    }
  }, [quoteData, approvedRateTier, baseTier, rateCardData, isCustomizing, onMonthlyTotalChange]);
  
  return {
    scopeData,
    quoteDetails,
    monthlyTotal,
    setupTotal
  };
};
