
import { ScopeData } from '../types/scopeTypes';

/**
 * Calculates fulfillment costs based on scope data and rate data
 */
export const calculateFulfillmentCosts = (scopeData: ScopeData, rateData: Record<string, number>): number => {
  // D2C (Parcel) Costs - use unitsPerParcel instead of assuming 1 unit per order
  const pickPackD2CBaseCost = (rateData.pickPackD2CBase || 0) * scopeData.parcelOrders;
  const pickPackD2CPerUnitCost = (rateData.pickPackD2CPerUnit || 0) * scopeData.parcelOrders * (scopeData.unitsPerParcel || 1);
  
  // FBA Costs - no longer relying on unitsPerOrder
  // Use actual FBA orders count for base costs
  const fbaBaseCost = (rateData.pickPackD2CBase || 0) * scopeData.fbaOrders;
  
  // Use actual label and case pack counts for unit-based costs
  const fbaLabelingCost = (rateData.labelingStickeringPerUnit || 0) * scopeData.fbaFnskuLabels;
  const fbaCasePackingCost = (rateData.pickPackB2BPerUnit || 0) * scopeData.fbaCasePack;
  
  // B2B Costs
  const outboundPalletForwardingCost = (rateData.outboundPalletForwarding || 0) * scopeData.palletOrders;
  const outboundStandardCartonCost = (rateData.outboundStandardCarton || 0) * scopeData.cartonOrders;

  // Value-added service costs (kitting, returns, labeling)
  let valueAddedServicesCost = 0;
  
  if (scopeData.hasKitting) {
    valueAddedServicesCost += (rateData.kittingSetupFee || 0);
  }
  
  if (scopeData.hasReturns) {
    valueAddedServicesCost += (rateData.returnProcessingBase || 0) * (scopeData.parcelOrders * 0.05); // Assume 5% return rate
  }
  
  if (scopeData.hasLabeling) {
    valueAddedServicesCost += (rateData.labelingStickeringSetup || 0);
  }

  return pickPackD2CBaseCost + pickPackD2CPerUnitCost + 
         fbaBaseCost + fbaLabelingCost + fbaCasePackingCost +
         outboundPalletForwardingCost + outboundStandardCartonCost + 
         valueAddedServicesCost;
};
