
import { useState, useCallback, useEffect } from "react";
import { useProspects } from "./useProspects";
import { toast } from "@/hooks/use-toast";
import { SortDirection, SortField } from "../types";
import { getSortedProspects } from "../utils/prospectUtils";

export const useSalesPage = () => {
  const [showArchived, setShowArchived] = useState(false);
  const [sortField, setSortField] = useState<SortField>('last_modified_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [calculatedMonthlyValues, setCalculatedMonthlyValues] = useState<Record<string, number>>({});
  
  const {
    prospects,
    isLoading,
    toggleArchiveMutation,
    updateQuoteMutation
  } = useProspects(showArchived, sortField, sortDirection);

  // Remove logging to prevent triggering unnecessary renders
  useEffect(() => {
    // Intentionally empty to ensure clean mount behavior
  }, []);

  const handleToggleArchive = useCallback((prospectId: string, currentArchived: boolean) => {
    toggleArchiveMutation.mutate(
      { prospectId, archived: !currentArchived },
      {
        onSuccess: () => {
          const actionText = !currentArchived ? "archived" : "unarchived";
          toast({
            title: `Prospect ${actionText}`,
            description: `The prospect has been ${actionText} successfully.`,
            variant: "default"
          });
        },
        onError: (error) => {
          console.error("Error in toggleArchiveMutation:", error);
          toast({
            title: "Error",
            description: "Failed to update prospect status. Please try again.",
            variant: "destructive"
          });
        }
      }
    );
  }, [toggleArchiveMutation]);

  const handleSort = useCallback((field: SortField) => {
    // If clicking the same field, toggle direction
    if (field === sortField) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking a new field, set it as the sort field and default to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  }, [sortField]);

  // Track calculated monthly values from prospect rows
  const updateCalculatedMonthlyValue = useCallback((prospectId: string, monthlyValue: number) => {
    setCalculatedMonthlyValues(prev => ({
      ...prev,
      [prospectId]: monthlyValue
    }));
  }, []);

  // Apply sorting to prospects
  const sortedProspects = prospects ? 
    getSortedProspects(prospects, sortField, sortDirection, calculatedMonthlyValues) : 
    [];

  const activeProspects = sortedProspects.filter(p => !p.archived) || [];
  const archivedProspects = sortedProspects.filter(p => p.archived) || [];

  return {
    showArchived,
    setShowArchived,
    prospects: sortedProspects,
    isLoading,
    handleToggleArchive,
    activeProspects,
    archivedProspects,
    sortField,
    sortDirection,
    handleSort,
    updateCalculatedMonthlyValue,
    calculatedMonthlyValues
  };
};
