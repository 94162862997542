
import { TableCell, TableRow } from "@/components/ui/table";

interface PalletConfigurationInfoProps {
  loadingTypes: Record<string, string>;
  palletCounts: Record<string, number>;
}

export const PalletConfigurationInfo = ({ loadingTypes, palletCounts }: PalletConfigurationInfoProps) => {
  const hasPalletizedLoading = 
    loadingTypes?.container20 === "palletized" && palletCounts?.container20 > 0 ||
    loadingTypes?.container40 === "palletized" && palletCounts?.container40 > 0 ||
    loadingTypes?.ftl === "palletized" && palletCounts?.ftl > 0;
  
  if (!hasPalletizedLoading) {
    return null;
  }

  return (
    <TableRow className="bg-gray-50">
      <TableCell colSpan={4} className="py-2">
        <div className="text-sm text-gray-600 font-medium">Pallet Configuration:</div>
        <ul className="list-disc pl-5 text-sm mt-1 space-y-1">
          {loadingTypes?.container20 === "palletized" && palletCounts?.container20 > 0 && (
            <li>20ft Containers: {palletCounts.container20} pallets per container</li>
          )}
          {loadingTypes?.container40 === "palletized" && palletCounts?.container40 > 0 && (
            <li>40ft Containers: {palletCounts.container40} pallets per container</li>
          )}
          {loadingTypes?.ftl === "palletized" && palletCounts?.ftl > 0 && (
            <li>FTL Shipments: {palletCounts.ftl} pallets per truckload</li>
          )}
        </ul>
      </TableCell>
    </TableRow>
  );
};
