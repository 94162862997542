
import { NavigationMenuContent, NavigationMenuLink } from "@/components/ui/navigation-menu";
import { Link } from "react-router-dom";
import { MapPin } from "lucide-react";

export const LocationsMenu = () => {
  return (
    <NavigationMenuContent>
      <ul className="grid w-[400px] gap-3 p-4">
        <li>
          <NavigationMenuLink asChild>
            <Link
              to="/locations/houston"
              className="flex items-center space-x-2 select-none rounded-md p-3 no-underline outline-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            >
              <MapPin className="h-4 w-4" />
              <span>Houston</span>
            </Link>
          </NavigationMenuLink>
        </li>
        <li className="flex items-center space-x-2 select-none rounded-md p-3 text-muted-foreground">
          <MapPin className="h-4 w-4" />
          <span>Chicago (coming soon)</span>
        </li>
        <li className="flex items-center space-x-2 select-none rounded-md p-3 text-muted-foreground">
          <MapPin className="h-4 w-4" />
          <span>Nashville (coming soon)</span>
        </li>
      </ul>
    </NavigationMenuContent>
  );
};
