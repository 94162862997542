
import { TableCell, TableRow } from "@/components/ui/table";
import { formatCurrency } from "@/lib/utils";
import { RateCell } from "../RateCell";

interface PalletStorageRowProps {
  fieldKey: 'storageFirst100Pallets' | 'storageAdditionalPallets';
  label: string;
  rate: number;
  quantity: number;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isModified: boolean;
}

export const PalletStorageRow = ({
  fieldKey,
  label,
  rate,
  quantity,
  onRateChange,
  isCustomizing,
  isModified
}: PalletStorageRowProps) => {
  return (
    <TableRow>
      <TableCell className="font-medium">{label}</TableCell>
      <RateCell 
        fieldKey={fieldKey} 
        rate={rate || 0}
        onChange={onRateChange}
        isCustomizing={isCustomizing}
        isModified={isModified}
      />
      <TableCell>{quantity}</TableCell>
      <TableCell>
        {formatCurrency((rate || 0) * quantity)}
      </TableCell>
    </TableRow>
  );
};
