
import React from "react";
import { Card, CardHeader, CardContent, CardTitle, CardDescription } from "@/components/ui/card";
import { Images } from "lucide-react";
import { useImageGallery } from "./hooks/useImageGallery";
import { ImageCard } from "./components/ImageCard";
import { ImageDetailDialog } from "./components/ImageDetailDialog";
import { EmptyImagesState } from "./components/EmptyImagesState";
import { ImagesLoadingState } from "./components/ImagesLoadingState";
import { ImagesErrorState } from "./components/ImagesErrorState";

interface ImageGalleryProps {
  quoteId: string;
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({ quoteId }) => {
  const {
    images,
    isLoading,
    error,
    selectedImage,
    imageDialogOpen,
    setImageDialogOpen,
    handleImageClick,
    openInNewTab
  } = useImageGallery(quoteId);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="text-lg flex items-center">
            <Images className="h-5 w-5 mr-2 text-blue-500" />
            Uploaded Images
          </CardTitle>
          <CardDescription>
            {images.length > 0 
              ? `${images.length} image${images.length === 1 ? '' : 's'} uploaded during discovery`
              : 'No images have been uploaded during discovery conversations'}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <ImagesLoadingState />
          ) : error ? (
            <ImagesErrorState error={error} />
          ) : images.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {images.map((image) => (
                <ImageCard 
                  key={image.id}
                  image={image} 
                  onViewImage={handleImageClick}
                  onOpenInNewTab={openInNewTab}
                />
              ))}
            </div>
          ) : (
            <EmptyImagesState />
          )}
        </CardContent>
      </Card>

      <ImageDetailDialog 
        image={selectedImage}
        open={imageDialogOpen}
        onOpenChange={setImageDialogOpen}
        onOpenInNewTab={openInNewTab}
      />
    </>
  );
};
