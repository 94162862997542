
// Helper function to safely convert values to numbers
export const safeNumberConversion = (value: any, defaultValue: number = 0): number => {
  if (value === null || value === undefined || value === '') {
    return defaultValue;
  }
  
  // Handle string numbers with commas
  if (typeof value === 'string') {
    // Remove commas and other non-numeric characters except decimal points
    const cleanedValue = value.replace(/[^0-9.]/g, '');
    const num = parseFloat(cleanedValue);
    return isNaN(num) ? defaultValue : num;
  }
  
  // Handle direct number values
  if (typeof value === 'number') {
    return value;
  }
  
  return defaultValue;
};
