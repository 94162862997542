
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Json } from "@/integrations/supabase/types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Type guard to check if a value is a non-null object
export const isObject = (value: any): value is Record<string, any> => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

// Helper function to safely handle Json types from Supabase
export const safeJsonParse = (jsonValue: Json | null | undefined): Record<string, any> => {
  if (jsonValue === null || jsonValue === undefined) {
    return {};
  }
  
  // If it's already an object, return it
  if (isObject(jsonValue)) {
    return jsonValue;
  }
  
  // Try to parse if it's a string
  if (typeof jsonValue === 'string') {
    try {
      const parsed = JSON.parse(jsonValue);
      if (isObject(parsed)) {
        return parsed;
      }
    } catch (e) {
      console.warn("Failed to parse JSON:", jsonValue);
    }
  }
  
  return {};
};

/**
 * Format a number as USD currency
 */
export function formatCurrency(value: number | string): string {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numberValue);
}

/**
 * Format a date string to MM/DD/YYYY format
 */
export function formatDate(dateString: string): string {
  if (!dateString) return 'N/A';
  
  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { 
      month: '2-digit', 
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return 'Invalid Date';
  }
}

/**
 * Format the time elapsed since a given date
 */
export function formatTimeElapsed(dateString: string): string {
  if (!dateString) return 'N/A';
  
  try {
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now.getTime() - date.getTime();
    
    // Convert to appropriate time unit
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    
    if (diffDays > 0) {
      return diffDays === 1 ? '1 day' : `${diffDays} days`;
    } else if (diffHours > 0) {
      return diffHours === 1 ? '1 hour' : `${diffHours} hours`;
    } else if (diffMins > 0) {
      return diffMins === 1 ? '1 minute' : `${diffMins} minutes`;
    } else {
      return 'Just now';
    }
  } catch (error) {
    console.error("Error calculating time elapsed:", error);
    return 'Unknown';
  }
}

/**
 * Format a business name with appropriate fallbacks
 */
export function formatBusinessName(name?: string): string {
  if (!name) return "Your Business";
  return name;
}
