
import {
  Table,
  TableBody,
  TableHeader,
} from "@/components/ui/table";
import { Prospect, SortDirection, SortField } from "../types";
import { TooltipProvider } from "@/components/ui/tooltip";
import { TableEmptyState } from "./TableEmptyState";
import { ProspectTableHeader } from "./ProspectTableHeader";
import { ProspectTableRow } from "./ProspectTableRow";

interface ProspectsTableProps {
  prospects: Prospect[];
  onToggleArchive: (prospectId: string, currentArchived: boolean) => void;
  sortField: SortField;
  sortDirection: SortDirection;
  onSort: (field: SortField) => void;
  onUpdateMonthlyValue?: (prospectId: string, monthlyValue: number) => void;
  calculatedMonthlyValues?: Record<string, number>;
}

export const ProspectsTable = ({
  prospects,
  onToggleArchive,
  sortField,
  sortDirection,
  onSort,
  onUpdateMonthlyValue,
  calculatedMonthlyValues
}: ProspectsTableProps) => {
  if (!prospects || prospects.length === 0) {
    return <TableEmptyState />;
  }

  return (
    <TooltipProvider delayDuration={0}>
      <div className="border rounded-md overflow-hidden">
        <Table>
          <TableHeader>
            <ProspectTableHeader 
              sortField={sortField}
              sortDirection={sortDirection}
              onSort={onSort}
            />
          </TableHeader>
          <TableBody>
            {prospects.map((prospect) => (
              <ProspectTableRow
                key={prospect.id}
                prospect={prospect}
                onToggleArchive={onToggleArchive}
                onUpdateMonthlyValue={onUpdateMonthlyValue}
                monthlyValue={calculatedMonthlyValues?.[prospect.id]}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </TooltipProvider>
  );
};
