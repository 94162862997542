
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertTriangle, Info, FileCheck, Clock } from "lucide-react";

export interface QuoteDisclaimerProps {
  isCustomizing?: boolean;
  isAdminView?: boolean;
  quoteFirmStatus?: 'budgetary' | 'firm';
  quoteStatus?: string;
  formattedDate?: string | null;
}

export const QuoteDisclaimer = ({ 
  isCustomizing = false, 
  isAdminView = false,
  quoteFirmStatus = 'budgetary',
  quoteStatus = '',
  formattedDate = null
}: QuoteDisclaimerProps) => {
  // Check if status is pending review specifically
  const isPendingReview = quoteStatus === 'pending_review';
  const isFirm = quoteFirmStatus === 'firm';
  
  const getDisclaimerText = () => {
    if (isCustomizing) {
      return "You are currently customizing rates. Changes will be saved when you exit customization mode.";
    }
    
    if (isPendingReview) {
      return "We've received your quote request submission. Your information has not yet been reviewed by our team. This Rate Sheet represents preliminary budgetary pricing which may adjust after our detailed review of your business requirements.";
    }
    
    if (isFirm) {
      return `This is a firm quote with finalized rates based on our detailed review of your requirements. These rates represent our commitment to the pricing shown and are ready for contract. This rate card is valid for 15 days from ${formattedDate || "submission date"}.`;
    }
    
    return "This budgetary quote has been reviewed by our team based on our initial assessment of your needs. The rates shown are estimated costs for planning purposes and may be adjusted as we finalize your specific requirements.";
  };
  
  const DisclaimerIcon = isCustomizing ? AlertTriangle : 
                          isPendingReview ? Clock :
                          isFirm ? FileCheck : Info;
  
  const bgColor = isCustomizing ? "bg-amber-50 border-amber-200" :
                 isPendingReview ? "bg-amber-50 border-amber-200" :
                 isFirm ? "bg-green-50 border-green-200" : 
                 "bg-blue-50 border-blue-200";
                 
  const iconColor = isCustomizing ? "text-amber-500" :
                   isPendingReview ? "text-amber-500" :
                   isFirm ? "text-green-500" : 
                   "text-blue-500";
  
  return (
    <Alert className={`mb-6 ${bgColor}`}>
      <div className="flex items-start">
        <DisclaimerIcon className={`h-6 w-6 ${iconColor} mt-0.5 mr-3`} />
        <AlertDescription className="text-sm">
          {getDisclaimerText()}
          
          {isAdminView && !isCustomizing && (
            <p className="mt-1 text-gray-600">
              {quoteFirmStatus === 'firm' 
                ? "Switch to Budgetary to indicate rates may change." 
                : "Switch to Firm to indicate final rates for contract."
              }
            </p>
          )}
        </AlertDescription>
      </div>
    </Alert>
  );
};
