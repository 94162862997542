
import { useCallback } from 'react';
import { supabase } from "@/integrations/supabase/client";
import { ClientProgress, SubStep } from "@/types/progress";
import { Json } from "@/integrations/supabase/types";
import { useToast } from "@/hooks/use-toast";

export const useProgressUpdater = (
  progressData: ClientProgress[],
  refreshData: () => Promise<void>
) => {
  const { toast } = useToast();
  
  const updateProgressStep = useCallback(async (
    stepType: string, 
    updates: Partial<Omit<ClientProgress, 'substeps'> & { substeps?: SubStep[] }>
  ) => {
    try {
      const stepToUpdate = progressData.find(step => step.step_type === stepType);
      
      if (!stepToUpdate) {
        console.error(`Step ${stepType} not found in progress data`);
        return false;
      }
      
      const dbUpdates: Partial<any> = { 
        ...updates,
        ...(updates.substeps ? { substeps: updates.substeps as unknown as Json } : {})
      };
      
      const { error } = await supabase
        .from('client_progress')
        .update(dbUpdates)
        .eq('id', stepToUpdate.id);
        
      if (error) {
        console.error("Error updating progress step:", error);
        throw error;
      }
      
      await refreshData();
      return true;
      
    } catch (error: any) {
      toast({
        title: "Error Updating Progress",
        description: error.message || "Failed to update progress data",
        variant: "destructive",
      });
      return false;
    }
  }, [progressData, refreshData, toast]);
  
  const updateSubstep = useCallback(async (
    stepType: string,
    substepId: string,
    isCompleted: boolean
  ) => {
    try {
      const stepToUpdate = progressData.find(step => step.step_type === stepType);
      
      if (!stepToUpdate) {
        console.error(`Step ${stepType} not found in progress data`);
        return false;
      }
      
      const updatedSubsteps = stepToUpdate.substeps.map(substep => 
        substep.id === substepId ? { ...substep, completed: isCompleted } : substep
      );
      
      const completedSubsteps = updatedSubsteps.filter(substep => substep.completed).length;
      const totalSubsteps = updatedSubsteps.length;
      const percentage = totalSubsteps > 0 ? Math.floor((completedSubsteps / totalSubsteps) * 100) : 0;
      
      const allCompleted = totalSubsteps > 0 && completedSubsteps === totalSubsteps;
      
      const { error } = await supabase
        .from('client_progress')
        .update({
          substeps: updatedSubsteps as unknown as Json,
          percentage_complete: percentage,
          status: allCompleted ? 'completed' : (percentage > 0 ? 'in_progress' : 'not_started'),
          completed_at: allCompleted ? new Date().toISOString() : stepToUpdate.completed_at
        })
        .eq('id', stepToUpdate.id);
        
      if (error) {
        console.error("Error updating substep:", error);
        throw error;
      }
      
      await refreshData();
      return true;
      
    } catch (error: any) {
      toast({
        title: "Error Updating Progress",
        description: error.message || "Failed to update progress data",
        variant: "destructive",
      });
      return false;
    }
  }, [progressData, refreshData, toast]);
  
  return { updateProgressStep, updateSubstep };
};
