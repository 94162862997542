
import { DatabaseQuote } from "@/pages/Quote/types";

/**
 * Processes and returns inbound shipment types from a quote
 */
export const getInboundShipmentTypes = (quote: DatabaseQuote) => {
  console.log("Inbound types:", quote.inbound_types);
  console.log("Loading types:", quote.loading_types);
  console.log("Receiving volumes:", quote.receiving_volumes);
  console.log("Pallet counts:", quote.pallet_counts);
  
  const items = [];
  const inboundTypes = quote.inbound_types as Record<string, boolean> || {};
  const volumes = quote.receiving_volumes as Record<string, string | number> || {};
  const loadingTypes = quote.loading_types as Record<string, string> || {};
  const palletCounts = quote.pallet_counts as Record<string, number> || {};

  if (inboundTypes.oceanContainer20) {
    const isPalletized = loadingTypes.container20 === "palletized";
    const palletCount = palletCounts.container20;
    
    items.push({
      type: "20ft Ocean Container",
      loadingType: loadingTypes.container20 || "Not specified",
      volume: volumes.container20PerMonth,
      isPalletized,
      palletCount: isPalletized && palletCount ? palletCount : null
    });
  }
  if (inboundTypes.oceanContainer40) {
    const isPalletized = loadingTypes.container40 === "palletized";
    const palletCount = palletCounts.container40;
    
    items.push({
      type: "40ft Ocean Container",
      loadingType: loadingTypes.container40 || "Not specified",
      volume: volumes.container40PerMonth,
      isPalletized,
      palletCount: isPalletized && palletCount ? palletCount : null
    });
  }
  if (inboundTypes.ltl) {
    items.push({
      type: "LTL (Less Than Truckload)",
      loadingType: "N/A",
      volume: volumes.ltlPerMonth,
      isPalletized: false,
      palletCount: null
    });
  }
  if (inboundTypes.ftl) {
    const isPalletized = loadingTypes.ftl === "palletized";
    const palletCount = palletCounts.ftl;
    
    items.push({
      type: "FTL (Full Truckload)",
      loadingType: loadingTypes.ftl || "Not specified",
      volume: volumes.ftlPerMonth,
      isPalletized,
      palletCount: isPalletized && palletCount ? palletCount : null
    });
  }
  if (inboundTypes.smallParcel) {
    items.push({
      type: "Small Parcel",
      loadingType: "N/A",
      volume: volumes.parcelsPerMonth,
      isPalletized: false,
      palletCount: null
    });
  }
  
  console.log("Processed inbound shipment types:", items);
  return items;
};
