
import React from 'react';
import { Box } from "lucide-react";

interface StorageSectionProps {
  palletPositions: number;
  hasCartonStorage: boolean;
  hasBinStorage: boolean;
}

export const StorageSection = ({ 
  palletPositions, 
  hasCartonStorage, 
  hasBinStorage 
}: StorageSectionProps) => {
  return (
    <div className="flex items-start">
      <Box className="h-4 w-4 mr-2 text-amber-500 mt-0.5 flex-shrink-0" />
      <div className="flex-1">
        <h5 className="text-sm font-medium text-gray-700">Storage</h5>
        <p className="text-sm">
          {palletPositions > 0 && `${palletPositions} Pallets`}
          {palletPositions > 0 && (hasCartonStorage || hasBinStorage) && ', '}
          {hasCartonStorage && 'Cartons'}
          {hasCartonStorage && hasBinStorage && ', '}
          {hasBinStorage && 'Bins'}
        </p>
      </div>
    </div>
  );
};
