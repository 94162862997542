
import { useState } from "react";
import { DatabaseQuote } from "@/pages/Quote/types";
import { useResearchAPI } from "./useResearchAPI";
import { useResearchSections } from "./useResearchSections";

export const useResearchState = (quote: DatabaseQuote) => {
  // Extract initial data from the quote
  const [research, setResearch] = useState<string | null>(
    // Use the research_summary field instead of discovery_summary
    quote.research_summary || null
  );
  const [clientFriendlyNarrative, setClientFriendlyNarrative] = useState<string | null>(
    // Use the correct field name client_friendly_narrative
    quote.client_friendly_narrative || null
  );
  const [researchTimestamp, setResearchTimestamp] = useState<string | null>(
    // Use the research_completed_at field
    quote.research_completed_at || null
  );

  // Use our custom hooks
  const { 
    isResearching, 
    errorMessage, 
    callResearchAPI, 
    handleRetry,
    setErrorMessage 
  } = useResearchAPI();
  
  const { sections, hasLimitedInfo } = useResearchSections(research);

  /**
   * Handles refreshing the research data
   */
  const handleRefreshResearch = async () => {
    if (isResearching) return;
    
    const { websiteUrl, companyName } = {
      websiteUrl: quote.website || '',
      companyName: quote.company || ''
    };

    // Call the research API
    const data = await callResearchAPI(companyName, websiteUrl, quote.id);
    
    // If we have data, update our state
    if (data?.research) {
      setResearch(data.research);
      setClientFriendlyNarrative(data.clientNarrative || null);
      setResearchTimestamp(data.timestamp || new Date().toISOString());
    } else if (handleRetry(companyName, websiteUrl, quote.id, (data) => {
      // Success callback for retry
      setResearch(data.research);
      setClientFriendlyNarrative(data.clientNarrative || null);
      setResearchTimestamp(data.timestamp || new Date().toISOString());
    })) {
      // If we successfully started a retry, just return
      return;
    }
  };

  return {
    isResearching,
    research,
    clientFriendlyNarrative,
    hasLimitedInfo,
    sections,
    researchTimestamp,
    errorMessage,
    handleRefreshResearch
  };
};
