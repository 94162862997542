
import React from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ExternalLink } from "lucide-react";
import { DiscoveryImage } from "../types";

interface ImageDetailDialogProps {
  image: DiscoveryImage | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onOpenInNewTab: (url: string) => void;
}

export const ImageDetailDialog: React.FC<ImageDetailDialogProps> = ({
  image,
  open,
  onOpenChange,
  onOpenInNewTab
}) => {
  if (!image) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>
            Image from {new Date(image.created_at).toLocaleString()}
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col">
          <div className="rounded-md overflow-hidden border max-h-[70vh]">
            <img 
              src={image.image_path} 
              alt={image.description || 'Discovery image'} 
              className="w-full h-auto object-contain"
            />
          </div>
          {image.description && (
            <p className="mt-4 text-sm text-gray-600">
              {image.description}
            </p>
          )}
          <div className="flex justify-end mt-4">
            <Button 
              variant="outline" 
              onClick={() => onOpenInNewTab(image.image_path)}
              className="flex items-center gap-2"
            >
              <ExternalLink className="h-4 w-4" />
              Open Original
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
