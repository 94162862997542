
import { Table, TableBody } from "@/components/ui/table";
import { StorageSectionHeader } from "./StorageSectionHeader";
import { StorageTableHeader } from "./StorageTableHeader";
import { PalletStorageRow } from "./PalletStorageRow";
import { ItemStorageRow } from "./ItemStorageRow";
import { CartonStorageRow } from "./CartonStorageRow";
import { CartonCubicFootageInfoRow } from "./CartonCubicFootageInfoRow";

export interface StorageSectionProps {
  rateCardData: Record<string, number>;
  scopeData: any;
  expanded: boolean;
  quoteData?: any;
  onToggle: () => void;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified?: (key: string) => boolean;
  showAllRates?: boolean;
}

export const StorageSection = ({
  rateCardData,
  scopeData,
  expanded,
  onToggle,
  onRateChange,
  isCustomizing,
  isRateModified = () => false,
  showAllRates = false
}: StorageSectionProps) => {
  // Only render section if storage positions exist or showAllRates is true
  const hasStoragePositions = 
    scopeData.palletPositions > 0 || 
    scopeData.itemPositions > 0 || 
    scopeData.cartonPositions > 0;

  if (!hasStoragePositions && !showAllRates) {
    return null;
  }
  
  // Calculate carton cubic footage cost using the cubic footage rate - added null check
  const cartonCubicFootageCost = (scopeData.cartonCubicFootage || 0) && rateCardData.storagePerCubicFoot 
    ? (scopeData.cartonCubicFootage || 0) * rateCardData.storagePerCubicFoot
    : 0;

  // If we have cubic footage data, we use that instead of shelf position calculation
  const showCartonCubicFootage = (scopeData.cartonCubicFootage || 0) > 0;
  
  return (
    <div className="mb-6 border rounded-lg overflow-hidden">
      <StorageSectionHeader expanded={expanded} onToggle={onToggle} />
      
      {expanded && (
        <div className="overflow-x-auto p-3">
          <Table>
            <StorageTableHeader />
            <TableBody>
              {/* Pallet Storage */}
              {(showAllRates || scopeData.palletPositions > 0) && (
                <>
                  <PalletStorageRow 
                    fieldKey="storageFirst100Pallets"
                    label="Pallet Storage (First 100)"
                    rate={rateCardData.storageFirst100Pallets || 0}
                    quantity={scopeData.palletPositions > 100 ? 100 : (scopeData.palletPositions || 0)}
                    onRateChange={onRateChange}
                    isCustomizing={isCustomizing}
                    isModified={isRateModified("storageFirst100Pallets")}
                  />
                  
                  {/* Additional Pallets */}
                  {(showAllRates || scopeData.palletPositions > 100) && (
                    <PalletStorageRow 
                      fieldKey="storageAdditionalPallets"
                      label="Pallet Storage (Additional)"
                      rate={rateCardData.storageAdditionalPallets || 0}
                      quantity={Math.max(0, (scopeData.palletPositions || 0) - 100)}
                      onRateChange={onRateChange}
                      isCustomizing={isCustomizing}
                      isModified={isRateModified("storageAdditionalPallets")}
                    />
                  )}
                </>
              )}
              
              {/* Item Storage */}
              {(showAllRates || scopeData.itemPositions > 0) && (
                <ItemStorageRow 
                  itemStorageType={scopeData.itemStorageType}
                  rate={rateCardData.storageBin || 0}
                  quantity={scopeData.itemPositions || 0}
                  onRateChange={onRateChange}
                  isCustomizing={isCustomizing}
                  isModified={isRateModified("storageBin")}
                />
              )}
              
              {/* Carton Storage */}
              {(showAllRates || scopeData.cartonPositions > 0) && (
                <>
                  <CartonStorageRow 
                    showCubicFootage={showCartonCubicFootage}
                    rate={rateCardData.storageShelves || 0}
                    quantity={scopeData.cartonPositions || 0}
                    cubicFootage={scopeData.cartonCubicFootage || 0}
                    cubicFootageRate={rateCardData.storagePerCubicFoot || 0}
                    onRateChange={onRateChange}
                    isCustomizing={isCustomizing}
                    isModified={isRateModified}
                  />
                  
                  {/* Cubic Footage Info Row */}
                  {!showCartonCubicFootage && (showAllRates || (scopeData.cartonCubicFootage || 0) > 0) && (
                    <CartonCubicFootageInfoRow 
                      cubicFootage={scopeData.cartonCubicFootage || 0}
                      rate={rateCardData.storagePerCubicFoot || 0}
                      onRateChange={onRateChange}
                      isCustomizing={isCustomizing}
                      isModified={isRateModified("storagePerCubicFoot")}
                    />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

// Export all components for potential reuse
export * from "./PalletStorageRow";
export * from "./ItemStorageRow";
export * from "./CartonStorageRow";
export * from "./CartonCubicFootageInfoRow";
export * from "./StorageTableHeader";
export * from "./StorageSectionHeader";
