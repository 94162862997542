
import {
  LayoutDashboard,
  Book,
  Calculator,
  FileText,
  ClipboardList,
  MessageSquare,
  Settings,
  LogOut,
  Handshake
} from "lucide-react";

// Define menu items by sections
export const menuSections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: LayoutDashboard,
        path: "/client-portal",
      }
    ]
  },
  {
    label: "Getting Started",
    items: [
      {
        title: "Quotes",
        icon: Calculator,
        path: "/client-portal/quotes",
      },
      {
        title: "Discovery",
        icon: Book,
        path: "/client-portal/discovery",
      },
      {
        title: "Contract",
        icon: FileText,
        path: "/client-portal/contract",
      },
      {
        title: "Onboard",
        icon: ClipboardList,
        path: "/client-portal/onboarding",
      },
    ]
  },
  {
    label: "Support & Resources",
    items: [
      {
        title: "Get Support",
        icon: MessageSquare,
        path: "/client-portal/support",
      },
      {
        title: "Recommended Partners",
        icon: Handshake,
        path: "/client-portal/partners",
      },
    ]
  },
];
