import { motion } from "framer-motion";
import { memo, useState } from "react";
import { usePreviewMode } from "@/contexts/PreviewContext";
interface PlatformLogoProps {
  name: string;
  logo: string;
  width: number;
}
const PlatformLogo = memo(({
  name,
  logo,
  width
}: PlatformLogoProps) => {
  const {
    adjustImagePath,
    isPreviewMode
  } = usePreviewMode();
  const [imgError, setImgError] = useState(false);

  // Get the adjusted path using our utility function
  const adjustedLogoPath = adjustImagePath(logo);
  return <motion.div initial={{
    opacity: 0,
    y: 20
  }} whileInView={{
    opacity: 1,
    y: 0
  }} transition={{
    duration: 0.5
  }} viewport={{
    once: true
  }} className="flex items-center justify-center p-6 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
      {!imgError ? <img src={adjustedLogoPath} alt={`${name} logo`} className="object-contain h-10" style={{
      width
    }} loading="lazy" onError={() => {
      console.log(`Failed to load image for ${name}:`, {
        original: logo,
        adjusted: adjustedLogoPath,
        isPreviewMode
      });
      setImgError(true);
    }} /> : <div className="flex items-center justify-center bg-gray-100 rounded-md" style={{
      width,
      height: '40px'
    }}>
          <span className="text-sm font-medium text-gray-500">{name}</span>
        </div>}
    </motion.div>;
});
export const PlatformIntegration = () => {
  const platforms = [{
    name: "Amazon",
    logo: "/lovable-uploads/fbecdc24-0c5d-4334-9f1c-8675fce65b0e.png",
    width: 120
  }, {
    name: "Shopify",
    logo: "/lovable-uploads/c48765fc-66f6-4c26-a634-82f4a4fdc0dc.png",
    width: 140
  }, {
    name: "TikTok Shop",
    logo: "/lovable-uploads/bf353530-5b38-42b6-a463-f337c0e40e63.png",
    width: 130
  }, {
    name: "Walmart",
    logo: "/lovable-uploads/ca285843-fc12-43a9-8e96-a76bc7c4836d.png",
    width: 160
  }, {
    name: "WooCommerce",
    logo: "/lovable-uploads/aa0470f5-cf12-4a5e-802b-f6a6b1f71892.png",
    width: 180
  }, {
    name: "SPS Commerce",
    logo: "/lovable-uploads/07418175-9581-4ad9-8f16-ab31b41c3625.png",
    width: 100
  }, {
    name: "Faire",
    logo: "/lovable-uploads/bdcdbab2-831a-4e54-9022-268f68914630.png",
    width: 140
  }, {
    name: "Etsy",
    logo: "/lovable-uploads/e4d4d8ca-31a6-48f5-860b-5e8d500ea460.png",
    width: 100
  }, {
    name: "eBay",
    logo: "/lovable-uploads/68eae255-cac6-46ea-a0f5-2e8c5e5b8bc1.png",
    width: 110
  }, {
    name: "Wayfair",
    logo: "/lovable-uploads/aff9482b-c8d7-4a6b-bcd8-11a712a78b6b.png",
    width: 160
  }];
  return <div className="py-16 bg-lime-50">
      <div className="container mx-auto px-4">
        <motion.div initial={{
        opacity: 0,
        y: 20
      }} whileInView={{
        opacity: 1,
        y: 0
      }} transition={{
        duration: 0.6
      }} viewport={{
        once: true
      }} className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Unified Multi-Channel Order Management
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Seamlessly integrate and manage orders from all your sales channels in one place. 
            Effortlessly expand to new channels.
          </p>
        </motion.div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {platforms.map(platform => <PlatformLogo key={platform.name} {...platform} />)}
        </div>

        <motion.div initial={{
        opacity: 0,
        scale: 0.8
      }} whileInView={{
        opacity: 1,
        scale: 1
      }} transition={{
        duration: 0.6,
        delay: 0.2
      }} viewport={{
        once: true
      }} className="text-center mt-8">
          <p className="text-xl text-gray-600 font-semibold italic">
            And many more!
          </p>
        </motion.div>
      </div>
    </div>;
};
export default PlatformIntegration;