
import React from 'react';
import { Wrench } from "lucide-react";

interface ServicesSectionProps {
  valueAddedServices: string[];
}

export const ServicesSection = ({ valueAddedServices }: ServicesSectionProps) => {
  if (valueAddedServices.length === 0) {
    return null;
  }

  return (
    <div className="flex items-start">
      <Wrench className="h-4 w-4 mr-2 text-green-500 mt-0.5 flex-shrink-0" />
      <div className="flex-1">
        <h5 className="text-sm font-medium text-gray-700">Services</h5>
        <p className="text-sm">{valueAddedServices.join(', ')}</p>
      </div>
    </div>
  );
};
