import { motion } from "framer-motion";
import { Plane, Ship, Train, Truck } from "lucide-react";
export const HoustonHub = () => {
  return <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <motion.div initial={{
          opacity: 0,
          x: -20
        }} whileInView={{
          opacity: 1,
          x: 0
        }} transition={{
          duration: 0.6
        }} viewport={{
          once: true
        }} className="lg:w-1/2">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">Houston: Your Strategic Logistics Hub for Speed and Savings</h2>
            <p className="text-lg text-gray-600 mb-6">
              Located in the heart of Texas, our Houston facility provides unparalleled access to major transportation networks and international trade routes. With proximity to the Port of Houston, major airports, and extensive rail and highway systems, we offer optimal distribution solutions for businesses of all sizes.
            </p>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="flex items-center gap-2">
                <Ship className="w-6 h-6 text-primary" />
                <span>Port Access</span>
              </div>
              <div className="flex items-center gap-2">
                <Plane className="w-6 h-6 text-primary" />
                <span>Air Freight</span>
              </div>
              <div className="flex items-center gap-2">
                <Train className="w-6 h-6 text-primary" />
                <span>Rail Network</span>
              </div>
              <div className="flex items-center gap-2">
                <Truck className="w-6 h-6 text-primary" />
                <span>Highway System</span>
              </div>
            </div>
          </motion.div>
          
          <motion.div initial={{
          opacity: 0,
          x: 20
        }} whileInView={{
          opacity: 1,
          x: 0
        }} transition={{
          duration: 0.6,
          delay: 0.2
        }} viewport={{
          once: true
        }} className="lg:w-1/2">
            <img src="/lovable-uploads/ce3977b6-9be5-4802-b883-f3d9aa221554.png" alt="Multi-modal logistics and transportation" className="rounded-xl shadow-lg w-full" />
          </motion.div>
        </div>
      </div>
    </section>;
};