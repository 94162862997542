
import { useState, useEffect } from "react";
import { Prospect, RateTier } from "../../../types";
import { calculateQuoteDetails, extractScopeData } from "../../../components/QuoteCalculator/utils";
import { getRecommendedTier } from "@/utils/rates/recommendedTierUtils";
import { isObject } from "@/lib/utils";

export const useScopeData = (prospect: Prospect) => {
  const [recommendedTier, setRecommendedTier] = useState<RateTier | null>(null);
  const [annualValue, setAnnualValue] = useState<number>(0);
  
  // Get the tier directly from the database or default to D2C
  const displayTier = prospect.approved_rate_tier || 'D2C';
  
  // Check if this prospect has custom rates
  const hasCustomRates = prospect.custom_rates && 
    typeof prospect.custom_rates === 'object' && 
    Object.keys(prospect.custom_rates).length > 0;
  
  // Calculate the recommended tier when the component mounts
  useEffect(() => {
    if (prospect) {
      try {
        // Extract scope data for calculations
        const scopeData = extractScopeData(prospect);
        
        // Calculate quote details using the default rate tier or approved rate tier
        const rateTier = prospect.approved_rate_tier || 'D2C';
        
        // Properly type custom rates
        const customRates = prospect.custom_rates && isObject(prospect.custom_rates) 
          ? prospect.custom_rates as Record<string, number>
          : undefined;
          
        const quoteDetails = calculateQuoteDetails(
          rateTier === 'CUSTOM' ? 'D2C' : rateTier,
          scopeData,
          customRates
        );
        
        // Calculate recommended tier based on monthly revenue
        if (quoteDetails && quoteDetails.monthly && quoteDetails.monthly.total) {
          const monthlyRevenue = quoteDetails.monthly.total;
          setAnnualValue(monthlyRevenue * 12);
          const tier = getRecommendedTier(monthlyRevenue, prospect.id);
          setRecommendedTier(tier);
        }
      } catch (err) {
        console.error(`Error calculating recommended tier for prospect ${prospect.id}:`, err);
      }
    }
  }, [prospect]);

  return {
    recommendedTier,
    displayTier,
    hasCustomRates,
    annualValue,
    // Determine if we should show the recommended tier badge
    shouldShowRecommendedBadge: 
      recommendedTier && 
      recommendedTier !== displayTier && 
      displayTier !== 'CUSTOM',
    // Only show (Custom) if the tier is not already CUSTOM
    showCustomLabel: hasCustomRates && displayTier !== 'CUSTOM'
  };
};
