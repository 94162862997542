
import { Link, useLocation } from "react-router-dom";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "@/components/ui/sidebar";
import { LucideIcon } from "lucide-react";

interface SidebarMenuItemType {
  title: string;
  icon: LucideIcon;
  path: string;
  isGreyedOut?: boolean;
  completionStatus?: "incomplete" | "in-progress" | "complete";
  comingSoon?: boolean;
}

interface SidebarSectionProps {
  label?: string;
  items: SidebarMenuItemType[];
  isGreyedOut?: boolean;
}

export const SidebarSection = ({ label, items, isGreyedOut }: SidebarSectionProps) => {
  const location = useLocation();

  return (
    <SidebarGroup>
      {label && <SidebarGroupLabel>{label}</SidebarGroupLabel>}
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item) => {
            // Apply greyed out style if specified at the section level OR item level,
            // unless the item is marked as complete
            const shouldGreyOut = (isGreyedOut || item.isGreyedOut) && 
                                  item.completionStatus !== 'complete';
            
            return (
              <SidebarMenuItem key={item.title}>
                {item.comingSoon ? (
                  <div className="flex items-center gap-3 px-3 py-2 text-gray-400 cursor-not-allowed">
                    <item.icon className="h-4 w-4" />
                    <span>{item.title}</span>
                    <span className="text-xs ml-1">(Coming Soon)</span>
                  </div>
                ) : (
                  <SidebarMenuButton
                    asChild
                    className={`
                      ${location.pathname === item.path ? "bg-gray-100" : ""}
                      ${shouldGreyOut ? "opacity-50" : ""}
                    `}
                  >
                    <Link to={item.path} className="flex items-center gap-3">
                      <item.icon className="h-4 w-4" />
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                )}
              </SidebarMenuItem>
            );
          })}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
};
