
import { motion } from "framer-motion";
import { Star } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Card, CardContent } from "@/components/ui/card";
import { CustomBadge } from "@/components/ui/custom-badge";

export const Testimonials = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            What Our Clients Are Saying
          </h2>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {/* First Testimonial - Sean Davis */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            viewport={{ once: true }}
          >
            <Card className="h-full shadow-lg hover:shadow-xl transition-shadow">
              <CardContent className="p-6">
                <div className="flex items-center mb-6">
                  <Avatar className="w-16 h-16 border-2 border-primary/10">
                    <AvatarImage src="/lovable-uploads/a7bf4284-8485-4f0a-a895-ba84cc830e1f.png" alt="Sean Davis" />
                    <AvatarFallback>SD</AvatarFallback>
                  </Avatar>
                  <div className="ml-4">
                    <h3 className="text-lg font-bold text-gray-900">Sean Davis</h3>
                    <p className="text-sm text-gray-600">Legacy Enterprises</p>
                  </div>
                </div>
                
                {/* Testimonial Header */}
                <div className="mb-3">
                  <h4 className="text-xl font-semibold text-primary">Exceptional Service</h4>
                  {/* Star Rating */}
                  <div className="flex mt-1 mb-3">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                    ))}
                  </div>
                </div>
                
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                  <p className="text-gray-700 italic">
                    "I've been working with Thrive for the past few months and they have been amazing to work with. Eric, Robert, Dennis, Crystal and the entire team are extremely helpful and all around a pleasure to work with. I'm in the process of moving ALL my inventory to them. They have already saved me a lot of money without sacrificing accuracy or efficiency!"
                  </p>
                </div>
              </CardContent>
            </Card>
          </motion.div>

          {/* Second Testimonial - Channing Dyson */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <Card className="h-full shadow-lg hover:shadow-xl transition-shadow">
              <CardContent className="p-6">
                <div className="flex items-center mb-6">
                  <Avatar className="w-16 h-16 border-2 border-primary/10">
                    <AvatarImage src="/lovable-uploads/75ca12c8-0e29-4505-89f9-b40ab27fa396.png" alt="Channing Dyson" />
                    <AvatarFallback>CD</AvatarFallback>
                  </Avatar>
                  <div className="ml-4">
                    <h3 className="text-lg font-bold text-gray-900">Channing Dyson</h3>
                    <p className="text-sm text-gray-600">DAX Eyewear</p>
                  </div>
                </div>
                
                {/* Testimonial Header */}
                <div className="mb-3">
                  <h4 className="text-xl font-semibold text-primary">Responsive Support</h4>
                  {/* Star Rating */}
                  <div className="flex mt-1 mb-3">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                    ))}
                  </div>
                </div>
                
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                  <p className="text-gray-700 italic">
                    "Wonderful service. Thankful for how quickly they answer any questions we have and resolve any problems quickly and professionally."
                  </p>
                </div>
              </CardContent>
            </Card>
          </motion.div>

          {/* Third Testimonial - Holly Brauer */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            viewport={{ once: true }}
          >
            <Card className="h-full shadow-lg hover:shadow-xl transition-shadow">
              <CardContent className="p-6">
                <div className="flex items-center mb-6">
                  <Avatar className="w-16 h-16 border-2 border-primary/10">
                    <AvatarImage src="/lovable-uploads/52fd5f04-d07f-4273-92e7-db5174d02015.png" alt="Holly Brauer" />
                    <AvatarFallback>HB</AvatarFallback>
                  </Avatar>
                  <div className="ml-4">
                    <h3 className="text-lg font-bold text-gray-900">Holly Brauer</h3>
                    <p className="text-sm text-gray-600">Bulletproof Bunny</p>
                  </div>
                </div>
                
                {/* Testimonial Header */}
                <div className="mb-3">
                  <h4 className="text-xl font-semibold text-primary">Reliable Partnership</h4>
                  {/* Star Rating */}
                  <div className="flex mt-1 mb-3">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                    ))}
                  </div>
                </div>
                
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                  <p className="text-gray-700 italic">
                    "I've been extremely happy with Thrive 3PL. They have an excellent team, who is great about answering questions and keeping me up to date with my inventory. Would highly recommend!"
                  </p>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
