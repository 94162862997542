
import { RateTier } from './types';

// Revenue thresholds for each rate tier (in USD)
export interface RevenueTiers {
  D2C: number;
  'D2C+': number;
  SMB: number;
  'SMB+': number;
  LEB: number;
}

// Default revenue thresholds
export const defaultRevenueTiers: RevenueTiers = {
  D2C: 0,
  'D2C+': 1500,
  SMB: 3000,
  'SMB+': 6000,
  LEB: 12000
};

// Function for determining rate tier based on estimated monthly revenue
export const getRateTierFromRevenue = (revenue: number, tiers: RevenueTiers = defaultRevenueTiers): RateTier => {
  if (revenue >= tiers.LEB) return 'LEB';
  if (revenue >= tiers['SMB+']) return 'SMB+';
  if (revenue >= tiers.SMB) return 'SMB';
  if (revenue >= tiers['D2C+']) return 'D2C+';
  return 'D2C';
};

// Function for determining rate tier based on a score
export const getRateTierFromScore = (score: number): RateTier => {
  if (score >= 90) return 'LEB';
  if (score >= 80) return 'SMB+';
  if (score >= 70) return 'SMB';
  if (score >= 60) return 'D2C+';
  return 'D2C';
};
