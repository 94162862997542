
import { RateStructure, RateTier } from './types';

// Define the rate table with all pricing tiers
export const rateTable: Record<RateTier, RateStructure> = {
  'D2C': {
    setupFee: 95,
    skuSetupFee: 5,
    skuBulkUpload: 20,
    manualOrderEntry: 1.75,
    bolCreation: 6,
    standardWOMSPortal: 30,
    enhancedWOMSPortal: 100,
    warehouseLabor: 39,
    warehouseManagement: 59,
    bookDrayage: 50, // New service with $50 rate
    receivingStandardCarton: 1.95,
    receivingExtraweightCarton: 2.85,
    receivingStandardPallet: 15,
    receiving20Container: 400,
    receiving40Container: 700,
    receivingFTL: 850,
    receivingFBABase: 1.95,
    receivingFBAPerUnit: 0.30,
    receivingPalletizeOnReceipt: 22, // New receiving service with $22 rate
    storageBin: 0.55,
    storageShelves: 0.48,
    storageFirst100Pallets: 30,
    storageAdditionalPallets: 25,
    storagePerCubicFoot: 0.48,
    pickPackD2CBase: 1.95,
    pickPackD2CPerUnit: 0.25,
    pickPackB2BBase: 3.85,
    pickPackB2BPerUnit: 0.06,
    outboundStandardCarton: 1.95,
    outboundExtraweightCarton: 2.85,
    outboundPalletForwarding: 15,
    outboundPalletizingCartons: 35,
    labelingStickeringPerUnit: 0.25,
    kittingBaggingPerPiece: 0.25
  },
  'D2C+': {
    setupFee: 145,
    skuSetupFee: 5,
    skuBulkUpload: 20,
    manualOrderEntry: 1.75,
    bolCreation: 6,
    standardWOMSPortal: 30,
    enhancedWOMSPortal: 100,
    warehouseLabor: 39,
    warehouseManagement: 59,
    bookDrayage: 50, // New service with $50 rate
    receivingStandardCarton: 1.95,
    receivingExtraweightCarton: 2.85,
    receivingStandardPallet: 13.50,
    receiving20Container: 385,
    receiving40Container: 675,
    receivingFTL: 825,
    receivingFBABase: 1.95,
    receivingFBAPerUnit: 0.30,
    receivingPalletizeOnReceipt: 22, // New receiving service with $22 rate
    storageBin: 0.55,
    storageShelves: 0.47,
    storageFirst100Pallets: 26,
    storageAdditionalPallets: 22,
    storagePerCubicFoot: 0.47,
    pickPackD2CBase: 1.95,
    pickPackD2CPerUnit: 0.25,
    pickPackB2BBase: 3.85,
    pickPackB2BPerUnit: 0.06,
    outboundStandardCarton: 1.95,
    outboundExtraweightCarton: 2.85,
    outboundPalletForwarding: 13.50,
    outboundPalletizingCartons: 33.50,
    labelingStickeringPerUnit: 0.25,
    kittingBaggingPerPiece: 0.25
  },
  'SMB': {
    setupFee: 195,
    skuSetupFee: 5,
    skuBulkUpload: 20,
    manualOrderEntry: 1.75,
    bolCreation: 6,
    standardWOMSPortal: 30,
    enhancedWOMSPortal: 100,
    warehouseLabor: 39,
    warehouseManagement: 59,
    bookDrayage: 50, // New service with $50 rate
    receivingStandardCarton: 1.95,
    receivingExtraweightCarton: 2.85,
    receivingStandardPallet: 12,
    receiving20Container: 375,
    receiving40Container: 650,
    receivingFTL: 800,
    receivingFBABase: 1.95,
    receivingFBAPerUnit: 0.30,
    receivingPalletizeOnReceipt: 22, // New receiving service with $22 rate
    storageBin: 0.55,
    storageShelves: 0.45,
    storageFirst100Pallets: 22,
    storageAdditionalPallets: 19,
    storagePerCubicFoot: 0.45,
    pickPackD2CBase: 1.95,
    pickPackD2CPerUnit: 0.25,
    pickPackB2BBase: 3.85,
    pickPackB2BPerUnit: 0.06,
    outboundStandardCarton: 1.95,
    outboundExtraweightCarton: 2.85,
    outboundPalletForwarding: 12,
    outboundPalletizingCartons: 32,
    labelingStickeringPerUnit: 0.25,
    kittingBaggingPerPiece: 0.25
  },
  'SMB+': {
    setupFee: 295,
    skuSetupFee: 5,
    skuBulkUpload: 20,
    manualOrderEntry: 1.75,
    bolCreation: 6,
    standardWOMSPortal: 30,
    enhancedWOMSPortal: 100,
    warehouseLabor: 39,
    warehouseManagement: 59,
    bookDrayage: 50, // New service with $50 rate
    receivingStandardCarton: 1.95,
    receivingExtraweightCarton: 2.85,
    receivingStandardPallet: 11.50,
    receiving20Container: 365,
    receiving40Container: 600,
    receivingFTL: 775,
    receivingFBABase: 1.95,
    receivingFBAPerUnit: 0.30,
    receivingPalletizeOnReceipt: 22, // New receiving service with $22 rate
    storageBin: 0.55,
    storageShelves: 0.43,
    storageFirst100Pallets: 18,
    storageAdditionalPallets: 16.25,
    storagePerCubicFoot: 0.43,
    pickPackD2CBase: 1.95,
    pickPackD2CPerUnit: 0.25,
    pickPackB2BBase: 3.85,
    pickPackB2BPerUnit: 0.06,
    outboundStandardCarton: 1.95,
    outboundExtraweightCarton: 2.85,
    outboundPalletForwarding: 11.50,
    outboundPalletizingCartons: 30,
    labelingStickeringPerUnit: 0.25,
    kittingBaggingPerPiece: 0.25
  },
  'LEB': {
    setupFee: 395,
    skuSetupFee: 5,
    skuBulkUpload: 20,
    manualOrderEntry: 1.75,
    bolCreation: 6,
    standardWOMSPortal: 30,
    enhancedWOMSPortal: 100,
    warehouseLabor: 39,
    warehouseManagement: 59,
    bookDrayage: 50, // New service with $50 rate
    receivingStandardCarton: 1.95,
    receivingExtraweightCarton: 2.85,
    receivingStandardPallet: 10.50,
    receiving20Container: 350,
    receiving40Container: 550,
    receivingFTL: 750,
    receivingFBABase: 1.95,
    receivingFBAPerUnit: 0.30,
    receivingPalletizeOnReceipt: 22, // New receiving service with $22 rate
    storageBin: 0.55,
    storageShelves: 0.40,
    storageFirst100Pallets: 17,
    storageAdditionalPallets: 15.50,
    storagePerCubicFoot: 0.40,
    pickPackD2CBase: 1.95,
    pickPackD2CPerUnit: 0.25,
    pickPackB2BBase: 3.85,
    pickPackB2BPerUnit: 0.06,
    outboundStandardCarton: 1.95,
    outboundExtraweightCarton: 2.85,
    outboundPalletForwarding: 10.50,
    outboundPalletizingCartons: 28,
    labelingStickeringPerUnit: 0.25,
    kittingBaggingPerPiece: 0.25
  }
};
