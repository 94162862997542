
import { DatabaseQuote } from "@/pages/Quote/types";
import { StoredQuoteData } from "@/utils/storage/types";
import { Prospect } from "@/pages/Admin/Sales/types";
import { Json } from "@/integrations/supabase/types";
import { DiscoveryConversation } from "@/pages/Quote/types";
import { DisplayRateTier } from "@/utils/rates";

// Helper function to safely convert JSON to Record type
export const safeJsonToRecord = <T = any>(json: Json | undefined | null): Record<string, T> => {
  if (!json) return {};
  if (typeof json === 'object') return json as Record<string, T>;
  try {
    return JSON.parse(json as string) as Record<string, T>;
  } catch (e) {
    console.error("Error parsing JSON:", e);
    return {};
  }
};

// Define the return type for processQuoteData
export interface ProcessedQuoteData {
  processedQuoteRequest: ProcessedQuoteRequest;
  typedQuoteData: StoredQuoteData;
}

// Export the ProcessedQuoteRequest type for use in other files
export type ProcessedQuoteRequest = StoredQuoteData & {
  id: string;
  // Add database fields needed by components
  quote_status?: string;
  admin_notes?: string;
  admin_reviewed_at?: string;
  approved_rate_tier?: DisplayRateTier; // Updated to use DisplayRateTier type
  quote_version?: number;
  created_at?: string;
  quote_type?: "budgetary" | "firm"; // Update this to match DatabaseQuote
  // Add discovery conversation data
  discovery_conversation?: any; // Change to 'any' to avoid type issues
  // Add custom_rates field
  custom_rates?: Record<string, number>;
  // Other fields from DatabaseQuote that might be needed
  archived?: boolean;
  last_modified_at?: string;
  pushed_to_client_at?: string;
  business_fit_score?: number;
  business_fit_analysis?: string;
  review_date?: string;
  reviewer_name?: string;
  estimated_monthly_cost?: number;
  original_rate_tier?: string;
  research_completed_at?: string;
  research_summary?: string;
};

/**
 * Processes quote data and extracts typed values from database models
 */
export const processQuoteData = (quote: DatabaseQuote | Prospect): ProcessedQuoteData => {
  // Cast the quote data to a typed StoredQuoteData object
  const typedQuoteData: StoredQuoteData = {
    // Contact info
    first_name: quote.first_name,
    last_name: quote.last_name,
    email: quote.email,
    phone: quote.phone,
    company: quote.company,
    website: quote.website,
    
    // Business info
    business_identity: quote.business_identity,
    role: quote.role,
    company_size: quote.company_size,
    annual_revenue: quote.annual_revenue,
    sales_channels: safeJsonToRecord(quote.sales_channels),
    total_skus: quote.total_skus,
    product_description: quote.product_description,
    fulfillment_priorities: quote.fulfillment_priorities,
    
    // Add business_challenges field
    business_challenges: quote.business_challenges,
    
    // Outbound info
    monthly_orders: quote.monthly_orders,
    fulfillment_requirements: safeJsonToRecord(quote.fulfillment_requirements),
    packaging_materials: safeJsonToRecord(quote.packaging_materials),
    packaging_details: quote.packaging_details,
    value_added_services: safeJsonToRecord(quote.value_added_services),
    services_details: safeJsonToRecord(quote.services_details),
    
    // Storage info
    storage_types: safeJsonToRecord(quote.storage_types),
    special_requirements: safeJsonToRecord(quote.special_requirements),
    bulk_storage_details: quote.bulk_storage_details,
    storage_additional_context: quote.storage_additional_context,
    other_storage_requirement: quote.other_storage_requirement,
    
    // Receiving info
    sku_mix: quote.sku_mix as "" | "single" | "mixed" | "both",
    receiving_special_instructions: quote.receiving_special_instructions,
    inbound_types: safeJsonToRecord<boolean>(quote.inbound_types),
    volumes: safeJsonToRecord<string>(quote.volumes),
    loading_types: safeJsonToRecord<"" | "mixed" | "floor" | "palletized">(quote.loading_types),
    receiving_value_added_services: safeJsonToRecord<boolean>(quote.receiving_value_added_services),
    transportation_needs: safeJsonToRecord<boolean>(quote.transportation_needs),
    expected_start_date: quote.expected_start_date,
    pallet_counts: safeJsonToRecord<string>(quote.pallet_counts),
    
    // Discovery and research info
    research_summary: quote.research_summary,
    client_friendly_narrative: quote.client_friendly_narrative,
    research_completed_at: quote.research_completed_at,
  };
  
  // Process the quote data into a more usable format, including all database fields
  const processedQuoteRequest: ProcessedQuoteRequest = {
    // Include ID so we can reference it in other components
    id: quote.id,
    // Add additional fields from DatabaseQuote needed by components
    quote_status: quote.quote_status,
    admin_notes: quote.admin_notes,
    admin_reviewed_at: quote.admin_reviewed_at,
    approved_rate_tier: quote.approved_rate_tier, // should already be the correct type from the prepareQuoteData function
    quote_version: quote.quote_version,
    created_at: quote.created_at,
    quote_type: quote.quote_type, // This will now be correctly typed
    archived: quote.archived,
    last_modified_at: quote.last_modified_at,
    pushed_to_client_at: quote.pushed_to_client_at,
    business_fit_score: quote.business_fit_score,
    business_fit_analysis: quote.business_fit_analysis,
    review_date: quote.review_date,
    reviewer_name: quote.reviewer_name,
    estimated_monthly_cost: quote.estimated_monthly_cost,
    original_rate_tier: quote.original_rate_tier,
    research_completed_at: quote.research_completed_at,
    research_summary: quote.research_summary,
    // Add discovery conversation data - simply pass it through without strict typing
    discovery_conversation: quote.discovery_conversation,
    // Add custom_rates - ensure proper conversion from Json to Record<string, number>
    custom_rates: safeJsonToRecord<number>(quote.custom_rates),
    // Add all the typed data
    ...typedQuoteData
  };
  
  return {
    processedQuoteRequest,
    typedQuoteData
  };
};
