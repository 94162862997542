
import { DisplayRateTier, RateTier } from "@/utils/rates";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { StarIcon, Flag } from "lucide-react";

interface RateTierSelectorProps {
  selectedTier: DisplayRateTier;
  onTierChange: (tier: string) => void;
  hasCustomRates?: boolean;
  recommendedTier?: RateTier;
  baseTier?: RateTier;
  setBaseTier?: (tier: RateTier) => void;
}

export const RateTierSelector = ({
  selectedTier,
  onTierChange,
  hasCustomRates = false,
  recommendedTier,
  baseTier,
  setBaseTier
}: RateTierSelectorProps) => {
  // Get display value for the trigger
  const getDisplayValue = () => {
    if (hasCustomRates) {
      return (
        <div className="flex items-center">
          <span>{selectedTier}</span>
          <Badge variant="outline" className="ml-2 bg-purple-50 text-purple-700 border-purple-200">
            <Flag className="h-3 w-3 mr-1" />
            <span className="text-xs">Custom</span>
          </Badge>
        </div>
      );
    }
    return selectedTier;
  };

  // Handle tier change with both onTierChange and setBaseTier
  const handleTierChange = (tier: string) => {
    // Only update baseTier for standard tiers (not CUSTOM)
    if (tier !== 'CUSTOM' && setBaseTier) {
      setBaseTier(tier as RateTier);
    }
    
    // Pass through to parent component
    onTierChange(tier);
  };

  return (
    <div className="flex items-center space-x-4 mb-2">
      <span className="font-medium">Rate Tier: </span>
      <div className="flex items-center space-x-2">
        <Select value={selectedTier} onValueChange={handleTierChange}>
          <SelectTrigger className="w-40">
            <SelectValue placeholder="Select tier">
              {getDisplayValue()}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="D2C">
              <div className="flex items-center justify-between w-full">
                <span>D2C</span>
                {recommendedTier === "D2C" && (
                  <Badge variant="outline" className="ml-2 bg-amber-50 text-amber-700 border-amber-200">
                    <StarIcon className="h-3 w-3 mr-1" />
                    <span className="text-xs">Recommended</span>
                  </Badge>
                )}
              </div>
            </SelectItem>
            <SelectItem value="D2C+">
              <div className="flex items-center justify-between w-full">
                <span>D2C+</span>
                {recommendedTier === "D2C+" && (
                  <Badge variant="outline" className="ml-2 bg-amber-50 text-amber-700 border-amber-200">
                    <StarIcon className="h-3 w-3 mr-1" />
                    <span className="text-xs">Recommended</span>
                  </Badge>
                )}
              </div>
            </SelectItem>
            <SelectItem value="SMB">
              <div className="flex items-center justify-between w-full">
                <span>SMB</span>
                {recommendedTier === "SMB" && (
                  <Badge variant="outline" className="ml-2 bg-amber-50 text-amber-700 border-amber-200">
                    <StarIcon className="h-3 w-3 mr-1" />
                    <span className="text-xs">Recommended</span>
                  </Badge>
                )}
              </div>
            </SelectItem>
            <SelectItem value="SMB+">
              <div className="flex items-center justify-between w-full">
                <span>SMB+</span>
                {recommendedTier === "SMB+" && (
                  <Badge variant="outline" className="ml-2 bg-amber-50 text-amber-700 border-amber-200">
                    <StarIcon className="h-3 w-3 mr-1" />
                    <span className="text-xs">Recommended</span>
                  </Badge>
                )}
              </div>
            </SelectItem>
            <SelectItem value="LEB">
              <div className="flex items-center justify-between w-full">
                <span>LEB</span>
                {recommendedTier === "LEB" && (
                  <Badge variant="outline" className="ml-2 bg-amber-50 text-amber-700 border-amber-200">
                    <StarIcon className="h-3 w-3 mr-1" />
                    <span className="text-xs">Recommended</span>
                  </Badge>
                )}
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
