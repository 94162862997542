
import { 
  ClientProgress, 
  ClientProgressFromDB, 
  ProgressStepStatus 
} from "@/types/progress";
import { Json } from "@/integrations/supabase/types";
import { getDefaultSubsteps } from "./defaultSubsteps";

export const convertProgressData = (dbItems: ClientProgressFromDB[]): ClientProgress[] => {
  return dbItems.map(item => {
    const hasValidSubsteps = item.substeps && 
                            (Array.isArray(item.substeps) || 
                             (typeof item.substeps === 'object' && Array.isArray((item.substeps as unknown as any[]))));
    
    const substeps = hasValidSubsteps 
      ? (Array.isArray(item.substeps) 
          ? item.substeps 
          : (item.substeps as unknown as any[])) 
      : getDefaultSubsteps(item.step_type);
    
    return {
      ...item,
      substeps: substeps
    };
  });
};

export const createDefaultProgressData = (
  quoteId: string, 
  userId: string | null, 
  quoteStatus: string | undefined, 
  discoveryConversation: any
) => {
  const now = new Date().toISOString();
  
  return [
    {
      id: crypto.randomUUID(),
      user_id: userId,
      quote_id: quoteId,
      step_type: 'quote',
      status: 'completed' as ProgressStepStatus,
      started_at: now,
      completed_at: now,
      is_current_step: false,
      percentage_complete: 100,
      substeps: getDefaultSubsteps('quote'),
      created_at: now,
      updated_at: now
    },
    {
      id: crypto.randomUUID(),
      user_id: userId,
      quote_id: quoteId,
      step_type: 'discovery',
      status: getDiscoveryStatus(discoveryConversation, quoteStatus),
      started_at: now,
      completed_at: isDiscoveryCompleted(discoveryConversation, quoteStatus) ? now : null,
      is_current_step: shouldDiscoveryBeCurrentStep(quoteStatus),
      percentage_complete: getDiscoveryPercentage(discoveryConversation, quoteStatus),
      substeps: getDefaultSubsteps('discovery'),
      created_at: now,
      updated_at: now
    },
    {
      id: crypto.randomUUID(),
      user_id: userId,
      quote_id: quoteId,
      step_type: 'contract',
      status: getContractStatus(quoteStatus),
      started_at: quoteStatus === 'firm_to_client' ? now : null,
      completed_at: quoteStatus === 'contract_signed' ? now : null,
      is_current_step: quoteStatus === 'firm_to_client',
      percentage_complete: getContractPercentage(quoteStatus),
      substeps: getDefaultSubsteps('contract'),
      created_at: now,
      updated_at: now
    },
    {
      id: crypto.randomUUID(),
      user_id: userId,
      quote_id: quoteId,
      step_type: 'onboarding',
      status: getOnboardingStatus(quoteStatus),
      started_at: quoteStatus === 'contract_signed' ? now : null,
      completed_at: null,
      is_current_step: quoteStatus === 'contract_signed',
      percentage_complete: getOnboardingPercentage(quoteStatus),
      substeps: getDefaultSubsteps('onboarding'),
      created_at: now,
      updated_at: now
    }
  ] as ClientProgress[];
};

// Helper functions for determining step statuses
const getDiscoveryStatus = (discoveryConversation: any, quoteStatus?: string): ProgressStepStatus => {
  const hasConversation = Array.isArray(discoveryConversation) && discoveryConversation.length > 0;
  
  if (hasConversation || quoteStatus === 'contract_signed') {
    return 'completed';
  } else {
    return 'in_progress';
  }
};

const isDiscoveryCompleted = (discoveryConversation: any, quoteStatus?: string): boolean => {
  const hasConversation = Array.isArray(discoveryConversation) && discoveryConversation.length > 0;
  return hasConversation || quoteStatus === 'contract_signed';
};

const shouldDiscoveryBeCurrentStep = (quoteStatus?: string): boolean => {
  return quoteStatus !== 'contract_signed' && quoteStatus !== 'firm_to_client';
};

const getDiscoveryPercentage = (discoveryConversation: any, quoteStatus?: string): number => {
  if (isDiscoveryCompleted(discoveryConversation, quoteStatus)) {
    return 100;
  }
  return 0;
};

const getContractStatus = (quoteStatus?: string): ProgressStepStatus => {
  if (quoteStatus === 'contract_signed') {
    return 'completed';
  } else if (quoteStatus === 'firm_to_client') {
    return 'in_progress';
  }
  return 'not_started';
};

const getContractPercentage = (quoteStatus?: string): number => {
  if (quoteStatus === 'contract_signed') {
    return 100;
  } else if (quoteStatus === 'firm_to_client') {
    return 10;
  }
  return 0;
};

const getOnboardingStatus = (quoteStatus?: string): ProgressStepStatus => {
  if (quoteStatus === 'contract_signed') {
    return 'in_progress';
  }
  return 'not_started';
};

const getOnboardingPercentage = (quoteStatus?: string): number => {
  if (quoteStatus === 'contract_signed') {
    return 10;
  }
  return 0;
};
