
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Container20Row } from "./Container20Row";
import { Container40Row } from "./Container40Row";
import { FTLRow } from "./FTLRow";
import { LTLRow } from "./LTLRow";
import { ParcelRow } from "./ParcelRow";
import { PalletConfigurationInfo } from "./PalletConfigurationInfo";

interface ReceivingTableProps {
  scopeData: any;
  rateCardData: Record<string, number>;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified: (key: string) => boolean;
  showAllRates: boolean;
}

export const ReceivingTable = ({
  scopeData,
  rateCardData,
  onRateChange,
  isCustomizing,
  isRateModified,
  showAllRates
}: ReceivingTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[45%]">Service</TableHead>
          <TableHead>Rate</TableHead>
          <TableHead>Quantity</TableHead>
          <TableHead>Extended</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {/* 20ft Container row */}
        {(showAllRates || scopeData.container20Volume > 0) && (
          <Container20Row 
            scopeData={scopeData}
            rateCardData={rateCardData}
            onRateChange={onRateChange}
            isCustomizing={isCustomizing}
            isRateModified={isRateModified}
          />
        )}
        
        {/* 40ft Container row */}
        {(showAllRates || scopeData.container40Volume > 0) && (
          <Container40Row 
            scopeData={scopeData}
            rateCardData={rateCardData}
            onRateChange={onRateChange}
            isCustomizing={isCustomizing}
            isRateModified={isRateModified}
          />
        )}
        
        {/* FTL row */}
        {(showAllRates || scopeData.ftlVolume > 0) && (
          <FTLRow 
            scopeData={scopeData}
            rateCardData={rateCardData}
            onRateChange={onRateChange}
            isCustomizing={isCustomizing}
            isRateModified={isRateModified}
          />
        )}
        
        {/* LTL row */}
        {(showAllRates || scopeData.ltlVolume > 0) && (
          <LTLRow 
            scopeData={scopeData}
            rateCardData={rateCardData}
            onRateChange={onRateChange}
            isCustomizing={isCustomizing}
            isRateModified={isRateModified}
          />
        )}
        
        {/* Small Parcel row */}
        {(showAllRates || scopeData.parcelVolume > 0) && (
          <ParcelRow 
            scopeData={scopeData}
            rateCardData={rateCardData}
            onRateChange={onRateChange}
            isCustomizing={isCustomizing}
            isRateModified={isRateModified}
          />
        )}
        
        {/* Pallet Count Information Section */}
        <PalletConfigurationInfo 
          loadingTypes={scopeData.loadingTypes || {}} 
          palletCounts={scopeData.palletCounts || {}}
        />
      </TableBody>
    </Table>
  );
};
