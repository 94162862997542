
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { FileDown } from "lucide-react";
import { ContactTab } from "./tabs/ContactTab";
import { BusinessTab } from "./tabs/BusinessTab";
import { FulfillmentTab } from "./tabs/FulfillmentTab";
import { ReceivingTab } from "./tabs/ReceivingTab";
import { StorageTab } from "./tabs/StorageTab";
import { BudgetaryTab } from "./tabs/BudgetaryTab";
import { ResearchTab } from "./tabs/ResearchTab";
import { DiscoveryTab } from "./tabs/DiscoveryTab";
import { DetailedQuoteViewProps } from "./types";
import { generateComprehensivePDF } from "./utils/pdfGenerator";
import { toast } from "sonner";

export const DetailedQuoteView = ({
  quote,
  companyName,
  open,
  onOpenChange
}: DetailedQuoteViewProps) => {
  const [activeTab, setActiveTab] = useState("contact");
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  
  if (!quote) return null;
  
  const handleDownloadReport = async () => {
    try {
      setIsGeneratingPDF(true);
      toast.loading("Generating PDF report...");
      
      // Generate comprehensive PDF with all tabs content
      await generateComprehensivePDF(quote, companyName || quote.company);
      
      toast.dismiss();
      toast.success("Report generated successfully");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.dismiss();
      toast.error("Failed to generate report. Please try again.");
    } finally {
      setIsGeneratingPDF(false);
    }
  };
  
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
        <DialogHeader className="flex flex-row items-center justify-between">
          <DialogTitle className="text-xl font-bold">{companyName || quote.company} - Quote Details</DialogTitle>
          <Button 
            onClick={handleDownloadReport} 
            variant="outline" 
            size="sm"
            disabled={isGeneratingPDF}
            className="ml-auto"
          >
            <FileDown className="h-4 w-4 mr-2" />
            Download Report
          </Button>
        </DialogHeader>

        <Tabs defaultValue="contact" value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid grid-cols-8 mb-4">
            <TabsTrigger value="contact">Contact</TabsTrigger>
            <TabsTrigger value="business">Business</TabsTrigger>
            <TabsTrigger value="receiving">Receiving</TabsTrigger>
            <TabsTrigger value="storage">Storage</TabsTrigger>
            <TabsTrigger value="fulfillment">Fulfillment</TabsTrigger>
            <TabsTrigger value="discovery">Discovery</TabsTrigger>
            <TabsTrigger value="research">Research</TabsTrigger>
            <TabsTrigger value="budgetary">Quote</TabsTrigger>
          </TabsList>
          
          <TabsContent value="contact" className="p-2">
            <ContactTab quote={quote} />
          </TabsContent>
          
          <TabsContent value="business" className="p-2">
            <BusinessTab quote={quote} />
          </TabsContent>
          
          <TabsContent value="receiving" className="p-2">
            <ReceivingTab quote={quote} />
          </TabsContent>
          
          <TabsContent value="storage" className="p-2">
            <StorageTab quote={quote} />
          </TabsContent>
          
          <TabsContent value="fulfillment" className="p-2">
            <FulfillmentTab quote={quote} />
          </TabsContent>
          
          <TabsContent value="discovery" className="p-2">
            <DiscoveryTab quote={quote} />
          </TabsContent>
          
          <TabsContent value="research" className="p-2">
            <ResearchTab quote={quote} />
          </TabsContent>
          
          <TabsContent value="budgetary" className="p-2">
            <BudgetaryTab quote={quote} approvedRateTier={quote.approved_rate_tier} />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};
