
// Helper functions for determining recommended rate tiers

import { RateTier } from "./types";
import { defaultRevenueTiers } from "./tierSelection";

// Determine the recommended rate tier based on monthly revenue
export const getRecommendedTier = (
  monthlyRevenue: number,
  quoteId?: string,
  currentTier?: RateTier
): RateTier => {
  // Use local storage if available to get custom thresholds
  let tiers = { ...defaultRevenueTiers };
  
  try {
    const storedTiers = localStorage.getItem('revenue_tiers');
    if (storedTiers) {
      const parsedTiers = JSON.parse(storedTiers);
      tiers = { ...tiers, ...parsedTiers };
    }
  } catch (e) {
    console.error("Error parsing stored revenue tiers", e);
  }
  
  console.log(`Determining rate tier for revenue: ${monthlyRevenue}`);
  
  // Determine tier based on revenue thresholds
  if (monthlyRevenue >= tiers.LEB) {
    console.log(`Revenue ${monthlyRevenue} >= LEB threshold ${tiers.LEB}`);
    return 'LEB';
  } else if (monthlyRevenue >= tiers["SMB+"]) {
    console.log(`Revenue ${monthlyRevenue} >= SMB+ threshold ${tiers["SMB+"]}`);
    return 'SMB+';
  } else if (monthlyRevenue >= tiers.SMB) {
    console.log(`Revenue ${monthlyRevenue} >= SMB threshold ${tiers.SMB}`);
    return 'SMB';
  } else if (monthlyRevenue >= tiers["D2C+"]) {
    console.log(`Revenue ${monthlyRevenue} >= D2C+ threshold ${tiers["D2C+"]}`);
    return 'D2C+';
  } else {
    console.log(`Revenue ${monthlyRevenue} < D2C+ threshold ${tiers["D2C+"]}, using D2C`);
    return 'D2C';
  }
};

// Helper function to check if a prospect has custom rates
export const hasCustomRates = (prospect: any): boolean => {
  return !!(prospect?.custom_rates && 
    typeof prospect.custom_rates === 'object' && 
    Object.keys(prospect.custom_rates).length > 0);
};
