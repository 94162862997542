// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://qrfmifymdjrwxjuuutwj.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFyZm1pZnltZGpyd3hqdXV1dHdqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzk5ODk1MzIsImV4cCI6MjA1NTU2NTUzMn0.twvrZzO4R7s5jHQgIqq0ZcFVL7Z-HKE_LuFTNbh5gzs";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);