import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { rateSections } from "@/pages/Admin/Finance/components/RateCards/constants";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@/components/ui/tooltip";
import { Info } from "lucide-react";
import { RateTier, rateTable } from "@/utils/rates";

export interface DirectRateViewProps {
  customRates: Record<string, number>;
  originalRateTier?: RateTier;
}

export const DirectRateView = ({
  customRates,
  originalRateTier = "D2C" // Default to D2C if no original tier provided
}: DirectRateViewProps) => {
  // Ensure customRates is an object and not null or undefined
  const safeCustomRates = customRates && typeof customRates === 'object' ? customRates : {};
  
  // Get the base rate table for the original tier to use as fallback
  const baseRates = originalRateTier ? rateTable[originalRateTier] : rateTable.D2C;
  
  // Split rate sections into two columns
  const leftColumnSections = rateSections.slice(0, Math.ceil(rateSections.length / 2));
  const rightColumnSections = rateSections.slice(Math.ceil(rateSections.length / 2));

  return (
    <TooltipProvider>
      <div className="animate-fade-in rate-card">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {/* Left Column */}
          <div className="space-y-3">
            {leftColumnSections.map((section, index) => (
              <div key={index} className="bg-white rounded-lg border shadow-sm hover:shadow-md transition-shadow">
                <div className="bg-gray-50 py-0.5 px-3 border-b rounded-t-lg">
                  <h3 className="text-sm font-medium text-gray-800 flex items-center">
                    {section.icon && <section.icon className="h-4 w-4 mr-1.5 text-blue-600" />}
                    {section.title}
                  </h3>
                </div>
                
                <div className="p-0">
                  <div className="overflow-x-auto">
                    <Table>
                      <TableHeader className="bg-gray-50">
                        <TableRow className="h-6">
                          <TableHead className="py-0.5 text-xs font-semibold">Service</TableHead>
                          <TableHead className="py-0.5 text-xs font-semibold text-right w-24">Rate</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {section.fields.map(({ key, label }, rowIndex) => {
                          // Get the rate from customRates or use the original tier rate as fallback
                          const fieldKey = String(key);
                          let rateValue = 0;
                          
                          // Check if we have a custom rate for this field
                          if (safeCustomRates && typeof safeCustomRates[fieldKey] === 'number') {
                            rateValue = safeCustomRates[fieldKey];
                          } 
                          // Otherwise use the base rate from the original tier
                          else if (baseRates && typeof baseRates[fieldKey as keyof typeof baseRates] === 'number') {
                            rateValue = baseRates[fieldKey as keyof typeof baseRates];
                          }
                          
                          return (
                            <TableRow 
                              key={fieldKey}
                              className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"} h-6`}
                            >
                              <TableCell className="py-0 pl-2 text-xs font-medium align-middle h-6">
                                <div className="flex items-center h-full">
                                  {label}
                                  {fieldKey.includes("premium") && (
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Info className="h-3 w-3 ml-1 text-blue-500 cursor-help print-hide-on-print" />
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p className="max-w-xs text-xs">Premium service with enhanced features</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell className="text-right font-medium">
                                <span className="text-green-700">
                                  ${rateValue.toFixed(2)}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Right Column */}
          <div className="space-y-3">
            {rightColumnSections.map((section, index) => (
              <div key={index} className="bg-white rounded-lg border shadow-sm hover:shadow-md transition-shadow">
                <div className="bg-gray-50 py-0.5 px-3 border-b rounded-t-lg">
                  <h3 className="text-sm font-medium text-gray-800 flex items-center">
                    {section.icon && <section.icon className="h-4 w-4 mr-1.5 text-blue-600" />}
                    {section.title}
                  </h3>
                </div>
                
                <div className="p-0">
                  <div className="overflow-x-auto">
                    <Table>
                      <TableHeader className="bg-gray-50">
                        <TableRow className="h-6">
                          <TableHead className="py-0.5 text-xs font-semibold">Service</TableHead>
                          <TableHead className="py-0.5 text-xs font-semibold text-right w-24">Rate</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {section.fields.map(({ key, label }, rowIndex) => {
                          // Get the rate from customRates or use the original tier rate as fallback
                          const fieldKey = String(key);
                          let rateValue = 0;
                          
                          // Check if we have a custom rate for this field
                          if (safeCustomRates && typeof safeCustomRates[fieldKey] === 'number') {
                            rateValue = safeCustomRates[fieldKey];
                          } 
                          // Otherwise use the base rate from the original tier
                          else if (baseRates && typeof baseRates[fieldKey as keyof typeof baseRates] === 'number') {
                            rateValue = baseRates[fieldKey as keyof typeof baseRates];
                          }
                          
                          return (
                            <TableRow 
                              key={fieldKey}
                              className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"} h-6`}
                            >
                              <TableCell className="py-0 pl-2 text-xs font-medium align-middle h-6">
                                <div className="flex items-center h-full">
                                  {label}
                                  {fieldKey.includes("premium") && (
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Info className="h-3 w-3 ml-1 text-blue-500 cursor-help print-hide-on-print" />
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p className="max-w-xs text-xs">Premium service with enhanced features</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell className="text-right font-medium">
                                <span className="text-green-700">
                                  ${rateValue.toFixed(2)}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};
