
import { lazy } from "react";
import { Route } from "react-router-dom";
import ClientPortal from "@/pages/ClientPortal"; // Import directly instead of lazy loading

// Lazy load other client portal components
const Portal = lazy(() => import("@/pages/client/Portal"));
const Quotes = lazy(() => import("@/pages/client/Quotes"));
const Discovery = lazy(() => import("@/pages/client/Discovery"));
const Contract = lazy(() => import("@/pages/client/Contract"));
const Onboarding = lazy(() => import("@/pages/client/Onboarding"));
const SendInventory = lazy(() => import("@/pages/client/SendInventory"));
const Inventory = lazy(() => import("@/pages/client/Inventory"));
const Orders = lazy(() => import("@/pages/client/Orders"));
const Shipments = lazy(() => import("@/pages/client/Shipments"));
const DockScheduler = lazy(() => import("@/pages/client/DockScheduler"));
const Reports = lazy(() => import("@/pages/client/Reports"));
const Support = lazy(() => import("@/pages/client/Support"));
const Settings = lazy(() => import("@/pages/client/Settings"));
const Partners = lazy(() => import("@/pages/client/Partners"));
const WorkOrders = lazy(() => import("@/pages/client/Support")); // Temporarily point to Support page
const Growth = lazy(() => import("@/pages/client/Portal")); // Temporarily point to Portal page

export const ClientPortalRoutes = (
  <Route path="/client-portal" element={<ClientPortal />}>
    <Route index element={<Portal />} />
    <Route path="discovery" element={<Discovery />} />
    <Route path="quotes" element={<Quotes />} />
    <Route path="contract" element={<Contract />} />
    <Route path="onboarding" element={<Onboarding />} />
    <Route path="send-inventory" element={<SendInventory />} />
    <Route path="inventory" element={<Inventory />} />
    <Route path="orders" element={<Orders />} />
    <Route path="shipments" element={<Shipments />} />
    <Route path="dock-scheduler" element={<DockScheduler />} />
    <Route path="work-orders" element={<WorkOrders />} />
    <Route path="growth" element={<Growth />} />
    <Route path="reports" element={<Reports />} />
    <Route path="support" element={<Support />} />
    <Route path="settings" element={<Settings />} />
    <Route path="partners" element={<Partners />} />
  </Route>
);
