
import { ReactNode } from "react";
import { LucideIcon, BadgeCheck, Clock } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "@/components/ui/sidebar";
import { CustomBadge } from "@/components/ui/custom-badge";
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";

interface MenuItemType {
  title: string;
  icon: LucideIcon;
  path: string;
  completionStatus?: "incomplete" | "in-progress" | "complete";
  nextStep?: boolean;
  isGreyedOut?: boolean;
  comingSoon?: boolean;
}

interface AchievementMenuItemsProps {
  label?: string;
  items: MenuItemType[];
}

export const AchievementMenuItems = ({ label, items }: AchievementMenuItemsProps) => {
  const location = useLocation();

  // Define badge components for different completion statuses
  const getBadge = (status?: "incomplete" | "in-progress" | "complete", title?: string): ReactNode => {
    if (!status || status === "incomplete") return null;

    if (status === "complete") {
      return (
        <motion.div 
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="ml-auto"
        >
          <CustomBadge 
            variant="success" 
            className="text-xs py-0.5 px-2"
          >
            Completed
          </CustomBadge>
        </motion.div>
      );
    }

    if (status === "in-progress") {
      return (
        <motion.div 
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="ml-auto"
        >
          <CustomBadge 
            variant="warning" 
            className="text-xs py-0.5 px-2"
          >
            In Progress
          </CustomBadge>
        </motion.div>
      );
    }

    return null;
  };

  // Define a separate component for the "next step" highlighting
  const NextStepIndicator = ({ isNextStep, title }: { isNextStep?: boolean, title?: string }) => {
    // For Quotes items, we'll never show the "Next" badge as they now get an "In Progress" badge instead
    if (title === "Quotes") return null;
    
    if (!isNextStep) return null;

    return (
      <div className="ml-auto">
        <CustomBadge 
          variant="success" 
          className="text-xs py-0.5 px-2"
        >
          Next
        </CustomBadge>
      </div>
    );
  };

  return (
    <SidebarGroup>
      {label && <SidebarGroupLabel>{label}</SidebarGroupLabel>}
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item) => (
            <SidebarMenuItem key={item.title}>
              {item.comingSoon || item.isGreyedOut ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center gap-3 px-3 py-2 text-gray-400 opacity-50 cursor-not-allowed">
                        <item.icon className="h-4 w-4" />
                        <span>{item.title}</span>
                        {item.comingSoon && <span className="text-xs ml-1">(Coming Soon)</span>}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      {item.comingSoon 
                        ? "This feature is coming soon"
                        : "Complete the previous steps first"}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <SidebarMenuButton
                  asChild
                  className={location.pathname === item.path ? "bg-gray-100" : ""}
                >
                  <Link 
                    to={item.path} 
                    className="flex items-center gap-3 w-full"
                  >
                    <item.icon className="h-4 w-4" />
                    <span className="flex-grow">{item.title}</span>
                    {getBadge(item.completionStatus, item.title)}
                    {(item.nextStep && !item.completionStatus) && 
                      <NextStepIndicator isNextStep={item.nextStep} title={item.title} />}
                  </Link>
                </SidebarMenuButton>
              )}
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
};
