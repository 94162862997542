
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from "@/components/ui/navigation-menu";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { FulfillmentMenu } from "./nav/FulfillmentMenu";
import { WarehousingMenu } from "./nav/WarehousingMenu";
import { TechnologyMenu } from "./nav/TechnologyMenu";
import { LocationsMenu } from "./nav/LocationsMenu";
import { PricingMenu } from "./nav/PricingMenu";

export function MainNav() {
  return (
    <NavigationMenu>
      <NavigationMenuList className="hidden md:flex items-center space-x-4 text-base">
        <NavigationMenuItem>
          <NavigationMenuTrigger className="text-base text-white hover:text-white/90">Fulfillment</NavigationMenuTrigger>
          <FulfillmentMenu />
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuTrigger className="text-base text-white hover:text-white/90">Warehousing</NavigationMenuTrigger>
          <WarehousingMenu />
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuTrigger className="text-base text-white hover:text-white/90">Technology</NavigationMenuTrigger>
          <TechnologyMenu />
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuTrigger className="text-base text-white hover:text-white/90">Locations</NavigationMenuTrigger>
          <LocationsMenu />
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuTrigger className="text-base text-white hover:text-white/90">Pricing</NavigationMenuTrigger>
          <PricingMenu />
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Link to="/client-portal">
              <Button variant="ghost" className="text-base text-white hover:text-white/90 hover:bg-white/10">Client Portal</Button>
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Link to="/quote">
              <Button className="text-base bg-white text-[#001801] hover:bg-white/90">Get in Touch</Button>
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}
