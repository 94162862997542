
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
export const HeroContent = () => {
  return <div className="text-left">
      <motion.h1 initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.8
    }} className="text-4xl sm:text-5xl font-bold mb-6 text-left md:text-5xl lg:text-5xl">
        Logistics Built to Make Your Business Thrive
      </motion.h1>
      
      <motion.p initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.8,
      delay: 0.2
    }} className="text-xl text-gray-100 mb-8 max-w-2xl font-normal md:text-2xl">3PL Warehousing & Order Fulfillment Helping Omni-Channel Brands Scale Faster</motion.p>
      
      <motion.div initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.8,
      delay: 0.4
    }} className="pl-4">
        <Link to="/quote" className="inline-block bg-white text-[#001801] px-10 py-5 rounded-lg text-xl font-semibold transition-all duration-300 shadow-md hover:bg-[#001801] hover:text-white hover:border hover:border-white hover:scale-105 hover:shadow-xl">
          Get Your Instant Quote
        </Link>
      </motion.div>
    </div>;
};
