import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { PreviewProvider } from './contexts/PreviewContext.tsx'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ErrorBoundary } from './components/ui/error-boundary.tsx'
import * as Sentry from "@sentry/react";

console.log('Initializing app in environment:', import.meta.env.MODE);
console.log('Preview mode env var:', import.meta.env.LOVABLE_PREVIEW);

// Initialize Sentry
Sentry.init({
  dsn: "https://c3d68c98a83142339ec2b8074edd7506@o4509073013932032.ingest.us.sentry.io/4509073016225792"
});

// Set up a global error handler for uncaught errors
window.addEventListener('error', (event) => {
  console.error('Global error caught:', event.error);
});

// Create a client with more conservative settings for preview mode
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: import.meta.env.LOVABLE_PREVIEW === 'true' ? 0 : 1, // Disable retry in preview
      refetchOnWindowFocus: false,
      staleTime: 30000,
    },
    mutations: {
      // Configuration for mutations if needed
    }
  },
});

// Wrap the app with error boundaries and providers
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={
      <div className="flex items-center justify-center min-h-screen bg-gray-50 p-6">
        <div className="bg-white p-6 rounded-lg shadow-md max-w-md">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Application Error</h2>
          <p className="text-gray-700 mb-4">
            We've encountered an unexpected error and our team has been notified. Please try again later.
          </p>
          <button 
            onClick={() => window.location.reload()}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Reload Page
          </button>
        </div>
      </div>
    }>
      <ErrorBoundary 
        fallback={
          <div className="flex items-center justify-center min-h-screen bg-gray-50 p-6">
            <div className="bg-white p-6 rounded-lg shadow-md max-w-md">
              <h2 className="text-xl font-semibold text-red-600 mb-2">Application Error</h2>
              <p className="text-gray-700 mb-4">
                The application encountered an unexpected error. This might be related to preview mode editing.
              </p>
              <button 
                onClick={() => window.location.reload()}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              >
                Reload Page
              </button>
            </div>
          </div>
        }
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <PreviewProvider>
              <App />
            </PreviewProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
