
import { TableCell, TableRow } from "@/components/ui/table";
import { RateCell } from "../RateCell";
import { formatCurrency } from "@/lib/utils";
import { calculate20ftContainerCost } from "./utils";

interface Container20RowProps {
  scopeData: any;
  rateCardData: Record<string, number>;
  onRateChange: (key: string, value: string) => void;
  isCustomizing: boolean;
  isRateModified: (key: string) => boolean;
}

export const Container20Row = ({
  scopeData,
  rateCardData,
  onRateChange,
  isCustomizing,
  isRateModified
}: Container20RowProps) => {
  const isPalletized = scopeData.loadingTypes?.container20 === "palletized" && 
                      scopeData.palletCounts?.container20 > 0;
  
  return (
    <TableRow>
      <TableCell className="font-medium">
        {isPalletized 
          ? "20ft Container Receiving (Palletized)" 
          : "20ft Container Receiving"}
      </TableCell>
      <TableCell>
        {isPalletized 
          ? (
            <div className="flex flex-col">
              <RateCell 
                fieldKey="receivingStandardPallet" 
                rate={rateCardData.receivingStandardPallet || 0}
                onChange={onRateChange}
                isCustomizing={isCustomizing}
                isModified={isRateModified("receivingStandardPallet")}
                label="per pallet"
              />
            </div>
          ) 
          : (
            <RateCell 
              fieldKey="receiving20Container" 
              rate={rateCardData.receiving20Container || 0} 
              onChange={onRateChange}
              isCustomizing={isCustomizing}
              isModified={isRateModified("receiving20Container")}
            />
          )
        }
      </TableCell>
      <TableCell>
        {isPalletized 
          ? `${scopeData.container20Volume * scopeData.palletCounts.container20} pallets/mo` 
          : `${scopeData.container20Volume || 0}/mo`}
      </TableCell>
      <TableCell>
        {formatCurrency(calculate20ftContainerCost(scopeData, rateCardData))}
      </TableCell>
    </TableRow>
  );
};
