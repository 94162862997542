
import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { PageLoader } from "./PageLoader";
import { ErrorBoundary } from "@/components/ui/error-boundary";

// Always eagerly load critical components
import Index from "@/pages/Index";
import NotFound from "@/pages/NotFound";
import Auth from "@/pages/Auth";
import ClientPortal from "@/pages/ClientPortal";

// Import route groups
import { AdminRoutes } from "./admin-routes";
import { ClientPortalRoutes } from "./client-portal-routes";
import { QuoteRoutes } from "./quote-routes";
import { ContentRoutes } from "./content-routes";

function AppRoutes() {
  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <ErrorBoundary 
          fallback={
            <div className="min-h-screen flex items-center justify-center bg-gray-50 p-8">
              <div className="bg-white rounded-lg shadow-md p-8 max-w-md w-full">
                <h2 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h2>
                <p className="text-gray-700 mb-6">
                  We encountered an error while loading this page. This could be due to network issues or a temporary server problem.
                </p>
                <div className="flex flex-col space-y-4">
                  <button 
                    onClick={() => window.location.reload()}
                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
                  >
                    Reload Page
                  </button>
                  <a 
                    href="/"
                    className="border border-gray-300 text-gray-700 py-2 px-4 rounded text-center hover:bg-gray-50 transition-colors"
                  >
                    Return to Home
                  </a>
                </div>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/auth" element={<Auth />} />
            
            {/* Include route groups */}
            {QuoteRoutes}
            {ContentRoutes}
            {ClientPortalRoutes}
            {AdminRoutes}
            
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
      <Toaster />
    </>
  );
}

export default AppRoutes;
